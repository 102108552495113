/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { PHONE_REGEX } from 'constants/defaultValues';
import { OTHER_NOTIFIER_TYPE } from 'constants/options';
import API from 'constants/api';
import rest from 'util/Api';
import { actions } from 'redux/store/slice';
import { storeLoadedSelector, storeItemsSelector } from 'redux/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import useStore from 'redux/store';
import useStoreNormalPackages from 'redux/packagesByStore';
import useStoreHistoryPackages from 'redux/packagesByStoreHistory';
import Store from '../store';

const defaultValues = {
  detail: '',
  address: '',
  contactName: '',
  email: '',
  geoKey: '',
  name: '',
  phone: '',
  id: '',
  username: '',
  hasIntegration: false,
  notifierType: null,
};

const validationWithoutIntegration = Yup.object().shape({
  name: Yup.string().required('Especifique el nombre de la tienda'),
  phone: Yup.string()
    .matches(PHONE_REGEX, 'Formato de telefono invalido.')
    .typeError('Especifique el teléfono de la tienda')
    .required('Especifique el teléfono de la tienda'),
});

const fullValidation = Yup.object()
  .shape({
    username: Yup.string().when('hasIntegration', {
      is: true,
      then: Yup.string().required(
        'Indique el nombre de usuario de la integración',
      ),
      otherwise: Yup.string().nullable(),
    }),
    notifierType: Yup.number().when('hasIntegration', {
      is: true,
      then: Yup.number()
        .typeError('Indique el tipo de integración')
        .required('Indique el tipo de integración'),
      otherwise: Yup.number().nullable(),
    }),
    password: Yup.string().when('hasIntegration', {
      is: true,
      then: Yup.string().required(
        'Especifique la contraseña de la integración',
      ),
      otherwise: Yup.string().nullable(),
    }),
    confirmpassword: Yup.string().when('hasIntegration', {
      is: true,
      then: Yup.string().test(
        'password-match',
        'Las contraseñas deben coincidir',
        function callback(value) {
          return this.parent.password === value;
        },
      ),
      otherwise: Yup.string().nullable(),
    }),
  })
  .concat(validationWithoutIntegration);

const MarketplaceStoreModify = ({ marketplaceData, storeId }) => {
  useStore();
  useStoreNormalPackages();
  useStoreHistoryPackages(storeId);
  const dispatch = useDispatch();
  const history = useHistory();
  const loaded = useSelector(storeLoadedSelector);
  const fullItems = useSelector(storeItemsSelector);
  const [successFetch, setSuccessFetch] = useState(false);
  const [storeData, setStoreData] = useState(null);
  const [disabledIntegration, setDisabledIntegration] = useState(false);

  const handleSubmit = async (values) => {
    dispatch(actions.loading());
    let credentials;
    if (values.hasIntegration) {
      credentials = {};
      const { password, username, notifierType } = values;
      if (password !== '') {
        credentials.password = password;
      }
      credentials.username = username;
      credentials.notifier =
        notifierType !== OTHER_NOTIFIER_TYPE.value ? notifierType : null;
    }
    const payload = {
      id: parseInt(storeId, 10),
      detail: values.detail,
      name: values.name,
      address: values.address
        ? values.address
        : marketplaceData.dispatchAddress,
      phone: values.phone,
      contactName: values.contactName,
      email: values.email,
      active: values.active,
      geoKey: values.geokey,
      ownerId: marketplaceData.id,
      ownerType: marketplaceData.ownerType,
      storeCode: values.id,
      credentials,
    };
    try {
      const response = await rest.put(
        `${API.stores.create}/${storeId}`,
        payload,
      );
      if (rest.isSuccessResponse(response)) {
        dispatch(actions.refreshPage(marketplaceData.id));
        notification.success({
          message: 'Actualización de Tienda',
          description: `La tienda ${values.name} ha sido actualizada correctamente.`,
        });
        if (response?.data?.data?.credentials) {
          notification.success({
            message: 'Credenciales actualizadas',
            description: 'Las contraseñas de integración han sido actualizadas',
          });
        }
        setTimeout(() => {
          history.push(`/marketplaces/${marketplaceData.id}`);
        }, 1000);
      }
    } catch (error) {
      dispatch(actions.loaded());
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al actualizar la tienda${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };

  useEffect(() => {
    dispatch(actions.updateMarketplace(marketplaceData.id));
  }, [dispatch, marketplaceData]);

  useEffect(() => {
    const getStore = async () => {
      if (loaded && fullItems) {
        try {
          const store = fullItems.find(
            (item) => item.id === parseInt(storeId, 10),
          );
          setStoreData({
            active: store.active,
            detail: store.detail,
            address: store.address,
            contactName: store.contactName,
            email: store.email,
            geoKey: store.geokey,
            name: store.name,
            phone: store.phone,
            id: store.id,
            username: store.credentials?.username,
            hasIntegration: !!store.credentials,
            notifierType: store.notifierTypeValue || OTHER_NOTIFIER_TYPE.value,
          });
          if (store?.credentials?.username) setDisabledIntegration(true);
          else
            setStoreData((state) => ({
              ...state,
              password: '',
              confirmpassword: '',
            }));
        } catch (error) {
          notification.error({
            message: 'Ha ocurrido un error',
            description: 'No se pudo obtener la tienda indicada.',
          });
          setTimeout(() => {
            history.goBack();
          }, 1500);
        } finally {
          setSuccessFetch(true);
        }
      }
    };
    if (!successFetch) {
      getStore();
    }
  }, [storeId, successFetch, loaded, fullItems, history]);

  return (
    <Store
      validationSchema={
        disabledIntegration ? validationWithoutIntegration : fullValidation
      }
      initialValues={storeData || defaultValues}
      onSubmit={handleSubmit}
      disabledIntegration={disabledIntegration}
    />
  );
};

MarketplaceStoreModify.propTypes = {
  marketplaceData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dispatchAddress: PropTypes.string,
    ownerType: PropTypes.number.isRequired,
  }).isRequired,
  storeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default MarketplaceStoreModify;
