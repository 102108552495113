import React, { useCallback, useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface MapWithMarkerProps {
  lat: number;
  lng: number;
  address: string;
  handleOnGeoChange?: (lat: number, lng: number) => void;
}

const MapWithMarker: React.FC<MapWithMarkerProps> = ({
  lat,
  lng,
  address,
  handleOnGeoChange,
}) => {
  const [markerPosition, setMarkerPosition] = useState({ lat, lng });

  // Actualiza la posición del marcador cuando cambian las props lat y lng
  useEffect(() => {
    setMarkerPosition({ lat, lng });
  }, [lat, lng]);

  // Actualiza la posición del marcador cuando cambia address
  useEffect(() => {
    if (address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const { location } = results[0].geometry;
          setMarkerPosition({ lat: location.lat(), lng: location.lng() });
          if (handleOnGeoChange) {
            handleOnGeoChange(location.lat(), location.lng());
          }
        }
      });
    }
    // if yo uadd handleOnGeoChange , good luck with google maps billing
  }, [address]);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      map.setCenter(markerPosition);
    },
    [markerPosition],
  );

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition}
      zoom={15}
      onLoad={onLoad}
      onClick={(e) => {
        if (!e) return;
        if (!e.latLng) return;

        if (handleOnGeoChange) {
          handleOnGeoChange(e.latLng.lat(), e.latLng.lng());
          setMarkerPosition({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          });
        }
      }}
    >
      <Marker position={markerPosition} />
    </GoogleMap>
  );
};

const MapWithMarkerHOC: React.FC<MapWithMarkerProps> = (props) => {
  return <MapWithMarker {...props} />;
};

export default MapWithMarkerHOC;
