import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const paquerypointViewSelector = (state) => state[name] || initialState;

export const paquerypointViewPaquerypointSelector = (state) =>
  state[name].paquerypoint;

export const paquerypointViewPaquerypointIDSelector = (state) =>
  state[name].paquerypoint?.id;

export const paquerypointViewLoadingSelector = (state) => state[name].isLoading;

export const paquerypointViewPackagesSelector = (state) => state[name].packages;

export const paquerypointViewHistorySelector = (state) => state[name].history;

export const paquerypointViewStatusSelector = (state) =>
  state[name].packages.status;

export const paquerypointViewSearchSelector = (state) =>
  state[name].packages.search;

export const paquerypointViewPackagesItemsSelector = (state) =>
  state[name].packages.items;

export const paquerypointViewPackagesPageableSelector = (state) =>
  state[name].packages.pageable;

export const paquerypointViewPackagesTypeSelector = (state) =>
  state[name].packages.type;

export const paquerypointViewPackagesMarketFilterSelector = (state) =>
  state[name].packages.marketplaceId;

export const paquerypointViewPackagesDateSelector = (state) =>
  state[name].packages.date;

export const paquerypointViewPackagesLoadedSelector = (state) =>
  state[name].packages.loaded;

export const paquerypointViewHistoryDateSelector = (state) =>
  state[name].history.date;

export const paquerypointViewHistorySearchSelector = (state) =>
  state[name].history.search;

export const paquerypointViewHistoryItemsSelector = (state) =>
  state[name].history.items;

export const paquerypointViewHistoryPageableSelector = (state) =>
  state[name].history.pageable;

export const paquerypointViewHistoryMarketFilterSelector = (state) =>
  state[name].history.marketplaceId;

export const paquerypointViewHistoryTypeSelector = (state) =>
  state[name].history.type;

export const paquerypointViewHistoryStatusSelector = (state) =>
  state[name].history.status;

export const paquerypointViewHistoryLoadedSelector = (state) =>
  state[name].history.loaded;

const makePaquerypointViewSelector = () =>
  createSelector(paquerypointViewSelector, (substate) => substate);

export default makePaquerypointViewSelector;
