export const loadReducer = (reducerName) => {
  try {
    const serializedReducer = localStorage.getItem(reducerName);
    if (serializedReducer === null) {
      return undefined;
    }
    return JSON.parse(serializedReducer);
  } catch (error) {
    return undefined;
  }
};

export const saveReducer = (reducer, reducerName) => {
  try {
    const serializedReducer = JSON.stringify(reducer);
    localStorage.setItem(reducerName, serializedReducer);
  } catch (error) {
    // Ignore on Errors
  }
};

export const loadKey = (keyName) => {
  try {
    const stringKey = localStorage.getItem(keyName);
    if (stringKey === null) {
      return undefined;
    }
    return stringKey;
  } catch (error) {
    return undefined;
  }
};

export const saveKey = (key, value) => {
  try {
    if (key && value) {
      localStorage.setItem(key, value);
    }
  } catch (error) {
    // ignore on errors
  }
};
