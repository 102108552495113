import api from 'util/Api';
import axios from 'axios';
import { LogisticOperatorRoles } from 'constants/roles';
import API from 'constants/api';
import { saveKey } from 'util/localstorage';
import {
  put,
  call,
  all,
  takeLatest,
  select,
  take,
  fork,
  cancel,
  race,
} from 'redux-saga/effects';
import { logout, clearReducers } from 'redux/logout';
import { actions as globalsActions } from 'redux/globals/slice';
import { message } from 'antd';
import { authTokenSelector } from './selectors';
import { actions } from './slice';

function* fetchProfileSaga() {
  const profileResponse = yield call(api.apiAxios.get, API.profile.load);
  if (!api.isSuccessResponse(profileResponse)) {
    throw new Error(
      `Error al obtener el perfil del usuario${
        profileResponse.data.message ? `: ${profileResponse.data.message}` : '.'
      }`,
    );
  }
  const profile = profileResponse.data.data;
  if (
    !LogisticOperatorRoles.some(
      (allowedRole) => allowedRole === profile.userRoleID,
    )
  ) {
    throw new Error('El usuario no es operador o administrador logístico');
  }
  return profile;
}

function* fetchLoggedUserSaga() {
  const sessionToken = yield select(authTokenSelector);
  if (sessionToken) {
    api.apiAxios.defaults.headers.common.authorization = `bearer ${sessionToken}`;
    try {
      const profile = yield call(fetchProfileSaga);
      yield put({ type: actions.fetchProfileSuccess.type, payload: profile });
    } catch (error) {
      yield put({ type: actions.fetchProfileFailure.type });
      message.error(error.message);
    }
  }
}

function* fetchLoginSaga(email, password) {
  const body = new URLSearchParams({
    username: email,
    password,
    grant_type: 'password',
  });
  const request = {
    url: API.auth.login,
    method: 'POST',
    data: body.toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  try {
    const response = yield call(axios, request);
    const token = response.data.access_token;
    api.apiAxios.defaults.headers.common.authorization = `bearer ${token}`;
    const profile = yield call(fetchProfileSaga);
    const payload = {
      profile,
      sessionToken: token,
    };
    saveKey('sessionToken', token);
    yield put({ type: actions.success.type, payload });
  } catch (error) {
    yield put({ type: actions.failure.type });
    if (error && error.message.includes(400)) {
      message.error('Usuario o contraseña incorrectos.');
    } else {
      message.error(error.message);
    }
  }
}

function* watchLogin() {
  while (true) {
    const { credentials } = yield race({
      credentials: take(actions.fetch.type),
      rehydrate: take(actions.fetchLoggedUser.type),
    });
    let task;
    if (credentials) {
      const {
        payload: { email, password },
      } = credentials;
      task = yield fork(fetchLoginSaga, email, password);
    }
    yield take([
      logout.type,
      actions.failure.type,
      actions.fetchProfileFailure.type,
    ]);
    localStorage.removeItem('sessionToken');
    yield put({ type: clearReducers.type });
    if (task) {
      yield cancel(task);
    }
  }
}

function* reloadTimeZone() {
  yield put({ type: globalsActions.success.type });
}

export default function* rootSaga() {
  yield all([
    yield fork(watchLogin),
    yield takeLatest(
      [actions.fetchLoggedUser, actions.reloadProfile],
      fetchLoggedUserSaga,
    ),
    yield takeLatest(actions.reloadProfile, reloadTimeZone),
  ]);
}
