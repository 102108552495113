import React, { useEffect, useCallback, useState } from 'react';
import rest from 'util/Api';
import PropTypes from 'prop-types';
import JsDownload from 'js-file-download';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { notification } from 'antd';
import PaqueryTable from '@paquery-team/lib-table';
import ViewPackageButton from 'components/viewPackagebutton';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import ModalButton from 'components/modalButton';
import { actions } from 'redux/paquerView/slice';
import { actions as paquerActions } from 'redux/paquers/slice';
import { selectGlobals } from 'redux/globals/selectors';
import {
  paquerviewPackagesHistoryLoadedSelector,
  paquerviewPackagesHistoryPaginateSelector,
  paqueryviewPackagesHistoryDateSelector,
  paqueryviewPackagesHistoryItemsSelector,
  paqueryviewPackagesHistoryMarketSelector,
  paqueryviewPackagesHistorySearchSelector,
  paqueryviewPackagesHistoryStatusSelector,
  paqueryviewPackagesHistoryTypeSelector,
} from 'redux/paquerView/selectors';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import usePaquers from 'redux/paquers';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import { useFinalStatusOptions, useMarketplaceOptions } from 'hooks/useOptions';
import useMarketplaces from 'redux/marketplaces';
import EditRecordButton from 'components/editRecordButton';

const fetchHistoryCsv = async (
  search,
  paquerId,
  date,
  packageType,
  status,
  marketplaceId,
) => {
  try {
    const searchParams = new URLSearchParams({
      search,
      paquerId,
      to: date.to,
      from: date.from,
      packageType: packageType || '',
      status,
      marketplaceId: marketplaceId || '',
    });
    // eslint-disable-next-line import/no-named-as-default-member
    const response = await rest.get(
      `${API.reports.historyByPaquer}?${searchParams.toString()}`,
      {
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (error) {
    return undefined;
  }
};

const statusStyle = (statusText) => {
  let bgColor;
  switch (statusText) {
    case 'Entregado':
      bgColor = 'green';
      break;
    case 'Cancelado':
      bgColor = 'red';
      break;
    case 'Devuelto':
      bgColor = 'gold';
      break;
    default:
      break;
  }
  return {
    background: bgColor,
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };
};

const signatureAvatar = (record) => (
  <ModalButton
    name={record.signatureName}
    shape="square"
    image={record.signatureImage}
  />
);

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const signatureColumn = {
  title: 'Firma',
  render: (_, record) => signatureAvatar(record),
};

const signatureTypeColumn = {
  title: 'Tipo de Firma',
  dataIndex: 'signatureType',
  width: 140,
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  render: (text) => <span style={statusStyle(text)}>{text}</span>,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  statusColumn,
];

const fullSizeColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureColumn,
  signatureTypeColumn,
  statusColumn,
  viewPackageColumn,
  editColumn,
];

const columnsSmallDevice = [externalCodeColumn, viewPackageColumn, editColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  viewPackageColumn,
  editColumn,
];

const PackageHistory = ({ paquerId }) => {
  usePaquers();
  useMarketplaces({ initialize: true });
  const dispatch = useDispatch();
  const search = useSelector(paqueryviewPackagesHistorySearchSelector);
  const loaded = useSelector(paquerviewPackagesHistoryLoadedSelector);
  const globals = useSelector(selectGlobals);
  const paquers = useSelector(paquersItemsSelector);
  const date = useSelector(paqueryviewPackagesHistoryDateSelector);
  const packageType = useSelector(paqueryviewPackagesHistoryTypeSelector);
  const status = useSelector(paqueryviewPackagesHistoryStatusSelector);
  const items = useSelector(paqueryviewPackagesHistoryItemsSelector);
  const pageable = useSelector(paquerviewPackagesHistoryPaginateSelector);
  const marketFilter = useSelector(paqueryviewPackagesHistoryMarketSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const [paquerList, setPaquerList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const filteredFinalStatuses = useFinalStatusOptions();

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  useEffect(() => {
    if (paquers.length > 0) {
      setPaquerList(
        paquers.map((paquer) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [paquers]);
  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const csv = await fetchHistoryCsv(
        search,
        paquerId,
        date,
        packageType,
        status,
        marketFilter,
      );
      const paquerFullName = paquerId
        ? `${
            paquerList.find(
              (paquerOnList) => paquerOnList.value === parseInt(paquerId, 10),
            ).name
          }`
        : 'Paquer';
      const filename = `Paquetes-Historial-${paquerFullName}-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(csv, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearchHistory(value));
    },
    [dispatch],
  );

  const dateFilterCallback = useCallback(
    (newDate) => {
      dispatch(actions.updateDateHistory(newDate));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updateHistoryType(newPackageType));
    },
    [dispatch],
  );
  const selectStatusCallback = useCallback(
    (newStatus) => {
      dispatch(actions.updateStatusHistory(newStatus));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarketHistory(marketSelected));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };

  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      list: filteredFinalStatuses,
      onChange: selectStatusCallback,
      placeholder: 'Estado',
    },
  ];

  const rangePicker = {
    onDateSelection: dateFilterCallback,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      content: packet.caption,
      deliveryDate: packet.deliveryDate
        ? dayjs(packet.deliveryDate).tz().format(TableDateTimeFormat)
        : null,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      signatureImage: packet.signatureImage,
      signatureName: packet.shippingScheduleDestination.name,
      signatureType: globals.packages.deliveryEndorsementType.find(
        (endorsement) =>
          endorsement.value ===
          packet.shippingScheduleDestination.deliveryPackageType,
      )?.name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searcher,
        selectors,
        rangePicker,
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: handleExportCsv, disabled },
      }}
      onChangePaginate={(newPagination) =>
        dispatch(actions.updatePaginationHistory(newPagination))
      }
      dataSource={filteredPackages}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={columnsMediumDevice}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
      usePackageRowColors
    />
  );
};

PackageHistory.propTypes = {
  paquerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default PackageHistory;
