import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PACKAGE_TYPE } from 'constants/defaultValues';

export const initialState = {
  isLoading: false,
  paquerypoint: null,
  packages: {
    items: [],
    pageable: {
      pageSize: 10,
      pageNumber: 0,
      totalElements: 0,
    },
    search: '',
    status: '',
    date: {
      from: '',
      to: '',
    },
    type: DEFAULT_PACKAGE_TYPE,
    marketplaceId: null,
    loaded: true,
  },
  history: {
    items: [],
    pageable: {
      pageSize: 10,
      pageNumber: 0,
      totalElements: 0,
    },
    search: '',
    status: '',
    date: {
      from: '',
      to: '',
    },
    type: DEFAULT_PACKAGE_TYPE,
    marketplaceId: null,
    loaded: true,
  },
};

const paquerypointViewSlice = createSlice({
  name: 'paquerypoint-view',
  initialState,
  reducers: {
    initial: (state) => {
      state.packages.loaded = false;
    },
    initialHistory: (state) => {
      state.history.loaded = false;
    },
    loading: (state) => {
      state.isLoading = true;
    },
    loaded: (state) => {
      state.isLoading = false;
    },
    fetchPaquerypoint: (state) => {
      state.isLoading = true;
    },
    fetchPaquerypointSuccess: (state, action) => {
      state.isLoading = false;
      state.paquerypoint = action.payload;
    },
    fetchPaquerypointFailure: (state) => {
      state.isLoading = false;
    },
    fetchPackages: (state) => {
      state.packages.loaded = false;
    },
    fetchPackagesSuccess: (state, action) => {
      state.packages.items = action.payload.items;
      state.packages.pageable.totalElements = action.payload.total;
      state.packages.loaded = true;
    },
    fetchPackagesFailure: (state) => {
      state.packages.loaded = true;
    },
    fetchHistory: (state) => {
      state.history.loaded = false;
    },
    fetchHistorySuccess: (state, action) => {
      state.history.loaded = true;
      state.history.items = action.payload.items;
      state.history.pageable.totalElements = action.payload.total;
    },
    fetchHistoryFailure: (state) => {
      state.history.loaded = true;
    },
    reset: () => initialState,
    updateStatusPackages: (state, action) => {
      state.packages.pageable.pageNumber = 0;
      state.packages.status = action.payload;
    },
    updateSearchPackages: (state, action) => {
      state.packages.pageable.pageNumber = 0;
      state.packages.search = action.payload;
    },
    updateSortPackages: (state, action) => {
      state.packages.pageable.pageNumber = 0;
      state.packages.sort = action.payload;
    },
    updatePaginationPackages: (state, action) => {
      state.packages.pageable.pageNumber =
        action.payload.pageSize !== state.packages.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.packages.pageable.pageSize = action.payload.pageSize;
    },
    updateDate: (state, action) => {
      state.packages.date = action.payload;
      state.packages.pageable.pageNumber = 0;
    },
    updatePackageType: (state, action) => {
      state.packages.pageable.pageNumber = 0;
      state.packages.type = action.payload;
    },
    updateMarket: (state, action) => {
      state.packages.pageable.pageNumber = 0;
      state.packages.marketplaceId = action.payload;
    },
    refreshPagePackages: (state) => {
      state.packages.loaded = false;
    },
    updateSearchHistory: (state, action) => {
      state.history.pageable.pageNumber = 0;
      state.history.search = action.payload;
    },
    updateSortHistory: (state, action) => {
      state.history.pageable.pageNumber = 0;
      state.history.sort = action.payload;
    },
    updateDateHistory: (state, action) => {
      state.history.date = action.payload;
      state.history.pageable.pageNumber = 0;
    },
    updatePaginationHistory: (state, action) => {
      state.history.pageable.pageNumber =
        action.payload.pageSize !== state.history.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.history.pageable.pageSize = action.payload.pageSize;
    },
    updateMarketHistory: (state, action) => {
      state.history.pageable.pageNumber = 0;
      state.history.marketplaceId = action.payload;
    },
    updatePackageTypeHistory: (state, action) => {
      state.history.pageable.pageNumber = 0;
      state.history.type = action.payload;
    },
    updateStatusHistory: (state, action) => {
      state.history.pageable.pageNumber = 0;
      state.history.status = action.payload;
    },
    setHistoryLoading: (state) => {
      state.history.loaded = false;
    },
    setHistoryLoaded: (state) => {
      state.history.loaded = true;
    },
    refreshPageHistory: (state) => {
      state.history.loaded = false;
    },
  },
});

export const { name, actions, reducer } = paquerypointViewSlice;
