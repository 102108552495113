import { useState } from 'react';

const generateRandomColor = (): string => {
  const r = Math.floor(Math.random() * 128);
  const g = Math.floor(Math.random() * 128);
  const b = Math.floor(Math.random() * 128);
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const usePolygonColors = () => {
  const [polygonColors, setPolygonColors] = useState<{ [key: number]: string }>(
    {},
  );

  const getColorForPolygon = (zoneId: number): string => {
    if (!polygonColors[zoneId]) {
      const newColor = generateRandomColor();
      setPolygonColors((prevColors) => ({
        ...prevColors,
        [zoneId]: newColor,
      }));
      return newColor;
    }
    return polygonColors[zoneId];
  };

  return {
    getColorForPolygon,
  };
};

export default usePolygonColors;
