import React, { useEffect, useState } from 'react';
import API from 'constants/api';
import { useHistory, useParams } from 'react-router-dom';
import rest from 'util/Api';
import { useSelector, useDispatch } from 'react-redux';
import {
  marketplacesItemsSelector,
  marketplacesLoadedSelector,
} from 'redux/marketplaces/selectors';
import { authProfile } from 'redux/auth/selectors';
import { actions } from 'redux/marketplaces/slice';
import useMarketplaces from 'redux/marketplaces';
import { notification, Tabs, Spin } from 'antd';
import * as Yup from 'yup';
import Marketplace from '../marketplace';
import MarketplaceStoreList from '../storeList';
import MarketplaceAddStore from '../storeAdd';
import MarketplaceStoreModify from '../storeModify';
import StorePackages from '../storeModify/components/StorePackages';
import StoreHistoryPackages from '../storeModify/components/StoreHistoryPackages';

const { TabPane } = Tabs;

const initialValues = {
  name: '',
  detail: '',
  published: false,
  sendCustomerNotifications: false,
  stock: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    'Es necesario especificar el nombre del marketplace',
  ),
  commercialEmail: Yup.string().email('No tiene formato de mail').nullable(),
  emailSupport: Yup.string().email('No tiene formato de mail').nullable(),
  dispatchAddress: Yup.string().required(
    'Es necesario especificar una dirección de despacho',
  ),
});

const MarketplaceModify = () => {
  useMarketplaces({ initialize: true });
  const profile = useSelector(authProfile);
  const loaded = useSelector(marketplacesLoadedSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [successFetch, setSuccessFetch] = useState(false);
  const [marketplaceData, setMarketplaceData] = useState(null);
  const [currentTab, setCurrentTab] = useState('marketplace');
  const [mkpLoading, setMkpLoading] = useState(false);
  const fullItems = useSelector(marketplacesItemsSelector);
  const { marketplaceId } = useParams();
  const { storeId } = useParams();
  const [storeComponent, setStoreComponent] = useState(
    <MarketplaceStoreList marketplaceId />,
  );

  useEffect(() => {
    const getMarketplace = () => {
      if (loaded && fullItems) {
        const marketplace = fullItems.find(
          (mkp) => mkp.id === parseInt(marketplaceId, 10),
        );
        try {
          setMarketplaceData(marketplace);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          notification.error({
            message: 'Ha ocurrido un error',
            description: 'No se pudo obtener el marketplace indicado.',
          });
          setTimeout(() => {
            history.goBack();
          }, 1500);
        } finally {
          setSuccessFetch(true);
        }
      }
    };
    if (!successFetch) {
      getMarketplace();
    }
  }, [fullItems, successFetch, history, loaded, marketplaceId]);

  useEffect(() => {
    if (storeId === 'add') {
      setCurrentTab('stores');
      setStoreComponent(
        <MarketplaceAddStore marketplaceData={marketplaceData} />,
      );
    } else if (storeId) {
      setStoreComponent(
        <MarketplaceStoreModify
          marketplaceData={marketplaceData}
          storeId={storeId}
        />,
      );
      setCurrentTab('stores');
    } else {
      setStoreComponent(<MarketplaceStoreList marketplaceId />);
    }
  }, [storeId, marketplaceData]);

  const handleSubmit = async (values) => {
    const payload = {
      id: parseInt(marketplaceId, 10),
      logisticOperators: [
        {
          id: profile.logisticOperatorID,
        },
      ],
      name: values.name,
      detail: values.detail || '',
      published: values.published,
      sendCustomerNotifications: values.sendCustomerNotifications,
      stock: values.stock,
      private: profile.isPrivate,
      dispatchAddress: values.dispatchAddress,
      commercialContact: values.commercialContact,
      commercialPhone: values.commercialPhone,
      commercialEmail: values.commercialEmail,
      contactSupport: values.contactSupport,
      phoneSupport: values.phoneSupport,
      emailSupport: values.emailSupport,
      commercialAgent: values.commercialAgent,
    };
    setMkpLoading(true);
    try {
      const response = await rest.put(
        `${API.marketplaces.update}${marketplaceId}`,
        payload,
      );
      if (response.status === 400) {
        throw new Error(response.data.message);
      } else {
        dispatch(actions.refreshPage());
        notification.success({
          message: 'Actualización de Marketplace',
          description: `${values.name} ha sido actualizado correctamente.`,
        });
        history.push('/marketplaces');
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al actualizar el marketplace${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    } finally {
      setMkpLoading(false);
    }
  };
  const handleTabChange = (key) => {
    setCurrentTab(key);
    if (key !== 'packages' && key !== 'packagesHistory')
      history.push(`/marketplaces/${marketplaceId}`);
    if (key === 'marketplace') {
      setStoreComponent(<MarketplaceStoreList marketplaceId />);
    }
  };

  return (
    <Spin spinning={!loaded}>
      <Tabs
        activeKey={currentTab}
        size="large"
        centered
        onChange={handleTabChange}
      >
        <TabPane tab="Marketplace" key="marketplace">
          <Marketplace
            initialValues={marketplaceData || initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            loading={mkpLoading}
          />
        </TabPane>
        <TabPane tab="Tiendas" key="stores">
          {storeComponent}
        </TabPane>
        <TabPane
          tab="Paquetes"
          key="packages"
          disabled={storeId === 'add' || !storeId}
        >
          <StorePackages />
        </TabPane>
        <TabPane
          tab="Historial"
          key="packagesHistory"
          disabled={storeId === 'add' || !storeId}
        >
          <StoreHistoryPackages />
        </TabPane>
      </Tabs>
    </Spin>
  );
};
export default MarketplaceModify;
