import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useSelector, shallowEqual } from 'react-redux';
import { name, reducer } from './slice';
import saga from './saga';
import makePaquerviewSelector from './selectors';

export default function usePaquerypointView() {
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useSelector(makePaquerviewSelector(), shallowEqual);
  return null;
}
