import API from 'constants/api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { actions } from './slice';

function* fetchSaga({ payload: { departure } }) {
  try {
    const searchParams = new URLSearchParams({
      page: 0,
      take: 10000,
      size: 10000,
    });
    const packages = yield call(
      getData,
      `${API.departures.getById(departure.id)}?${searchParams.toString()}`,
    );
    const payload = {
      items: packages.content,
      totals: packages.numberOfElements,
    };
    yield put({ type: actions.success.type, payload });
  } catch (error) {
    console.log('error fetching departure history: ', error.message);
    yield put({ type: actions.failure.type });
  }
}

export default function* rootSaga() {
  yield takeEvery([actions.fetch, actions.initial], fetchSaga);
}
