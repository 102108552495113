import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const paquersSelector = (state) => state[name] || initialState;

export const paquersItemsSelector = (state) => Object.values(state[name].items);

export const paquersSearchSelector = (state) => state[name].search;

export const paquersLoadedSelector = (state) => state[name].loaded;

export const paquersPageableSelector = (state) => state[name].pageable;

const makePaquerSelector = () =>
  createSelector(paquersSelector, (substate) => substate);

export const paquersFilteredItemsSelector = createSelector(
  paquersItemsSelector,
  paquersPageableSelector,
  paquersSearchSelector,
  (items, { pageSize, pageNumber }, search) => {
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const paquerNameIgnoreCase = `${item.name.toUpperCase()} ${item.lastName.toUpperCase()}`;
        const paquerEmailIgnoreCase = item.email.toUpperCase();
        return (
          paquerNameIgnoreCase.includes(searchIgnoreCase) ||
          paquerEmailIgnoreCase.includes(searchIgnoreCase)
        );
      });
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makePaquerSelector;
