import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Flex, Form, Modal } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import GoogleMap from 'routes/app/zone/components/map/GoogleMap';
import { useUpdateGroupingZone, useZones, Zone } from 'services/ZoneService';
import Card from 'components/card';
import { DebouncedInputField, DebouncedTextAreaField } from 'components/Forms';
import { ChildrenZonesSelector } from './components/childrenZonesSelector';

interface GroupingZoneEditParams {
  id?: string;
}

const GroupingZoneEdit = () => {
  const { id } = useParams<GroupingZoneEditParams>();
  const history = useHistory();
  const {
    zones: { data: zones },
    groupingZones: {
      isSuccess: isGroupingZonesSuccess,
      isLoading: isGroupingZonesLoading,
      isFetching: isGroupingZonesFetching,
      isPlaceholderData: isGroupingZonesPlaceholderData,
    },
    getZoneById,
  } = useZones();
  const { mutate: updateGroupingZone } = useUpdateGroupingZone();

  const [name, setName] = useState<string>();
  const [detail, setDetail] = useState<string>();
  const [open, setOpen] = useState(false);
  const [childrenZonesIds, setChildrenZonesIds] = useState<number[]>([]);

  const zone = useMemo(() => {
    if (
      !isGroupingZonesLoading &&
      !isGroupingZonesFetching &&
      !isGroupingZonesPlaceholderData &&
      id
    )
      return getZoneById(id);
    return { name: '', detail: '' };
  }, [
    isGroupingZonesLoading,
    isGroupingZonesFetching,
    isGroupingZonesPlaceholderData,
    id,
  ]);

  const selectedChildrenZones = useMemo(
    () => zones.filter((z: Zone) => childrenZonesIds.includes(z.id!)),
    [childrenZonesIds, zones],
  );

  const initialChildrenZones = useMemo(() => zone.childrenZones ?? [], [zone]);

  useEffect(() => {
    if (zone) {
      setName(zone.name);
      setDetail(zone.detail);
      if (zone.childrenZones)
        setChildrenZonesIds(zone.childrenZones.map((z: Zone) => z.id));
    }
  }, [zone]);

  if (!isGroupingZonesSuccess || !id) return history.push('/zones');

  const handleSubmit = () => {
    const zoneToUpdate = {
      ...zone,
      detail,
      name,
      childrenZones: childrenZonesIds,
    };

    updateGroupingZone(zoneToUpdate);
    history.goBack();
  };

  return (
    <>
      <Modal
        title={`¿Confirmar edición de zona agrupadora ${name}?`}
        okText="Confirmar"
        cancelText="Volver"
        onOk={handleSubmit}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Flex
        vertical={false}
        gap={16}
        style={{ width: '100%', minHeight: '100%' }}
      >
        <Flex vertical style={{ width: '20%' }}>
          <Card title="Datos de la zona">
            <Flex justify="space-between">
              <label>Nombre:</label>
              {name != zone.name && (
                <RedoOutlined onClick={() => setName(zone.name)} />
              )}
            </Flex>
            <Form.Item
              validateStatus={name != zone.name ? 'warning' : 'success'}
            >
              <DebouncedInputField
                placeholder="Nombre"
                value={name}
                setValue={setName}
              />
            </Form.Item>
            <Flex justify="space-between">
              <label>Descripción:</label>
              {detail != zone.detail && (
                <RedoOutlined onClick={() => setDetail(zone.detail)} />
              )}
            </Flex>
            <Form.Item
              validateStatus={detail != zone.detail ? 'warning' : 'success'}
            >
              <DebouncedTextAreaField
                rows={6}
                placeholder="Informacion adicional de esa zona"
                value={detail}
                setValue={setDetail}
              />
            </Form.Item>
          </Card>
          <ChildrenZonesSelector
            parentId={zone.id}
            initialChildrenZones={initialChildrenZones}
            selectedChildrenZones={selectedChildrenZones}
            setChildrenZonesIds={setChildrenZonesIds}
          />
        </Flex>
        <div style={{ width: '80%', minHeight: '100%' }}>
          <GoogleMap
            arePolygonsLoading={
              isGroupingZonesLoading ||
              isGroupingZonesFetching ||
              isGroupingZonesPlaceholderData
            }
            initialCenter={{
              lat: -34.601692,
              lng: -58.431461,
            }}
            polygons={selectedChildrenZones}
          />
        </div>
      </Flex>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        disabled={!name || !detail || childrenZonesIds.length < 1}
        size="large"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginRight: 80,
          marginBottom: 60,
        }}
      >
        Guardar Cambios
      </Button>
    </>
  );
};

export default GroupingZoneEdit;
