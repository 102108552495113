import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Flex, Form, Input, Tooltip } from 'antd';
import Modal from 'components/modal';
import { authProfile } from 'redux/auth/selectors';
import GoogleMap from 'routes/app/zone/components/map/GoogleMap';
import Card from 'components/card';
import { Point, useCreateZone, useZones } from 'services/ZoneService';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ZipCodeSelector } from './components/zipCodeSelector';

const { TextArea } = Input;

const ZoneAdd = () => {
  const history = useHistory();
  const profile = useSelector(authProfile);
  const {
    zones: { data: zones, isSuccess, isLoading, isFetching },
  } = useZones();
  const { mutate: createZone } = useCreateZone();

  const [polygon, setPolygon] = useState<Point[]>();
  const [name, setName] = useState<string>();
  const [detail, setDetail] = useState<string>();
  const [interurbanId, setInterurbanId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [zipCodes, setZipCodes] = useState<string[]>([]);

  if (!isSuccess) return history.push('/zones');

  const handleSubmit = () => {
    const geoJson =
      !!polygon && !!polygon.length && polygon.length > 0
        ? {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                polygon?.map((p: Point) => [
                  parseFloat(p.lng),
                  parseFloat(p.lat),
                ]),
              ],
            },
            properties: {},
          }
        : undefined;
    const tenant = profile?.tenantId;
    const logisticOperatorId = profile?.logisticOperatorID;

    const zone = {
      name,
      detail,
      cdcId: interurbanId,
      tenant,
      logisticOperatorId,
      zipCodes,
      geoJson: geoJson ? JSON.stringify(geoJson) : undefined,
    };

    createZone(zone);
    history.goBack();
  };

  return (
    <>
      <Modal
        title={`¿Confirmar creación de zona ${name}?`}
        okText="Confirmar"
        cancelText="Volver"
        onOk={handleSubmit}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Flex
        vertical={false}
        gap={16}
        style={{ width: '100%', minHeight: '100%' }}
      >
        <Flex vertical style={{ width: '20%' }}>
          <Card title="Datos de la zona">
            <label>Nombre:</label>
            <Form.Item validateStatus="warning">
              <Input
                placeholder="Nombre"
                onBlur={(event) => setName(event.target.value)}
                onPressEnter={({ target }) => {
                  setName((target as HTMLInputElement).value);
                }}
              />
            </Form.Item>
            <label>Descripción:</label>
            <Form.Item validateStatus="warning">
              <TextArea
                rows={3}
                placeholder="Informacion adicional de esa zona"
                onBlur={(event) => setDetail(event.target.value)}
                onPressEnter={({ target }) => {
                  setDetail((target as HTMLInputElement).value);
                }}
              />
            </Form.Item>
            <label>Identificador inter-urbano:</label>
            <Form.Item>
              <Input
                placeholder="Número de identificación"
                onBlur={(event) => setInterurbanId(event.target.value)}
                onPressEnter={({ target }) => {
                  setInterurbanId((target as HTMLInputElement).value);
                }}
                suffix={
                  <Tooltip title="El identificador de inter-urbano es un número necesario para el cálculo de las tarifas inter-urbano, entre ciudades, o también llamadas troncales.">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Card>
          <ZipCodeSelector
            selectedZipCodes={zipCodes}
            setZipCodes={setZipCodes}
          />
        </Flex>
        <div style={{ width: '80%', minHeight: '100%' }}>
          <GoogleMap
            arePolygonsLoading={isLoading || isFetching}
            initialCenter={{
              lat: -34.601692,
              lng: -58.431461,
            }}
            isAddZone
            onPolygonChange={(p) => setPolygon(p)}
            polygons={zones}
          />
        </div>
      </Flex>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        disabled={
          !polygon || (!!polygon && !polygon.length) || !name || !detail
        }
        size="large"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginRight: 80,
          marginBottom: 60,
        }}
      >
        Crear nueva Zona
      </Button>
    </>
  );
};

export default ZoneAdd;
