import React from 'react';
import PropTypes from 'prop-types';
import { Card as AntdCard, theme } from 'antd';

export default function Card({ children, ...props }) {
  const { token } = theme.useToken();
  return (
    <AntdCard
      bordered={false}
      headStyle={{
        borderBottom: `3px solid ${token.colorPrimary}`,
      }}
      {...props}
    >
      {children}
    </AntdCard>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.shape(),
};

Card.defaultProps = {
  children: <></>,
};
