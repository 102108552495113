import Api from 'util/Api';
import API from 'constants/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

interface GetMarketplacesParams {
  take: number;
  size: number;
  search: string;
}

export interface Marketplace {
  ownerId: number;
  private: boolean;
  detail: string;
  published: boolean;
  stock: boolean;
  ownerType: number;
  active: boolean;
  logisticOperators: {
    logisticOperatorID: number;
  }[];
  sendCustomerNotifications: boolean;
  clientCode: string;
  contactSupport: string;
  commercialContact: string;
  commercialEmail: string;
  commercialPhone: string;
  phoneSupport: string;
  emailSupport: string;
  commercialAgent: string;
  dispatchAddress: string;
  customerId: number;
  name: string;
  id: number;
}

interface GetMarketplacesData {
  content: Marketplace[];
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

interface GetMarketplacesResponse {
  data?: GetMarketplacesData;
  message: string;
}

const getAllMarketplaces = async (queries: GetMarketplacesParams) => {
  const response = await Api.apiAxios.get<GetMarketplacesResponse>(
    `${API.marketplaces.get}`,
    {
      params: queries,
    },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const useMarketplaces = (params?: GetMarketplacesParams) => {
  const query = useMemo(() => {
    return {
      take: params?.take ? Number(params?.take) : 10000,
      size: params?.size ? Number(params?.size) : 10000,
      search: params?.search ? params?.search : '',
    };
  }, [params]);

  return useQuery({
    queryKey: ['marketplaces', query],
    queryFn: () => getAllMarketplaces(query),
  });
};
