import React, { useEffect, useMemo, useState } from 'react';
import { Button, Space, Tag, Input, Popconfirm, Tooltip, Select } from 'antd';
import Card from 'components/card';
import { UndoOutlined } from '@ant-design/icons';
import { useZones, Zone } from 'services/ZoneService';
import { DefaultOptionType, OptionProps } from 'antd/es/select';

interface ZoneTagProps {
  zone: Zone;
  removeCallback: (args: any) => void;
}

interface ChildrenZoneSelectorProps {
  parentId?: number;
  initialChildrenZones?: Zone[];
  selectedChildrenZones: Zone[];
  setChildrenZonesIds: (args: any) => void;
}

function ZoneTag({ zone, removeCallback }: ZoneTagProps) {
  const { id, fullName } = zone;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popconfirm
      open={isOpen}
      title="Confirmar eliminación"
      description="¿Seguro que desea remover esta zona?"
      onConfirm={() => {
        removeCallback(id);
        setIsOpen(false);
      }}
      onCancel={() => setIsOpen(false)}
      okText="Yes"
      cancelText="No"
    >
      <Tag
        key={id}
        closable
        style={{ marginBottom: '4px' }}
        onClose={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        {fullName}
      </Tag>
    </Popconfirm>
  );
}

export const ChildrenZonesSelector = ({
  parentId,
  initialChildrenZones,
  selectedChildrenZones,
  setChildrenZonesIds,
}: ChildrenZoneSelectorProps) => {
  const [selectedZoneId, setSelectedZoneId] = useState<number>();
  const {
    zones: { data: zones },
  } = useZones();

  const selectableZoneOptions = useMemo(() => {
    const selectedChildrenZonesIds = selectedChildrenZones.map((z) => z.id);
    return zones
      .filter(
        (z: Zone) =>
          (!z.parent || z.parent == `/zones/${parentId}`) &&
          !selectedChildrenZonesIds.includes(z.id!),
      )
      .map((z: Zone) => ({ value: z.id, label: z.fullName }));
  }, [zones, selectedChildrenZones]);

  function loadChildrenZones() {
    if (initialChildrenZones) {
      const childrenZones = initialChildrenZones.map((z: Zone) => z.id);
      setChildrenZonesIds(childrenZones);
    }
    setSelectedZoneId(undefined);
  }

  useEffect(loadChildrenZones, [initialChildrenZones]);

  function addChildrenZone(zoneId: number) {
    setChildrenZonesIds((prev: number[]) => {
      const _zipCodes = [...prev];
      _zipCodes.push(zoneId);
      return _zipCodes.sort((a, b) => a - b);
    });
    setSelectedZoneId(undefined);
  }

  function removeChildrenZone(zoneId: number) {
    setChildrenZonesIds((prev: number[]) => prev.filter((id) => id != zoneId));
  }

  return (
    <Card title="Zonas agrupadas" style={{ marginTop: '16px', height: '60%' }}>
      <Space.Compact style={{ width: '100%', marginBottom: '16px' }}>
        <Select
          style={{ width: '99%', overflow: 'hidden' }}
          allowClear
          showSearch
          options={selectableZoneOptions}
          placeholder="Seleccione una zona"
          value={selectedZoneId}
          onSelect={(value) => setSelectedZoneId(value)}
          onClear={() => {
            setSelectedZoneId(undefined);
          }}
          optionFilterProp="label"
          filterSort={(
            optionA: DefaultOptionType,
            optionB: DefaultOptionType,
          ) =>
            (optionA?.label as string)
              .toLowerCase()
              .localeCompare((optionB?.label as string).toLowerCase())
          }
          notFoundContent={null}
        />
        <Button
          disabled={!selectedZoneId}
          type="primary"
          onClick={() => addChildrenZone(selectedZoneId!)}
        >
          Agregar
        </Button>
        {!!initialChildrenZones && (
          <Popconfirm
            title="Confirmar deshacer"
            description="¿Seguro que desea deshacer los cambios?"
            onConfirm={loadChildrenZones}
            okText="Si"
            cancelText="No"
          >
            <Button type="primary" style={{ background: 'orange' }}>
              <UndoOutlined style={{ fontSize: '18' }} />
            </Button>
          </Popconfirm>
        )}
      </Space.Compact>
      {selectedChildrenZones &&
        !!selectedChildrenZones.length &&
        selectedChildrenZones.length > 0 &&
        selectedChildrenZones.map((z: Zone) => (
          <ZoneTag key={z.id} zone={z} removeCallback={removeChildrenZone} />
        ))}
    </Card>
  );
};
