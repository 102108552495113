import Api from 'util/Api';
import API from 'constants/api';
import { useQuery } from '@tanstack/react-query';

interface Driver {
  id: number;
  name: string;
  lastName: string;
  email: string;
  mobile: string;
  docNumber: string;
  active: boolean;
  status: number;
}

interface DriversContent {
  content: Driver[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  totalPages: number;
  last: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  size: number;
  number: number;
  empty: boolean;
}

interface DriversResponse {
  data: DriversContent;
  code: number;
  message: string;
}

const getAllDrivers = async () => {
  const searchParams = new URLSearchParams({
    size: '10000',
    search: '',
  });
  const response = await Api.apiAxios.get<DriversResponse>(
    `${API.paquer.getAll}?${searchParams.toString()}`,
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error(response.data.message);
  }

  return response.data.data;
};

export const useDrivers = () => {
  return useQuery<DriversContent, Error>({
    queryKey: ['drivers'],
    queryFn: getAllDrivers,
    retry: 1,
  });
};
