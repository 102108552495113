import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import API from 'constants/api';
import rest from 'util/Api';
import { actions } from './slice';
import { usersItemsSelector } from './selectors';

function* fetchSaga() {
  try {
    const searchParams = new URLSearchParams({
      search: '',
      take: 10000,
      page: 0,
      desc: true,
      status: '',
      userType: '',
      userRol: '',
    });
    const users = yield call(
      rest.get,
      `${API.user.getAll}?${searchParams.toString()}`,
    );
    yield put({ type: actions.success.type, payload: users.data.data });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* initialSaga() {
  const items = yield select(usersItemsSelector);
  if (!items || items.length === 0) {
    yield put({ type: actions.fetch.type });
  }
}

function* refreshPageSaga() {
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.fetch, fetchSaga),
    takeEvery(actions.refreshPage, refreshPageSaga),
  ]);
}
