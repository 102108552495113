import { createSelector } from 'reselect';
import { initialState, name } from './slice';

export const userviewSelector = (state) => state[name] || initialState;

export const userviewAvatarSelector = (state) => state[name].avatar;

export const userviewLoadedSelector = (state) => state[name].loaded;

export const userviewUserSelector = (state) => state[name].user;

const makeUserviewSelector = () =>
  createSelector(userviewSelector, (substate) => substate);

export default makeUserviewSelector;
