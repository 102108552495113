export const buildPutPackage = (sourcePackage) => {
  const builtPackage = {
    id: sourcePackage.id,
    externalCode: sourcePackage.externalCode,
    caption: sourcePackage.caption,
    estimatedCost: sourcePackage.estimatedCost,
    status: sourcePackage.status,
    reason: sourcePackage.reason,
    detail: sourcePackage.detail,
    deliveryTerm: sourcePackage.deliveryTerm,
    packageSize: sourcePackage.packageSize,
    packageType: sourcePackage.packageType,
    ownerID: sourcePackage.ownerID,
    arrivedAtPaqueryPointDate: sourcePackage.arrivedAtPaqueryPointDate,
    additionalCode: sourcePackage.additionalCode,
    shippingScheduleOrigin: {
      name: sourcePackage.shippingScheduleOrigin.name,
      phone: sourcePackage.shippingScheduleOrigin.phone,
      comment: sourcePackage.shippingScheduleOrigin.comment,
      zone: sourcePackage.shippingScheduleOrigin.zone,
      distributionZone: sourcePackage.shippingScheduleOrigin.distributionZone,
      shippingAddress: {
        addressDetail:
          sourcePackage.shippingScheduleOrigin.shippingAddress.addressDetail,
        lat: sourcePackage.shippingScheduleOrigin.shippingAddress.lat,
        lng: sourcePackage.shippingScheduleOrigin.shippingAddress.lng,
        geoKey: sourcePackage.shippingScheduleOrigin.shippingAddress.geoKey,
        comment: sourcePackage.shippingScheduleOrigin.shippingAddress.comment,
      },
      addressDetail:
        sourcePackage.shippingScheduleOrigin.shippingAddress.addressDetail,
    },
    shippingScheduleDestination: {
      name: sourcePackage.shippingScheduleDestination.name,
      phone: sourcePackage.shippingScheduleDestination.phone,
      destinationEmail:
        sourcePackage.shippingScheduleDestination.destinationEmail,
      comment: sourcePackage.shippingScheduleDestination.comment,
      scheduledDate: sourcePackage.shippingScheduleDestination.scheduledDate,
      paymentOnDelivery:
        sourcePackage.shippingScheduleDestination.paymentOnDelivery,
      paymentAmount: sourcePackage.shippingScheduleDestination.paymentAmount,
      shippingAddress: {
        addressDetail:
          sourcePackage.shippingScheduleDestination.shippingAddress
            .addressDetail,
        lat: sourcePackage.shippingScheduleDestination.shippingAddress.lat,
        lng: sourcePackage.shippingScheduleDestination.shippingAddress.lng,
        geoKey:
          sourcePackage.shippingScheduleDestination.shippingAddress.geoKey,
        comment:
          sourcePackage.shippingScheduleDestination.shippingAddress.comment,
      },
      zone: sourcePackage.shippingScheduleDestination.zone,
      zoneID: sourcePackage.shippingScheduleDestination.zone?.id,
      distributionZone:
        sourcePackage.shippingScheduleDestination.distributionZone,
      distributionZoneID:
        sourcePackage.shippingScheduleDestination.distributionZone?.id,
    },
  };
  return builtPackage;
};

export default {};
