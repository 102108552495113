import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const storeSelector = (state) => state[name] || initialState;

export const storeItemsSelector = (state) => state[name].items;

export const storeOwnerMarketplaceIdSelector = (state) =>
  state[name].ownerMarketplaceId;

export const storeLoadedSelector = (state) => state[name].loaded;

export const storeSearchSelector = (state) => state[name].search;

export const storePageableSelector = (state) => state[name].pageable;

const makeStoreSelector = () =>
  createSelector(storeSelector, (substate) => substate);

export default makeStoreSelector;
