import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from 'constants/api';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import Api from 'util/Api';
import JsDownload from 'js-file-download';
import { ExportDateFormat } from 'constants/dateFormats';
import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';
import { AxiosError } from 'axios';

export interface AssigneeStore {
  id: number;
  name: string;
  address: string;
  geo: {
    lat: number;
    lng: number;
  };
}

export enum AssigneeRouteEventType {
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  FAILED_DELIVERY = 'FAILED_DELIVERY',
  SUCCESS_DELIVERY = 'SUCCESS_DELIVERY',
  GEOLOCATION = 'GEOLOCATION',
  TRAIL_START = 'TRAIL_START',
  ROUTE_FINISHED = 'ROUTE_FINISHED',
}

export enum RouteStatus {
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  TRAIL_IN_PROGRESS = 'TRAIL_IN_PROGRESS',
  ROUTE_FINISHED = 'ROUTE_FINISHED',
}

export interface IAssigneeDriver {
  id: string;
  driverId: number;
  name: string;
  logisticOperatorId: number;
  assignedStore: AssigneeStore;
}

export interface IAssigneeRouteEvent {
  timestamp: string;
  event: AssigneeRouteEventType;
  description: string;
  shipmentId: string;
  geo: {
    lat: number;
    lng: number;
  };
}

export interface GeolocationFullResponse {
  event: string;
  is_moving: boolean;
  uuid: string;
  timestamp: string;
  odometer: number;
  coords: {
    latitude: number;
    longitude: number;
    accuracy: number;
    speed: number;
    speed_accuracy: number;
    heading: number;
    heading_accuracy: number;
    altitude: number;
    altitude_accuracy: number;
  };
  activity: {
    type: string;
    confidence: number;
  };
  battery: {
    is_charging: boolean;
    level: number;
  };
  extras?: Record<string, any>;
}

export interface IAssigneeRoute {
  id: string;
  status: RouteStatus;
  shipmentsDelivered: number;
  shipmentsFailedToDeliver: number;
  shipmentsDeclared: number;
  logisticOperatorId: number;
  kilometersDeclared: number;
  events: IAssigneeRouteEvent[];
  assigneeDriver: IAssigneeDriver;

  kilometersComputed?: number;
  geolocations?: GeolocationFullResponse[];
}

interface GetRoutesQueries {
  dateFrom?: string | null;
  dateTo?: string | null;
  driverId?: number | null;
  storeId?: number | null;
  page?: number | null;
  size?: number | null;
}

interface GetRoutesResponse {
  metadata: {
    totalPages: number;
    pageNumber: number;
    totalElements: number;
  };
  routes: IAssigneeRoute[];
  message?: string;
}

interface GetRouteResponse {
  route: IAssigneeRoute;
}

const getRoutes = async (
  queries?: GetRoutesQueries,
): Promise<GetRoutesResponse> => {
  const response = await Api.apiAxios.get<GetRoutesResponse>(
    api.assignee.routes.getAll,
    {
      params: queries,
    },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const useAssigneeRoutes = (queries?: GetRoutesQueries) => {
  const query = useMemo(() => {
    return {
      page: queries?.page ? Number(queries.page) : 1,
      size: queries?.size ? Number(queries.size) : 10,
      driverId: queries?.driverId ? Number(queries.driverId) : undefined,
      storeId: queries?.storeId ? Number(queries.storeId) : undefined,
      dateFrom: queries?.dateFrom
        ? dayjs(queries.dateFrom).toISOString() // Genera el formato correcto
        : undefined,
      dateTo: queries?.dateTo ? dayjs(queries.dateTo).toISOString() : undefined,
    } satisfies GetRoutesQueries;
  }, [queries]);

  return useQuery<GetRoutesResponse, Error>({
    queryKey: ['assigneeRoutes', query],
    queryFn: () => getRoutes(query),
    retry: 1,
  });
};

const getAssigneeRoute = async (routeId: string) => {
  const response = await Api.apiAxios.get<GetRouteResponse>(
    `${api.assignee.routes.getById(routeId)}`,
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido obtener la ruta');
  }

  return response.data.route;
};

export const useAssigneeRoute = (routeId?: string) => {
  return useQuery<IAssigneeRoute, Error>({
    queryKey: ['assigneeRoute', routeId],
    queryFn: () => getAssigneeRoute(routeId || ''),
    retry: 1,
    enabled: !!routeId,
  });
};

const downloadReport = async (route: IAssigneeRoute, timeOffset: number) => {
  const search = new URLSearchParams();
  search.set('timeOffset', timeOffset.toString());
  const response = await Api.apiAxios.get(
    `${api.assignee.routes.downloadReportByRouteId(route.id)}?${search.toString()}`,
    {
      responseType: 'blob',
    },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido descargar el reporte');
  }

  const filename = `Asignacion-${route.assigneeDriver.assignedStore.name}-${dayjs().format(ExportDateFormat)}.pdf`;
  JsDownload(response.data, filename);

  return true;
};

export const useDownloadReport = () => {
  const profile = useSelector(authProfile);

  // i hate myself for this
  const timeOffset = useMemo(() => {
    switch (profile?.countryID) {
      // Argentina
      case 1:
        return -3;
      // Chile
      case 2:
        return -4;
      // Uruguay
      case 3:
        return -3;
      // Bolivia
      case 4:
        return -4;
      // Estados Unidos
      case 5:
        return -5;
      // Guatemala
      case 6:
        return -6;
      // Paraguay
      case 7:
        return -3;
      // Colombia
      case 8:
        return -5;
      // Venezuela
      case 9:
        return -4;
      // Ecuador
      case 10:
        return -5;
      // Peru
      case 11:
        return -5;
      // Mexico
      case 12:
        return -6;
      default:
        return 0;
    }
  }, [profile]);

  return useMutation({
    mutationFn: (route: IAssigneeRoute) => downloadReport(route, timeOffset),
  });
};

const getAssigneeDrivers = async () => {
  const response = await Api.apiAxios.get<IAssigneeDriver[]>(
    api.assignee.drivers.getAll,
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se han podido obtener los conductores');
  }

  return response.data;
};

export const useAssigneeDrivers = () => {
  return useQuery<IAssigneeDriver[], Error>({
    queryKey: ['assigneeDrivers'],
    queryFn: getAssigneeDrivers,
    retry: 1,
  });
};

const deleteAssigneeDriver = async (driverId: number) => {
  try {
    const response = await Api.apiAxios.delete<{ message: string }>(
      api.assignee.drivers.deleteById(driverId),
    );

    if (!Api.isSuccessResponse(response)) {
      throw new Error(response.data.message);
    }

    return true;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    }
    return false;
  }
};

export const useDeleteAssigneeDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (driverId: number) => deleteAssigneeDriver(driverId),
    onSuccess: (_, driverId) => {
      const assigneeDrivers = queryClient.getQueryData<IAssigneeDriver[]>([
        'assigneeDrivers',
      ]);

      const newAssigneeDrivers = assigneeDrivers?.filter(
        (driver) => driver.driverId !== driverId,
      );

      queryClient.setQueryData<IAssigneeDriver[]>(
        ['assigneeDrivers'],
        newAssigneeDrivers,
      );

      queryClient.invalidateQueries({
        queryKey: ['assigneeDrivers'],
      });
    },
  });
};

interface CreateAssigneeDriverBody {
  store: {
    id: number;
    name: string;
    address: string;
    geo: {
      lat: number;
      lng: number;
    };
  };

  driver: {
    id: string;
    name: string;
  };

  logisticOperatorId: number;
}

const createAssigneeDriver = async (body: CreateAssigneeDriverBody) => {
  try {
    const response = await Api.apiAxios.post<{ message: string }>(
      api.assignee.drivers.create,
      body,
    );

    if (!Api.isSuccessResponse(response)) {
      throw new Error('No se ha podido crear el conductor');
    }

    return true;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    }
    return false;
  }
};

export const useCreateAssigneeDriver = () => {
  const queryClient = useQueryClient();
  const profile = useSelector(authProfile);

  return useMutation({
    mutationFn: (body: {
      store: AssigneeStore;
      driver: { id: string; name: string };
    }) => {
      return createAssigneeDriver({
        ...body,
        logisticOperatorId: profile?.logisticOperatorID,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['assigneeDrivers'],
      });
    },
  });
};
