import React, { useState } from 'react';
import * as Yup from 'yup';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import API from 'constants/api';
import rest from 'util/Api';
import { useDispatch } from 'react-redux';
import { actions as marketplaceActions } from 'redux/marketplaces/slice';
import Marketplace from '../marketplace';

const initialValues = {
  name: '',
  detail: '',
  published: false,
  sendCustomerNotifications: false,
  stock: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    'Es necesario especificar el nombre del marketplace',
  ),
  commercialEmail: Yup.string().email('No tiene formato de mail'),
  emailSupport: Yup.string().email('No tiene formato de mail'),
  dispatchAddress: Yup.string().required(
    'Es necesario especificar una dirección de despacho',
  ),
});

const MarketplaceAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await rest.post(API.marketplaces.create, values);
      if (response.status === 400) {
        throw new Error(response.data.message);
      } else {
        notification.success({
          message: 'Alta de marketplace correcto',
          description: `${values.name} ha sido dado de alta como marketplace correctamente.`,
        });
        dispatch(marketplaceActions.refreshPage());
        history.push('/marketplaces');
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al dar el alta de marketplace${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Marketplace
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      loading={loading}
    />
  );
};

export default MarketplaceAdd;
