import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const usersSelector = (state) => state[name] || initialState;

export const usersItemsSelector = (state) => Object.values(state[name].items);

export const usersSearchSelector = (state) => state[name].search;

export const usersLoadedSelector = (state) => state[name].loaded;

export const usersPageableSelector = (state) => state[name].pageable;

export const usersStatusSelector = (state) => state[name].status;

export const usersRolesSelector = (state) => state[name].role;

const makeUserSelector = () =>
  createSelector(usersSelector, (substate) => substate);

export const userFilterItemsSelector = createSelector(
  usersItemsSelector,
  usersSearchSelector,
  usersRolesSelector,
  usersStatusSelector,
  (items, search, role, status) => {
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const storeNameIgnoreCase = item.name.toUpperCase();
        return storeNameIgnoreCase.includes(searchIgnoreCase);
      });
    }
    if (role) {
      resultItems = resultItems.filter((item) => item.userRoleID === role);
    }

    if (status) {
      resultItems = resultItems.filter((item) => item.statusID === status);
    }

    return resultItems;
  },
);

export const usersPageableItemsSelector = createSelector(
  userFilterItemsSelector,
  usersPageableSelector,
  (filterItems, { pageSize, pageNumber }) => {
    let resultItems = filterItems;
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makeUserSelector;
