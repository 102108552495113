/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal } from 'antd';
import QrReader from 'react-qr-reader';

interface Props {
  onError?: (error: string) => void;
  onScan: (data: string | null) => void;
  onLoad?: () => void;
  showCamera: boolean;
  setShowCamera: (showCamera: boolean) => void;
  cameraStyle?: React.CSSProperties;
}

const QRScanner = ({
  cameraStyle,
  onError = () => {},
  onScan,
  setShowCamera,
  showCamera,
  onLoad,
}: Props) => {
  return (
    <Modal
      open={showCamera}
      onCancel={() => setShowCamera(false)}
      destroyOnClose
      footer={null}
    >
      <div
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <QrReader
          facingMode="environment"
          delay={500}
          style={cameraStyle}
          onError={onError}
          onScan={onScan}
          onLoad={onLoad}
        />
      </div>
    </Modal>
  );
};

QRScanner.defaultProps = {
  cameraStyle: {
    height: '40%',
    width: '80%',
  },
  onLoad: () => {},
  onError: () => {},
};

export default QRScanner;
