import React, { useMemo } from 'react';
import { Layout, Typography, Button } from 'antd';
import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';

const { Header, Content } = Layout;
const { Title } = Typography;

const CHAZKI_TENANTS = [
  39, // argentina
  40, // chile
  42, // peru
  45, // mexico
  48, // colombia
];

const Support = () => {
  const profile = useSelector(authProfile);

  const isChazkiOperator = useMemo(() => {
    return CHAZKI_TENANTS.includes(profile?.tenantId);
  }, [profile]);

  const supportLink = useMemo(() => {
    if (!isChazkiOperator) {
      return 'https://sites.google.com/chazki.com/centrodegestionsaas';
    }

    return 'https://sites.google.com/chazki.com/centrodegestion';
  }, [isChazkiOperator]);

  return (
    <Layout
      style={{
        backgroundColor: 'transparent',
      }}
    >
      <Header
        style={{
          backgroundColor: 'transparent',
          textAlign: 'center',
          padding: 0,
          height: 'auto',
        }}
      >
        <Title level={2}>Necesitas ayuda?</Title>
        {isChazkiOperator && (
          <Title level={4}>
            Recuerda tener iniciada la sesion en tu email corporativo
            @chazki.com
          </Title>
        )}
      </Header>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          textAlign: 'center',
        }}
      >
        <Button type="primary">
          <a href={supportLink} target="_blank" rel="noopener noreferrer">
            Contactar a Soporte
          </a>
        </Button>
      </Content>
    </Layout>
  );
};

export default Support;
