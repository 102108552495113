import { createSelector } from 'reselect';
import { initialState, name } from './slice';

export const selectPackagesMassive = (state) => state[name] || initialState;
export const itemsSelector = (state) => state[name].items;
export const searchSelector = (state) => state[name].search;
export const dateSelector = (state) => state[name].date;
export const pageableSelector = (state) => state[name].pageable;
export const loadedSelector = (state) => state[name].loaded;

const makeSelectMassives = () =>
  createSelector(selectPackagesMassive, (substate) => substate);

export default makeSelectMassives;
