import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from '../logout';

export const initialState = {
  loaded: false,
  packages: null,
  roles: null,
  countries: null,
  deliveryTerm: null,
  notifierType: null,
  userType: null,
  cities: null,
  daysService: null,
  currentServices: null,
  dispatchTypes: null,
  vehicleTypes: null,
};

const globalsSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      state.packages = action.payload.packages;
      state.countries = action.payload.countries;
      state.roles = action.payload.roles;
      state.deliveryTerm = action.payload.deliveryTerm;
      state.notifierType = action.payload.notifierType;
      state.userType = action.payload.userType;
      state.cities = action.payload.cities;
      state.daysService = action.payload.daysService;
      state.currentServices = action.payload.currentServices;
      state.dispatchTypes = action.payload.dispatchTypes;
      state.vehicleTypes = action.payload.vehicleTypes;
    },
    failure: (state) => {
      state.loaded = true;
    },
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = globalsSlice;
