import React, { useEffect, useState, useCallback } from 'react';
import rest from 'util/Api';
import JsDownload from 'js-file-download';
import { useSelector, useDispatch } from 'react-redux';
import usePackagesWithPaquers from 'redux/packagesWithPaquers';
import usePaquers from 'redux/paquers';
import usePaquerypoints from 'redux/paquerypoint';
import { actions } from 'redux/packagesWithPaquers/slice';
import { actions as paquerActions } from 'redux/paquers/slice';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import { selectGlobals } from 'redux/globals/selectors';
import { paquerypointsItemsSelector } from 'redux/paquerypoint/selectors';
import {
  packagesWithPaquersSearchSelector,
  packagesWithPaquersStatusSelector,
  packagesWithPaquersPaquerSelector,
  packagesWithPaquersLoadedSelector,
  packagesTypeSelector,
  packagesMarketFilterSelector,
  packagesWithPaquersDateSelector,
  packagesWithPaquersItemsSelector,
  packagesWithPaquersPageableSelector,
} from 'redux/packagesWithPaquers/selectors';
import { notification } from 'antd';
import dayjs from 'dayjs';
import ViewPackageButton from 'components/viewPackagebutton';
import PaqueryTable from '@paquery-team/lib-table';
import { ALL_STATUS_FILTERING } from 'constants/defaultValues';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import {
  useMarketplaceOptions,
  useWithPaquersStatusOptions,
} from 'hooks/useOptions';
import externalCodeColumn from 'components/tables/columns';
import PaquerSettingsButton from 'components/paquerSettingsButton';
import useMarketplaces from 'redux/marketplaces';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';

const destinationPersonNameColumn = {
  title: 'Nombre destinatario',
  dataIndex: 'destinationPersonName',
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTermDescription',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
};

const paquerypointColumn = {
  title: 'PaqueryPoint',
  dataIndex: 'paquerypoint',
  align: 'center',
};

const paquerColumn = {
  title: 'Paquer',
  dataIndex: 'paquer',
  align: 'center',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSizeDescription',
  align: 'center',
  render: (text) => <SizeIndicator text={text} />,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const paquerSettingsColumn = {
  align: 'center',
  render: (_, record) => (
    <PaquerSettingsButton
      packageId={record}
      actions={actions}
      isWithPaquerListing
    />
  ),
};

const dataColumns = [
  externalCodeColumn,
  destinationPersonNameColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  sizeColumn,
  arrivedAtPaqueryPointDateColumn,
  paquerypointColumn,
  statusColumn,
  paquerColumn,
];

const columnsLargeDevice = [
  ...dataColumns,
  paquerSettingsColumn,
  viewPackageColumn,
  editColumn,
];

const columnsSmallDevice = [
  externalCodeColumn,
  paquerSettingsColumn,
  viewPackageColumn,
  editColumn,
];

const columnsMediumDevice = [
  externalCodeColumn,
  statusColumn,
  paquerSettingsColumn,
  viewPackageColumn,
  editColumn,
];

const PackageWithPaquers = () => {
  usePackagesWithPaquers();
  usePaquers();
  usePaquerypoints({ initialize: true });
  useMarketplaces({ initialize: true });
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const pageable = useSelector(packagesWithPaquersPageableSelector);
  const items = useSelector(packagesWithPaquersItemsSelector);
  const paquers = useSelector(paquersItemsSelector);
  const paquerypoints = useSelector(paquerypointsItemsSelector);
  const search = useSelector(packagesWithPaquersSearchSelector);
  const status = useSelector(packagesWithPaquersStatusSelector);
  const packageType = useSelector(packagesTypeSelector);
  const date = useSelector(packagesWithPaquersDateSelector);
  const marketFilter = useSelector(packagesMarketFilterSelector);
  const loaded = useSelector(packagesWithPaquersLoadedSelector);
  const paquerId = useSelector(packagesWithPaquersPaquerSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const [disabled, setDisabled] = useState(false);
  const [paquerList, setPaquerList] = useState([]);
  const filteredStatus = useWithPaquersStatusOptions();

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  useEffect(() => {
    if (paquers.length > 0) {
      setPaquerList(
        paquers.map((paquer) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [paquers]);

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const searchParams = new URLSearchParams({
        from: date.from,
        to: date.to,
        search,
        paquerId,
        status: status === ALL_STATUS_FILTERING ? '' : status,
        packageType: packageType || '',
        marketplaceId: marketFilter || '',
      });
      // eslint-disable-next-line import/no-named-as-default-member
      const response = await rest.get(
        `${API.reports.packagesbyPaquer}?${searchParams.toString()}`,
        {
          responseType: 'blob',
        },
      );
      const paquerFilteredName = paquerId
        ? `${
            paquerList.find((paquerOnList) => paquerOnList.value === paquerId)
              .name
          }`
        : 'Paquer';
      const filename = `Paquetes-Por-${paquerFilteredName}-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(response.data, filename);
    } catch (error) {
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searchPaquerCallback = useCallback(
    (newPaquerId) => {
      dispatch(actions.updatePaquerId(newPaquerId));
    },
    [dispatch],
  );
  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarket(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );

  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: filteredStatus,
    },
    {
      onChange: searchPaquerCallback,
      placeholder: 'Paquer',
      list: paquerList,
    },
  ];

  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  const searcher = {
    placeholder: 'Código externo',
    onSearching: searchCallback,
    allowEmptySearch: true,
  };

  let filteredPackages = [];
  if (loaded) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationPersonName: packet.shippingScheduleDestination.name,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTermDescription: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      packageSizeDescription: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      paquerypoint:
        packet.shippingScheduleDestination.paqueryPointId &&
        paquerypoints.length > 0
          ? paquerypoints.find(
              (ppInList) =>
                ppInList.id ===
                packet.shippingScheduleDestination.paqueryPointId,
            )?.name
          : null,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      paquer: packet.shippingScheduleDestination?.driver
        ? `${packet.shippingScheduleDestination.driver.name} ${packet.shippingScheduleDestination.driver.lastName} (${packet.shippingScheduleDestination.driver.id})`
        : '',
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        title: 'Paquetes por paquer',
        searcher,
        selectors,
        rangePicker,
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: handleExportCsv, disabled },
      }}
      usePackageRowColors
      onChangePaginate={updatePaginate}
      dataSource={filteredPackages}
      paginate={pageable}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default PackageWithPaquers;
