import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { notification } from 'antd';
import rest from 'util/Api';
import * as Yup from 'yup';
import { PHONE_REGEX } from 'constants/defaultValues';
import { OTHER_NOTIFIER_TYPE } from 'constants/options';
import API from 'constants/api';
import { actions } from 'redux/store/slice';
import { useDispatch } from 'react-redux';
import Store from '../store';

const defaultValues = {
  detail: '',
  address: '',
  contactName: '',
  email: '',
  geoKey: '',
  name: '',
  phone: '',
  id: '',
  username: '',
  hasIntegration: false,
  notifierType: null,
  active: true,
  password: '',
  confirmpassword: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Especifique el nombre de la tienda'),
  phone: Yup.string()
    .matches(PHONE_REGEX, 'Formato de telefono invalido.')
    .typeError('Especifique el teléfono de la tienda')
    .required('Especifique el teléfono de la tienda'),
  username: Yup.string().when('hasIntegration', {
    is: true,
    then: Yup.string().required(
      'Indique el nombre de usuario de la integración',
    ),
    otherwise: Yup.string().nullable(),
  }),
  notifierType: Yup.number().when('hasIntegration', {
    is: true,
    then: Yup.number()
      .typeError('Indique el tipo de integración')
      .required('Indique el tipo de integración'),
    otherwise: Yup.number().nullable(),
  }),
  password: Yup.string().when('hasIntegration', {
    is: true,
    then: Yup.string().required('Especifique la contraseña de la integración'),
    otherwise: Yup.string().nullable(),
  }),
  confirmpassword: Yup.string().when('hasIntegration', {
    is: true,
    then: Yup.string().test(
      'password-match',
      'Las contraseñas deben coincidir',
      function callback(value) {
        return this.parent.password === value;
      },
    ),
    otherwise: Yup.string().nullable(),
  }),
});

const MarketplaceAddStore = ({ marketplaceData }) => {
  const { marketplaceId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (values) => {
    dispatch(actions.loading());
    let credentials;
    if (values.hasIntegration) {
      const { username, password, notifierType } = values;
      credentials = {
        username,
        password,
        notifier:
          notifierType !== OTHER_NOTIFIER_TYPE.value ? notifierType : null,
      };
    }
    const payload = {
      name: values.name,
      detail: values.detail,
      address: values.address
        ? values.address
        : marketplaceData.dispatchAddress,
      phone: values.phone,
      contactName: values.contactName,
      email: values.email,
      active: values.active,
      geoKey: values.geokey,
      ownerId: marketplaceId,
      ownerType: marketplaceData.ownerType,
      storeCode: values.id,
      credentials,
    };
    try {
      const response = await rest.post(API.stores.create, payload);
      if (rest.isSuccessResponse(response)) {
        dispatch(actions.refreshPage(marketplaceId));
        notification.success({
          message: 'Creación de Tienda',
          description: `La Tienda ${values.name} ha sido creada correctamente.`,
        });
        if (response?.data?.data?.credentials) {
          notification.success({
            message: 'Credenciales Generadas',
            description:
              'Se han generado correctamente las credenciales de integración',
          });
        }
        setTimeout(() => {
          history.push(`/marketplaces/${marketplaceId}`);
        }, 1000);
      }
    } catch (error) {
      dispatch(actions.loaded());
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al crear la tienda${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };
  return (
    <Store
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    />
  );
};

MarketplaceAddStore.propTypes = {
  marketplaceData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ownerType: PropTypes.number.isRequired,
    dispatchAddress: PropTypes.string,
  }).isRequired,
};

export default MarketplaceAddStore;
