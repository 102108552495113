import { Card, Col, Divider, Pagination, Row } from 'antd';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Modal from 'components/modal';

const useStylesPackageModal = createUseStyles({
  card: {
    '& .ant-card:hover': {
      backgroundColor: '#0db8b21a',
      cursor: 'pointer',
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    color: '#80808085',
  },
});

const paginatePackages = (packages, { pageNumber, pageSize }) => {
  const start = (pageNumber - 1) * pageSize;
  const end = (pageNumber - 1) * pageSize + pageSize;
  return packages.slice(start, end);
};

const PackageSelectModal = ({
  visible,
  packages,
  handleCloseModal,
  handlePackageSelection,
  disabled,
}) => {
  const classes = useStylesPackageModal();
  const [paginate, setPaginate] = useState(DEFAULT_PAGINATE);
  const filteredPackages = paginatePackages(packages, paginate);

  useEffect(() => {
    setPaginate((pagination) => ({
      ...pagination,
      totalElements: packages.length,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  const handleChangePage = (currentPage, pageSize) => {
    setPaginate({
      ...paginate,
      pageNumber: currentPage,
      pageSize,
    });
  };

  const handleChangePageSize = (_, pageSize) => {
    setPaginate({
      ...paginate,
      pageSize,
    });
  };

  return (
    <Modal
      className={classes.modal}
      title="Elija el paquete que desea agregar:"
      header
      bodyStyle={{ height: 400, overflowY: 'auto' }}
      open={visible}
      footer={[
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          current={paginate.pageNumber}
          total={paginate.totalElements}
          pageSize={paginate.pageSize}
          onChange={handleChangePage}
          onShowSizeChange={handleChangePageSize}
        />,
      ]}
      onCancel={() => {
        setPaginate(DEFAULT_PAGINATE);
        handleCloseModal();
      }}
      width="55%"
    >
      {filteredPackages.map((packet) => {
        return (
          <div className={classes.card} key={packet.id}>
            <Row
              onClick={() => {
                setPaginate(DEFAULT_PAGINATE);
                handlePackageSelection(packet);
              }}
            >
              <Col xs={24}>
                <Card
                  bordered={false}
                  size="small"
                  title={`Código Externo: ${packet.externalCode}`}
                >
                  {!disabled.marketplace && (
                    <div>Marketplace: {packet.marketplace}</div>
                  )}
                  {!disabled.destinationAddress && (
                    <div>Destino: {packet.destinationAddress}</div>
                  )}
                  {!disabled.statusDescription && (
                    <div>Estado: {packet.statusDescription}</div>
                  )}
                </Card>
              </Col>
            </Row>
            <Divider style={{ margin: '18px 0px' }} />
          </div>
        );
      })}
    </Modal>
  );
};

PackageSelectModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handlePackageSelection: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: {
    marketplace: PropTypes.bool,
    destinationAddress: PropTypes.bool,
    statusDescription: PropTypes.bool,
  },
};

PackageSelectModal.defaultProps = {
  disabled: {
    marketplace: false,
    destinationAddress: false,
    statusDescription: false,
  },
};

export default PackageSelectModal;
