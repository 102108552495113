import React, { useEffect, useCallback, useState } from 'react';
import store from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import rest from 'util/Api';
import dayjs from 'dayjs';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import API from 'constants/api';
import { ExportDateFormat } from 'constants/dateFormats';
import CsvDownloader from 'util/CsvDownloader';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import DeleteModal from 'components/deleteModal';
import usePaquerypoints from 'redux/paquerypoint';
import { actions } from 'redux/paquerypoint/slice';
import {
  paquerypointsLoadedSelector,
  paquerypointsItemsSelector,
  paquerypointsFilteredItemsSelector,
} from 'redux/paquerypoint/selectors';
import EditRecordButton from 'components/editRecordButton';
import DeleteRecordButton from 'components/deleteRecordButton';

const deleteAction = (paqueryPointId, paqueryPointName) => {
  const deleteRequest = async () => {
    let response;
    try {
      response = await rest.del(`${API.paqueryPoint.create}/${paqueryPointId}`);
      if (rest.isSuccessResponse(response)) {
        store.dispatch({
          type: actions.removePacket.type,
          payload: paqueryPointId,
        });
      }
    } catch (error) {
      // Nothing to do
    }
    return response;
  };
  DeleteModal({
    title: `¿Estas seguro de que deseas eliminar el paquerypoint ${paqueryPointName}?`,
    onConfirmation: deleteRequest,
  });
};

const editPaquerypointColumn = {
  title: 'Editar',
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link="/paquerypoints" />
  ),
};

const deletePaquerypointColumn = {
  title: 'Eliminar',
  align: 'center',
  render: (_, record) => (
    <DeleteRecordButton onClick={() => deleteAction(record.key, record.name)} />
  ),
};

const nameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
};

const detailColumn = {
  title: 'Descripción',
  dataIndex: 'detail',
};

const phoneColumn = {
  title: 'Teléfono',
  dataIndex: 'phone',
};

const contactNameColumn = {
  title: 'Contacto',
  dataIndex: 'contactName',
};

const activeColumn = {
  title: 'Estado',
  dataIndex: 'active',
};

const dataColumns = [
  nameColumn,
  detailColumn,
  phoneColumn,
  contactNameColumn,
  activeColumn,
];

const columnsLargeDevice = [
  ...dataColumns,
  editPaquerypointColumn,
  deletePaquerypointColumn,
];

const columnsMediumDevice = [...dataColumns];

const columnsSmallDevice = [nameColumn, contactNameColumn];

const PaqueryPointList = () => {
  usePaquerypoints();
  const dispatch = useDispatch();
  const loaded = useSelector(paquerypointsLoadedSelector);
  const fullItems = useSelector(paquerypointsItemsSelector);
  const { items, pageable } = useSelector(paquerypointsFilteredItemsSelector);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    dispatch(actions.initial());
  }, [dispatch]);
  const getCsv = async () => {
    setDisabled(true);
    try {
      const csv = fullItems.map((paqueryPoint) => ({
        ...paqueryPoint,
        active: paqueryPoint.active ? 'Si' : 'No',
        published: paqueryPoint.published ? 'Si' : 'No',
        deleted: paqueryPoint.deleted ? 'Si' : 'No',
        private: paqueryPoint.private ? 'Si' : 'No',
        openBranchOffice: paqueryPoint.openBranchOffice ? 'Si' : 'No',
      }));
      const csvHeaders = [
        { label: 'ID', value: 'id' },
        { label: 'Nombre', value: 'name' },
        { label: 'Descripción', value: 'detail' },
        { label: 'Dirección', value: 'address' },
        { label: 'Contacto', value: 'contactName' },
        { label: 'Activo', value: 'active' },
        { label: 'Publicado', value: 'published' },
        { label: 'Privado', value: 'private' },
        { label: 'Eliminado', value: 'deleted' },
        { label: 'Volumen', value: 'volume' },
        { label: 'Sucursal Abierta', value: 'openBranchOffice' },
        { label: 'Id de Opl dueño', value: 'ownerLogisticOperatorId' },
      ];
      CsvDownloader(
        csv,
        csvHeaders,
        `paquerypoints-${dayjs().tz().format(ExportDateFormat)}.csv`,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };
  let filteredPaquerypoints;

  if (loaded && items) {
    filteredPaquerypoints = items.map((paqueryPoint) => ({
      ...paqueryPoint,
      key: paqueryPoint.id,
      name: paqueryPoint.name,
      detail: paqueryPoint.detail,
      phone: paqueryPoint.phone,
      contactName: paqueryPoint.contactName,
      active: paqueryPoint.active ? 'Activo' : 'Inactivo',
    }));
  }

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );
  const searcher = {
    key: 'searchCodeOrDestinationName',
    onSearching: searchCallback,
    placeholder: 'Nombre',
    allowEmptySearch: true,
  };

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        title: 'PaQuery Points',
        searcher,
        onExportCsv: { callback: getCsv, disabled },
        refresh: () => dispatch(actions.refreshPage()),
        primaryButton: <TableHeader.AddButton url="/paquerypoints/add" />,
      }}
      onChangePaginate={updatePaginate}
      dataSource={filteredPaquerypoints}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default PaqueryPointList;
