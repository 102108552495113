const DateFormatter = (isoDate: string): string => {
  // Parsear la fecha ISO 8601
  const date = new Date(isoDate);

  // Formatear la fecha a DD/MM/YYYY HH:MM hs
  const formattedDate = date
    .toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .replace(',', ' hs');

  return formattedDate;
};

export default DateFormatter;
