import { InputProps, Input } from 'antd';
import { useEffect, useState } from 'react';

interface DebouncedInputProps extends InputProps {
  value?: string;
  setValue: (newValue?: string) => void;
}

const DebouncedInput = ({ value, setValue, ...props }: DebouncedInputProps) => {
  const [tempValue, setTempValue] = useState<string | undefined>(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleSetValue = () => {
    setValue(tempValue);
  };

  return (
    <Input
      value={tempValue}
      onChange={(event) => setTempValue(event.target.value)}
      onBlur={handleSetValue}
      onPressEnter={handleSetValue}
      {...props}
    />
  );
};

export default DebouncedInput;
