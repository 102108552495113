import React from 'react';
import PropTypes from 'prop-types';
import { Radio, FormItem } from 'formik-antd';

const RadioField = ({
  fieldName,
  options,
  onChange,
  optionType,
  buttonStyle,
  size,
  radioStyle,
}) => (
  <>
    <FormItem name={fieldName} style={{ textAlign: 'center' }}>
      <Radio.Group
        name={fieldName}
        options={options}
        onChange={onChange}
        optionType={optionType}
        buttonStyle={buttonStyle}
        size={size}
        style={radioStyle}
      />
    </FormItem>
  </>
);

RadioField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func,
  optionType: PropTypes.string,
  buttonStyle: PropTypes.string,
  size: PropTypes.string,
  radioStyle: PropTypes.shape({}),
};

RadioField.defaultProps = {
  onChange: null,
  optionType: null,
  buttonStyle: null,
  size: null,
  radioStyle: null,
};

export default RadioField;
