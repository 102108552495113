import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { useEffect, useState } from 'react';

const { TextArea } = Input;

interface DebouncedTextAreaProps extends TextAreaProps {
  value?: string;
  setValue: (newValue?: string) => void;
}

const DebouncedTextArea = ({
  value,
  setValue,
  ...props
}: DebouncedTextAreaProps) => {
  const [tempValue, setTempValue] = useState<string | undefined>(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleSetValue = () => {
    setValue(tempValue);
  };

  return (
    <TextArea
      value={tempValue}
      onChange={(event) => setTempValue(event.target.value)}
      onBlur={handleSetValue}
      onPressEnter={handleSetValue}
      {...props}
    />
  );
};

export default DebouncedTextArea;
