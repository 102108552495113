/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { DEPARTURE_TYPE_OPTIONS } from 'constants/options';
import { PACKAGE, isAllowedForDepartureStatus } from 'constants/packages';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat } from 'constants/dateFormats';
import store from 'app/store';
import PaqueryTable from '@paquery-team/lib-table';
import dayjs from 'dayjs';
import externalCodeColumn from 'components/tables/columns';
import { createUseStyles } from 'react-jss';
import {
  Button,
  Col,
  notification,
  Row,
  Select,
  Modal,
  Input,
  theme,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/packageInlandDeparture/slice';
import { actions as ppointDepartureActions } from 'redux/packagePaquerypointDeparture/slice';
import { actions as refundDepartureActions } from 'redux/packageRefundDeparture/slice';
import { actions as ppointActions } from 'redux/paquerypoint/slice';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import useMarketplaces from 'redux/marketplaces';
import {
  DownCircleOutlined,
  ExclamationCircleOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { fetchPackage } from 'constants/operations';
import { useDebounce } from 'util/Hooks';
import rest from 'util/Api';
import usePackageInlandDeparture from 'redux/packageInlandDeparture';
import usePackagePpointDeparture from 'redux/packagePaquerypointDeparture';
import usePackageRefundDeparture from 'redux/packageRefundDeparture';
import usePaquerypoints from 'redux/paquerypoint';
import {
  packageInlandFilteredItemsSelector,
  packageInlandDepartureItemsSelector,
  packageInlandDepartureOperatorsSelector,
  packageInlandDepartureDestinySelector,
  packageInlandDepartureOriginSelector,
  packageInlandDepartureTypeSelector,
  packageInlandDepartureLoadedSelector,
} from 'redux/packageInlandDeparture/selectors';
import {
  packagePaquerypointFilteredItemsSelector,
  packagePaquerypointDepartureItemsSelector,
  packagePaquerypointDepartureAllPaqueryPointsItemsSelector,
  packagePaquerypointDepartureAllPaqueryPointsLoadedSelector,
} from 'redux/packagePaquerypointDeparture/selectors';
import {
  packageRefundFilteredItemsSelector,
  packageRefundDepartureItemsSelector,
} from 'redux/packageRefundDeparture/selectors';
import {
  paquerypointsItemsSelector,
  paquerypointsLoadedSelector,
} from 'redux/paquerypoint/selectors';
import PackageSelectModal from 'components/packageSelectModal';
import {
  usePackageStatus,
  usePackageTypes,
  useDeliveryTerms,
  usePackageSizes,
} from '@paquery-team/lib-paquery-language/globals';
import QRScanner from 'components/QRScanner';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';
import DeleteRecordButton from 'components/deleteRecordButton';
import Card from 'components/card';

const { Search } = Input;

const WAIT_INPUT_EXTERNAL_CODE = 600;

const useStylesDeparturePacket = createUseStyles((_theme) => {
  return {
    pageTitle: {
      letterSpacing: '-0.03cm',
      alignSelf: 'flex-start',
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '2em',
      boxShadow: '0px 0px 2px #c3c3c3',
      margin: '15px 15px 0 15px',
    },
    headerButtonContainer: {
      textAlign: 'center',
      alignItems: 'flex-end',
    },
    registerPacketInput: {
      boxShadow: `0px 0px 3px ${_theme.colorPrimary}`,
      border: `1px solid ${_theme.colorPrimary}`,
      outline: '1px solid transparent',
      transition: '100ms all linear',
      '&:focus': {
        boxShadow: `0px 0px 3px ${_theme.colorPrimary}`,
        border: `1px solid ${_theme.colorPrimary}`,
        outline: `1px solid ${_theme.colorPrimary}`,
      },
    },
    inputContainer: {
      '@media (max-width: 768px)': {
        marginTop: '20px',
      },
    },
    buttonContainer: {
      '@media (min-width: 769px)': {
        padding: '0px 16px',
      },
    },
    destinyInput: {
      '@media (min-width: 769px)': {
        marginLeft: '30px',
      },
    },
    registerInput: {
      '@media (min-width: 1025px)': {
        marginRight: '30px',
      },
    },
    buttonText: {
      '@media (max-width: 1024px)': {
        fontSize: '13px',
      },
    },
  };
});

const { confirm } = Modal;

externalCodeColumn.width = 200;

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
  width: 130,
};

const packageTypeColumn = {
  title: 'Tipo',
  dataIndex: 'packageType',
};

const zoneColumn = {
  title: 'Zona',
  dataIndex: 'zone',
  align: 'center',
};

const scheduledDateColumn = {
  title: 'Fecha Programada',
  dataIndex: 'scheduledDate',
  align: 'center',
};

const bundlesColumn = {
  title: 'Bultos',
  dataIndex: 'numberPackages',
  align: 'center',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deleteAction = (packetID, packetCode) => {
  confirm({
    title: `¿Estás seguro de que deseas eliminar el paquete con código externo: ${packetCode} ?`,
    icon: <ExclamationCircleOutlined />,
    content: 'Esta accion no se puede deshacer',
    onOk() {
      store.dispatch(actions.removePacket(packetID));
    },
  });
};

const marketplaceColumn = {
  title: 'Marketplace',
  dataIndex: 'marketplace',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSize',
  align: 'center',
  render: (text) => <SizeIndicator text={text} />,
  sorter: true,
  sortDirections: ['descend'],
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const removePackageColumn = {
  align: 'center',
  render: (_, record) => (
    <DeleteRecordButton
      onClick={() => deleteAction(record.id, record.externalCode)}
    />
  ),
};

const dataColumns = [
  externalCodeColumn,
  deliveryTermColumn,
  scheduledDateColumn,
  zoneColumn,
  packageTypeColumn,
  marketplaceColumn,
  bundlesColumn,
  sizeColumn,
];

const columnsLargeDevice = [...dataColumns, editColumn, removePackageColumn];

const columnsSmallDevice = [
  externalCodeColumn,
  editColumn,
  removePackageColumn,
];

const columnsMediumDevice = [
  externalCodeColumn,
  scheduledDateColumn,
  arrivedAtPaqueryPointDateColumn,
  zoneColumn,
  marketplaceColumn,
  editColumn,
  removePackageColumn,
];

const PackageDeparture = () => {
  usePackageInlandDeparture();
  usePackagePpointDeparture();
  usePackageRefundDeparture();
  usePaquerypoints();
  useMarketplaces();
  const searcherInput = useRef(null);
  const externalCodeInput = useRef(null);
  const { token } = theme.useToken();
  const classes = useStylesDeparturePacket({ theme: token });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [destinyOptions, setDestinyOptions] = useState([]);
  const [packageModal, setPackageModal] = useState(false);
  const [packageSearch, setPackageSearch] = useState('');
  const [suggestedPackages, setSuggestedPackages] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const loaded = useSelector(packageInlandDepartureLoadedSelector);
  const departureType = useSelector(packageInlandDepartureTypeSelector);
  const destiny = useSelector(packageInlandDepartureDestinySelector);
  const departureOrigin = useSelector(packageInlandDepartureOriginSelector);
  const inlandDepartureOptions = useSelector(
    packageInlandDepartureOperatorsSelector,
  );
  const inlandDepartureOptionsLoaded = useSelector(
    packageInlandDepartureLoadedSelector,
  );
  const paquerypoints = useSelector(
    packagePaquerypointDepartureAllPaqueryPointsItemsSelector,
  );
  const paquerypointsLoaded = useSelector(
    packagePaquerypointDepartureAllPaqueryPointsLoadedSelector,
  );
  const originpaquerypoints = useSelector(paquerypointsItemsSelector);
  const originpaquerypointsLoaded = useSelector(paquerypointsLoadedSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const totalInlandPackages = useSelector(packageInlandDepartureItemsSelector);
  const totalPpointPackages = useSelector(
    packagePaquerypointDepartureItemsSelector,
  );
  const totalRefundPackages = useSelector(packageRefundDepartureItemsSelector);
  const totalPackages = [
    ...totalInlandPackages,
    ...totalPpointPackages,
    ...totalRefundPackages,
  ];
  const { items: inlandItems, pageable: inlandPageable } = useSelector(
    packageInlandFilteredItemsSelector,
  );
  const { items: ppointItems, pageable: ppointPageable } = useSelector(
    packagePaquerypointFilteredItemsSelector,
  );
  const { items: refundItems, pageable: refundPageable } = useSelector(
    packageRefundFilteredItemsSelector,
  );
  const debouncedPackageSearch = useDebounce(
    packageSearch,
    WAIT_INPUT_EXTERNAL_CODE,
  );
  const packageStatuses = usePackageStatus();
  const packageTypes = usePackageTypes();
  const deliveryTerms = useDeliveryTerms();
  const packageSizes = usePackageSizes();
  const [loading, setLoading] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const originOptions = originpaquerypoints.map((origin) => ({
    label: origin.name,
    value: origin.id,
  }));

  const getCurrentDepartureTypeOptions = () => {
    let destinationOptions;
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        destinationOptions = inlandDepartureOptions.map((destination) => ({
          label: destination.name,
          value: destination.id,
        }));
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        destinationOptions = paquerypoints.map((destination) => ({
          label: destination.name,
          value: destination.id,
        }));
        if (departureOrigin) {
          destinationOptions = destinationOptions.filter(
            (item) => item.value !== departureOrigin,
          );
        }
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        destinationOptions = marketplaces.map((destination) => ({
          label: destination.name,
          value: destination.id,
        }));
        break;
      default:
        break;
    }
    setDestinyOptions(destinationOptions);
  };

  const getPackage = async (externalCode) => {
    const response = await fetchPackage(externalCode);
    if (response instanceof Error) {
      setSuggestionsLoading(false);
      return notification.error({
        message: 'Ha ocurrido un error al encontrar el paquete',
        description: `No se ha encontrado el paquete con el código ${externalCode}`,
      });
    }
    return response;
  };

  const checkPackageAvaiability = (packet) => {
    const packetInListing = totalPackages.find((item) => item.id === packet.id);
    if (packetInListing) {
      throw new Error(
        `El paquete ${packetInListing.externalCode} se encuentra asignado a: ${packetInListing.destinyName}`,
      );
    }
    const statusName = packageStatuses.find(
      (status) => status.value === packet.status,
    )?.name;
    const errorMessage = `El paquete se encuentra en estado: ${statusName}.`;
    if (packet.status === PACKAGE.STATUSES.INHANDSOFPAQUER) {
      throw new Error(errorMessage);
    }
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        if (!isAllowedForDepartureStatus(packet.status)) {
          throw new Error(errorMessage);
        }
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        if (packet.ownerID !== destiny) {
          throw new Error(
            'No se puede devolver el paquete porque no pertenece al destino seleccionado.',
          );
        }
        break;
      default:
        break;
    }
  };

  const assignPacket = ({ id }) => {
    try {
      const selectedPacket = suggestedPackages.find(
        (packet) => packet.id === id,
      );
      if (selectedPacket) {
        checkPackageAvaiability(selectedPacket);
        let selectedDestinyName;
        const packetWithDestiny = {
          ...selectedPacket,
          destinyID: destiny,
          originID: departureOrigin,
        };
        packetWithDestiny.originName = originOptions.find(
          (option) => option.id === departureOrigin,
        )?.name;
        switch (departureType) {
          case DEPARTURE_TYPE_OPTIONS.INTERIOR:
            selectedDestinyName = inlandDepartureOptions.find(
              (option) => option.id === destiny,
            )?.name;
            packetWithDestiny.destinyName = selectedDestinyName;
            dispatch(actions.addPackage(packetWithDestiny));
            break;
          case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
            selectedDestinyName = paquerypoints.find(
              (ppoint) => ppoint.id === destiny,
            )?.name;
            packetWithDestiny.destinyName = selectedDestinyName;
            dispatch(ppointDepartureActions.addPackage(packetWithDestiny));
            break;
          case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
            selectedDestinyName = marketplaces.find(
              (marketplace) => marketplace.id === destiny,
            )?.name;
            packetWithDestiny.destinyName = selectedDestinyName;
            dispatch(refundDepartureActions.addPackage(packetWithDestiny));
            break;
          default:
            break;
        }
        notification.success({
          message: 'Asignación de paquete correcta',
          description: `El paquete ${selectedPacket.externalCode} fue asignado correctamente a ${selectedDestinyName}`,
        });
        setPackageModal(false);
        setPackageOptions([]);
      }
    } catch (error) {
      notification.error({
        message: 'Ha ocurrido un problema al asignar el paquete',
        description: error.message || 'Ha ocurrido un error en el servidor.',
      });
    } finally {
      setSuggestionsLoading(false);
    }
  };
  const buildListingPackage = (packet) => {
    return {
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      scheduledDate: packet.shippingScheduleDestination.scheduledDate
        ? dayjs(packet.shippingScheduleDestination.scheduledDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      zone: packet.shippingScheduleDestination.zone?.name ?? 'Sin Zona',
      deliveryTerm: deliveryTerms.find(
        (term) => term.value === packet.deliveryTerm,
      )?.name,
      marketplace: marketplaces.find(
        (marketplace) => marketplace.id === packet.ownerID,
      )?.name,
      packageSize: packageSizes.find(
        (packSize) => packSize.value === packet.packageSize,
      )?.name,
      packageType: packageTypes.find(
        (packType) => packType.value === packet.packageType,
      )?.name,
    };
  };
  const getFilteredPackages = () => {
    let filteredPackages;
    if (inlandItems && ppointItems && refundItems) {
      switch (departureType) {
        case DEPARTURE_TYPE_OPTIONS.INTERIOR:
          filteredPackages = inlandItems.map((packet) =>
            buildListingPackage(packet),
          );
          break;
        case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
          filteredPackages = ppointItems.map((packet) =>
            buildListingPackage(packet),
          );
          break;
        case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
          filteredPackages = refundItems.map((packet) =>
            buildListingPackage(packet),
          );
          break;
        default:
          filteredPackages = [];
          break;
      }
    }
    return filteredPackages;
  };
  const filteredPackages = getFilteredPackages();

  useEffect(() => {
    dispatch(actions.initial());
    dispatch(ppointDepartureActions.initial());
    dispatch(refundDepartureActions.initial());
    dispatch(ppointActions.initial());
  }, [dispatch]);

  useEffect(() => {
    getCurrentDepartureTypeOptions();
    dispatch(actions.resetFilters());
    dispatch(ppointDepartureActions.resetFilters());
    dispatch(refundDepartureActions.resetFilters());
    if (searcherInput.current) {
      searcherInput.current.state.value = '';
    }
  }, [departureType, departureOrigin, dispatch]);

  useEffect(() => {
    const getPackageSuggestions = async (externalCode) => {
      let receivedExternalCode = externalCode;
      // Check if the typed extCode is a scanned QR
      try {
        const qrCode = JSON.parse(externalCode);
        if (qrCode.id) {
          receivedExternalCode = qrCode.id;
        }
      } catch (e) {
        // not QR and it's okay
      }
      const fetchedPackets = await getPackage(receivedExternalCode);
      if (!fetchedPackets) {
        return;
      }
      let suggestedPackageOptions = fetchedPackets;
      if (departureType === DEPARTURE_TYPE_OPTIONS.MARKETPLACE) {
        suggestedPackageOptions = suggestedPackageOptions.filter(
          (packet) => packet.ownerID === destiny,
        );
      }
      if (suggestedPackageOptions.length === 0) {
        notification.info({
          title: 'Información',
          message: `No se ha encontrado el paquete con código externo: ${externalCode}`,
        });
        setSuggestionsLoading(false);
        setPackageOptions([]);
        return;
      }
      setSuggestedPackages(suggestedPackageOptions);
      if (suggestedPackageOptions.length > 1) {
        // If results are more than 1 packet, show modal and filter results by packages status allowed by ppoint.
        let filteredModalPackages = suggestedPackageOptions;
        filteredModalPackages = filteredModalPackages.filter(
          (packet) => packet.status !== PACKAGE.STATUSES.INHANDSOFPAQUER,
        );
        if (departureType === DEPARTURE_TYPE_OPTIONS.INTERIOR) {
          filteredModalPackages = filteredModalPackages
            .filter(
              (packet) =>
                !totalInlandPackages.some((item) => item.id === packet.id),
            )
            .filter(
              (packet) => packet.status !== PACKAGE.STATUSES.LOGGEDINTOPAQUERY,
            );
        }
        if (departureType === DEPARTURE_TYPE_OPTIONS.MARKETPLACE) {
          filteredModalPackages = filteredModalPackages.filter(
            (packet) =>
              !totalRefundPackages.some((item) => item.id === packet.id),
          );
        }
        if (departureType === DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT) {
          filteredModalPackages = filteredModalPackages
            .filter((packet) => isAllowedForDepartureStatus(packet.status))
            .filter(
              (packet) =>
                !totalPpointPackages.some((item) => item.id === packet.id),
            );
        }
        if (!filteredModalPackages.length) {
          notification.info({
            message: 'No se ha podido registrar el paquete',
            description:
              'Asegurese de que el paquete se encuentra en un estado permitido para despachar',
          });
          return;
        }
        suggestedPackageOptions = filteredModalPackages.map((packet) => {
          return {
            ...packet,
            marketplace: marketplaces.find((mkp) => mkp.id === packet.ownerID)
              ?.name,
            destinationAddress:
              packet.shippingScheduleDestination.shippingAddress.addressDetail,
            statusDescription: packageStatuses.find(
              (state) => state.value === packet.status,
            )?.name,
          };
        });
        if (suggestedPackageOptions.length > 1) {
          setPackageModal(true);
          setSuggestionsLoading(false);
          setPackageOptions(suggestedPackageOptions);
        } else {
          setSuggestedPackages(suggestedPackageOptions);
        }
      }
    };
    if (debouncedPackageSearch) {
      setPackageSearch('');
      setPackageOptions([]);
      setSuggestedPackages([]);
      setSuggestionsLoading(true);
      getPackageSuggestions(debouncedPackageSearch);
    }
    return undefined;
  }, [debouncedPackageSearch]);

  useEffect(() => {
    if (suggestedPackages.length === 1) {
      assignPacket(...suggestedPackages);
    }
  }, [suggestedPackages]);

  const handleExportRemit = () => {
    setDisabled(true);
    // TODO: hacer logica para exportar remito
    setDisabled(false);
  };

  const handleCloseModal = () => {
    setPackageModal(false);
    setPackageOptions([]);
  };

  const departurePackages = () => {
    const departurePackagesAction = async (currentRedux, payload) => {
      setLoading(true);
      try {
        const packageIds = payload.dispatchDetails.map(
          (items) => items.packageId,
        );
        const result = await rest.post(API.departures.create, payload);
        if (rest.isSuccessResponse(result)) {
          notification.success({
            message: 'Paquetes despachados correctamente',
            description: `Los paquetes han sido despachados correctamente.`,
          });
          dispatch(currentRedux.dispatchPackagesSuccess(packageIds));
        }
      } catch (error) {
        dispatch(currentRedux.failure());
      }
      setLoading(false);
    };
    let packages;
    let departureDestiny;
    let currentRedux;
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        packages = totalInlandPackages;
        departureDestiny = inlandDepartureOptions;
        currentRedux = actions;
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        packages = totalPpointPackages;
        departureDestiny = paquerypoints;
        currentRedux = ppointDepartureActions;
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        packages = totalRefundPackages;
        departureDestiny = marketplaces;
        currentRedux = refundDepartureActions;
        break;
      default:
        break;
    }
    dispatch(currentRedux.dispatchPackages());
    const destination = departureDestiny.find((item) => item.id === destiny);
    const origin = originpaquerypoints.find(
      (item) => item.id === departureOrigin,
    );
    const destinationPackages = packages.filter(
      (packet) => packet.destinyID === destiny,
    );
    const dispatchDetails = destinationPackages.map((packet) => {
      return {
        packageId: packet.id,
      };
    });
    const payload = {
      dispatchType: departureType,
      originDetail: origin.detail,
      originType: DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT,
      originId: origin.id,
      destinationType: departureType,
      destinationId: destination.id,
      destinationDetail: destination.detail,
      dispatchDetails,
    };
    departurePackagesAction(currentRedux, payload);
  };

  const handleDeparture = async () => {
    if (!destiny || !departureOrigin) {
      notification.info({
        message: 'No hay paquetes para generar despacho',
        description: `Asegúrese de asignar paquetes al destino antes de despachar`,
      });
      return;
    }
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        if (inlandItems.length < 1) {
          notification.info({
            message: 'No hay paquetes para generar despacho',
            description: `Asegúrese de asignar paquetes al destino antes de despachar`,
          });
          return;
        }
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        if (ppointItems.length < 1) {
          notification.info({
            message: 'No hay paquetes para generar despacho',
            description: `Asegúrese de asignar paquetes al destino antes de despachar`,
          });
          return;
        }
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        if (refundItems.length < 1) {
          notification.info({
            message: 'No hay paquetes para generar despacho',
            description: `Asegúrese de asignar paquetes al destino antes de despachar`,
          });
          return;
        }
        break;
      default:
        break;
    }
    departurePackages();
  };

  const handleScanQR = (data) => {
    if (data) {
      setShowCamera(false);
      setPackageSearch(data);
    }
  };
  // TODO: limpiar input y hacerle foco para que escaneen uno atrás del otro.

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch, departureType],
    // TODO: quitar departuretype de acá porque actualiza search cuando cambio de departuretype
  );
  const searcher = {
    key: 'searchCodeOrDestinationName',
    onSearching: searchCallback,
    placeholder: 'Nombre',
    ref: searcherInput,
  };
  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
      dispatch(ppointDepartureActions.updatePageable(page));
      dispatch(refundDepartureActions.updatePageable(page));
    },
    [dispatch],
  );

  const getPagination = () => {
    let pagination;
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        pagination = inlandPageable;
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        pagination = ppointPageable;
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        pagination = refundPageable;
        break;
      default:
        break;
    }
    return pagination;
  };

  const currentPageable = getPagination();

  return (
    <>
      <QRScanner
        onScan={handleScanQR}
        showCamera={showCamera}
        setShowCamera={setShowCamera}
      />
      <PackageSelectModal
        visible={packageModal}
        packages={packageOptions}
        handleCloseModal={handleCloseModal}
        handlePackageSelection={assignPacket}
      />
      <Card
        title="Transferir o devolver paquetes"
        style={{ marginBottom: '10px' }}
      >
        <Row
          gutter={[16, 16]}
          align="middle"
          className={classes.headerButtonContainer}
          justify="space-between"
        >
          <Col xs={24} md={14} lg={21} xl={8}>
            <Row
              justify="space-around"
              gutter={[16, 16]}
              className={classes.buttonContainer}
            >
              <Col xs={24} md={8} lg={7} xl={6} xxl={6}>
                <Button
                  loading={!inlandDepartureOptionsLoaded}
                  className={
                    departureType === DEPARTURE_TYPE_OPTIONS.INTERIOR
                      ? classes.buttonActive
                      : ''
                  }
                  type="primary"
                  onClick={() =>
                    dispatch(
                      actions.updateDepartureType(
                        DEPARTURE_TYPE_OPTIONS.INTERIOR,
                      ),
                    )
                  }
                  style={{ width: '100%' }}
                >
                  <span className={classes.buttonText}>OPL</span>
                </Button>
              </Col>
              <Col xs={24} md={8} lg={9} xl={9} xxl={9}>
                <Button
                  loading={!paquerypointsLoaded}
                  className={
                    departureType === DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT
                      ? classes.buttonActive
                      : ''
                  }
                  type="primary"
                  onClick={() =>
                    dispatch(
                      actions.updateDepartureType(
                        DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT,
                      ),
                    )
                  }
                  style={{ width: '100%' }}
                >
                  <span className={classes.buttonText}>PaQuery Point</span>
                </Button>
              </Col>
              <Col xs={24} md={8} lg={7} xl={9} xxl={9}>
                <Button
                  className={
                    departureType === DEPARTURE_TYPE_OPTIONS.MARKETPLACE
                      ? classes.buttonActive
                      : ''
                  }
                  type="primary"
                  onClick={() =>
                    dispatch(
                      actions.updateDepartureType(
                        DEPARTURE_TYPE_OPTIONS.MARKETPLACE,
                      ),
                    )
                  }
                  style={{ width: '100%' }}
                  disabled
                >
                  <span className={classes.buttonText}>Devoluciones</span>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={22} xl={16}>
            <Row
              align="bottom"
              justify="space-around"
              className={classes.inputContainer}
              gutter={[16, 16]}
            >
              <Col xs={24} lg={7}>
                <span>Origen:</span>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  value={departureOrigin}
                  placeholder="Seleccione un origen"
                  onChange={(newOrigin) => {
                    dispatch(actions.updateOrigin(newOrigin));
                    dispatch(actions.updateDestiny(null));
                    dispatch(ppointDepartureActions.resetFilters());
                    dispatch(refundDepartureActions.resetFilters());
                  }}
                  filterOption={(input, option) =>
                    option.label.toUpperCase().includes(input.toUpperCase())
                  }
                  loading={!originpaquerypointsLoaded}
                  options={originOptions}
                />
              </Col>
              <Col xs={24} lg={7}>
                <span>Destino:</span>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  value={destiny}
                  placeholder="Seleccione un destino"
                  onChange={(newDestiny) => {
                    dispatch(actions.updateDestiny(newDestiny));
                    dispatch(ppointDepartureActions.resetFilters());
                    dispatch(refundDepartureActions.resetFilters());
                  }}
                  filterOption={(input, option) =>
                    option.label.toUpperCase().includes(input.toUpperCase())
                  }
                  options={destinyOptions}
                />
              </Col>
              <Col xs={24} lg={6}>
                <div>
                  <DownCircleOutlined />
                  Registrar paquete:
                </div>
                <Search
                  ref={externalCodeInput}
                  value={packageSearch}
                  disabled={!destiny}
                  onChange={(e) => setPackageSearch(e.target.value.trim())}
                  loading={suggestionsLoading}
                />
              </Col>
              <Col xs={24} lg={4}>
                <Button
                  disabled={!destiny}
                  style={{ width: '100%' }}
                  icon={<QrcodeOutlined />}
                  type="primary"
                  onClick={() => setShowCamera(true)}
                  loading={suggestionsLoading}
                >
                  Scan QR
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row
        className={classes.pageContainer}
        align="stretch"
        style={{ marginTop: -15 }}
      >
        <Col
          xs={24}
          className={classes.tablePacketContainer}
          style={{ marginTop: 15 }}
        >
          <PaqueryTable
            loading={!loaded}
            header={{
              searcher,
              onExportCsv: { callback: handleExportRemit, disabled },
            }}
            onChangePaginate={updatePaginate}
            dataSource={filteredPackages}
            paginate={currentPageable || DEFAULT_PAGINATE}
            dataColumns={dataColumns}
            colsForSmallDevice={columnsSmallDevice}
            colsForMediumDevice={columnsMediumDevice}
            colsForLargeDevice={columnsLargeDevice}
          />
        </Col>
      </Row>
      <Row align="middle" style={{ textAlign: 'center', margin: '10px 0px' }}>
        <Col xs={24}>
          <Button
            type="primary"
            size="large"
            onClick={handleDeparture}
            loading={loading}
          >
            Transferir
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PackageDeparture;
