import React, { useState, Fragment, useEffect } from 'react';
import { FieldArray, ErrorMessage, useFormikContext } from 'formik';
import { Select, Button, Tag, theme } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import useStyles from './styles';

const { Option } = Select;

interface Props {
  loading: boolean;
  label: string;
  showSearch: boolean;
  filterOption?: () => boolean;
  fieldName: string;
  fieldId: string;
  fieldDescription: string;
  options: Array<any>;
  visualOptions?: React.Component;
}

const MultipleSelect = ({
  loading,
  label,
  showSearch,
  filterOption,
  fieldName,
  fieldId,
  fieldDescription,
  options,
  visualOptions,
}: Props) => {
  const { values }: any = useFormikContext();
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const [selected, setSelected] = useState(1);
  const [screenOptions, setScreenOptions] = useState<any>(null);
  useEffect(() => {
    if (visualOptions) {
      setScreenOptions(visualOptions);
    } else if (options && options.length > 0) {
      const optionSelector = options.map((option) => (
        <Option key={option[fieldId]} value={option[fieldId]}>
          {option[fieldDescription]}
        </Option>
      ));
      setScreenOptions(optionSelector);
    }
  }, [options, visualOptions, fieldId, fieldDescription]);

  useEffect(() => {
    if (options.length > 0) {
      const firstOptionId = options[0][fieldId];
      setSelected(firstOptionId);
    }
  }, [options, fieldId]);

  const handleAdd = (arrayHelpers: any) => {
    const isSaved = values[fieldName].some(
      (option: any) => option[fieldId] === selected,
    );
    if (!isSaved) {
      const addSelect = options.find((option) => option[fieldId] === selected);
      arrayHelpers.push(addSelect);
    }
  };

  return (
    <FieldArray
      name={fieldName}
      render={(arrayHelpers) => (
        <div style={{ textAlign: 'left' }}>
          <span>{label}</span>
          <div className={classes.topSelectEffect}>
            <Select
              loading={loading || false}
              showSearch={showSearch}
              onChange={(value) => setSelected(value)}
              value={selected}
              filterOption={filterOption}
              style={{ width: '100%' }}
            >
              {screenOptions}
            </Select>
          </div>

          <Button
            type="primary"
            size="small"
            style={{ margin: '10px 0' }}
            onClick={() => handleAdd(arrayHelpers)}
          >
            Añadir
          </Button>
          <div style={{ minHeight: 45 }}>
            <ErrorMessage name={fieldName}>
              {(msg) => (
                <span
                  style={{
                    fontSize: 14,
                    lineHeight: '1.57',
                    color: '#ff4d4f',
                  }}
                >
                  {msg}
                </span>
              )}
            </ErrorMessage>
            {values[fieldName].map((field: any, index: number) => (
              <Fragment key={field[fieldId]}>
                <Tag color="green" style={{ margin: 5 }}>
                  {field[fieldDescription]}
                </Tag>
                <CloseCircleFilled
                  style={{ color: 'red', marginRight: 8 }}
                  onClick={() => arrayHelpers.remove(index)}
                />
              </Fragment>
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default MultipleSelect;
