import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useEffect } from 'react';
import { name as key, reducer, actions } from './slice';
import saga from './saga';
import makeSelectMassiveViewPackages from './selectors';

export default function useMassiveViewPackages() {
  const dispatch = useDispatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  useSelector(makeSelectMassiveViewPackages(), shallowEqual);
  useEffect(() => {
    dispatch(actions.resetFilters());
    dispatch(actions.clearItems());
  }, [dispatch]);
}
