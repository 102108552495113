import React, { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/packagesBySearchCode/slice';
import { actions as paquerActions } from 'redux/paquers/slice';
import { selectGlobals } from 'redux/globals/selectors';
import usePackagesBySearchCode from 'redux/packagesBySearchCode';
import {
  packagesBySearchCodeItemsSelector,
  packagesBySearchCodePageableSelector,
  packagesBySearchCodeLoadedSelector,
} from 'redux/packagesBySearchCode/selectors';
import PaqueryTable from '@paquery-team/lib-table';
import { isFinalStatus, readyForDeliveryStatus } from 'constants/packages';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat } from 'constants/dateFormats';
import ViewPackageButton from 'components/viewPackagebutton';
import externalCodeColumn from 'components/tables/columns';
import PaquerSettingsButton from 'components/paquerSettingsButton';
import DownloadLabelButton from 'components/downloadLabelButton';
import usePaquers from 'redux/paquers';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';
import DuplicatePackageButton from 'components/duplicatePackageButton';

const downloadLabelColumn = {
  render: (_, record) =>
    isFinalStatus(record.status) ? null : (
      <DownloadLabelButton
        packageId={record.key}
        externalCode={record.externalCode}
      />
    ),
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const rolPosColumn = {
  title: 'Rol/Pos',
  dataIndex: 'rollContainerPosition',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
};

const packageTypeColumn = {
  title: 'Tipo',
  dataIndex: 'packageType',
};

const scheduledDateColumn = {
  title: 'Fecha Programada',
  dataIndex: 'scheduledDate',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSize',
  align: 'center',
  render: (text) => <SizeIndicator text={text} />,
  sorter: true,
  sortDirections: ['descend'],
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const editColumnDuplicateInfo = {
  align: 'center',
  render: (_, record) => <DuplicatePackageButton packet={record} />,
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const paquerSettingsColumn = {
  align: 'center',
  render: (_, record) => (
    <PaquerSettingsButton actions={actions} packageId={record} isBySearchCode />
  ),
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  scheduledDateColumn,
  rolPosColumn,
  packageTypeColumn,
  sizeColumn,
  statusColumn,
];

const actionColumns = [
  paquerSettingsColumn,
  viewPackageColumn,
  editColumn,
  editColumnDuplicateInfo,
];

const columnsLargeDevice = [
  downloadLabelColumn,
  ...dataColumns,
  ...actionColumns,
];

const columnsMediumDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  statusColumn,
  ...actionColumns,
];

const columnsSmallDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  ...actionColumns,
];

const PackageSearchByCode = () => {
  usePackagesBySearchCode();
  usePaquers();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const packages = useSelector(packagesBySearchCodeItemsSelector);
  const pagination = useSelector(packagesBySearchCodePageableSelector);
  const loaded = useSelector(packagesBySearchCodeLoadedSelector);

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  const searchCallback = useCallback(
    (searchText) => {
      const searchCode = searchText.trim();
      if (searchCode) dispatch(actions.updateSearchPackages(searchText));
    },
    [dispatch],
  );
  const paginateCallback = useCallback(
    (pageable) => {
      dispatch(actions.updatePaginationPackages(pageable));
    },
    [dispatch],
  );
  const searcher = {
    key: 'searchByExternalCode',
    required: {
      value: true,
      message: 'Indique el codigo externo',
    },
    onSearching: searchCallback,
    placeholder: 'Código externo',
  };

  let filteredPackages;
  if (packages) {
    filteredPackages = packages.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination?.shippingAddress?.addressDetail,
      scheduledDate: packet.shippingScheduleDestination?.scheduledDate
        ? dayjs(packet.shippingScheduleDestination.scheduledDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      deliveryTerm:
        packet.deliveryTerm &&
        globals.deliveryTerm.find((term) => term.value === packet.deliveryTerm)
          .description,
      rollContainerPosition: packet.rollContainerPosition,
      packageSize: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      packageType: globals.packages.type.find(
        (packType) => packType.value === packet.packageType,
      ).name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      isDeliverable: readyForDeliveryStatus.some((id) => id === packet.status),
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searcher,
        refresh: () => dispatch(actions.refreshPage()),
        title: 'Buscar por código',
      }}
      usePackageRowColors
      onChangePaginate={paginateCallback}
      dataSource={filteredPackages}
      paginate={pagination}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default PackageSearchByCode;
