import { all, put, select, takeEvery, call } from 'redux-saga/effects';
import API from 'constants/api';
import { getData } from 'util/Api';
import {
  packagePaquerypointDepartureItemsSelector,
  packagePaquerypointDepartureAllPaqueryPointsItemsSelector,
} from './selectors';
import { name, actions } from './slice';

function* fetchPaquerypointsSaga() {
  try {
    const paquerypoints = yield call(
      getData,
      API.paqueryPoint.getAllUnrelatedToOpl,
    );
    yield put({
      type: actions.fetchPaquerypointsSuccess.type,
      payload: paquerypoints,
    });
  } catch (error) {
    yield put({ type: actions.fetchPaquerypointsFailure.type });
  }
}

function* initialSaga() {
  const { items, paquerypoints } = yield all({
    items: yield select(packagePaquerypointDepartureItemsSelector),
    paquerypoints: yield select(
      packagePaquerypointDepartureAllPaqueryPointsItemsSelector,
    ),
  });
  if (paquerypoints.length === 0) {
    yield put({ type: actions.fetchPaquerypoints.type });
  }
  if (!items || items.length === 0) {
    const storageItems = localStorage.getItem(name);
    if (storageItems) {
      yield put({
        type: actions.success.type,
        payload: JSON.parse(storageItems),
      });
    }
  }
}

function* updateStoragePackagesSaga() {
  const items = yield select(packagePaquerypointDepartureItemsSelector);
  localStorage.setItem(name, JSON.stringify(items));
}

function* dispatchSuccessSaga({ payload }) {
  const packageIds = payload;
  const items = yield select(packagePaquerypointDepartureItemsSelector);
  const filteredPackages = items.filter(
    (packet) => !packageIds.includes(packet.id),
  );
  localStorage.removeItem(name);
  yield put({ type: actions.reset.type });
  if (filteredPackages.length) {
    yield put({ type: actions.updatePackets.type, payload: filteredPackages });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.dispatchPackagesSuccess, dispatchSuccessSaga),
    takeEvery(actions.removePacketSuccess, updateStoragePackagesSaga),
    takeEvery(actions.addPackage, updateStoragePackagesSaga),
    takeEvery(actions.fetchPaquerypoints, fetchPaquerypointsSaga),
  ]);
}
