import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  storeId: null,
  items: [],
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  date: {
    from: '',
    to: '',
  },
  search: '',
  status: '',
  type: '',
  loaded: true,
};

const historySlice = createSlice({
  name: 'storePackagesHistory',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
    },
    failure: (state) => {
      state.loaded = true;
    },
    setPageable: (state, action) => {
      state.pageable.pageNumber =
        state.pageable.pageSize !== action.payload.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    setDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.pageable.pageNumber = 0;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
      state.pageable.pageNumber = 0;
    },
    setPackageType: (state, action) => {
      state.type = action.payload;
      state.pageable.pageNumber = 0;
    },
    setStore: (state, action) => {
      state.storeId = action.payload;
      state.pageable.pageNumber = 0;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, reducer, actions } = historySlice;
