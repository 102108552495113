import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  items: [],
  departureType: '',
  searchExternalCode: '',
  origin: '',
  destination: '',
  date: {
    from: '',
    to: '',
  },
  loaded: true,
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
};

const packageDepartureHistorySlice = createSlice({
  name: 'packageDepartureHistory',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    failure: (state) => {
      state.loaded = true;
    },
    setPageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    setSearchByExternalCode: (state, action) => {
      state.pageable.pageNumber = 0;
      state.searchExternalCode = action.payload.trim();
    },
    setDepartureType: (state, action) => {
      state.pageable.pageNumber = 0;
      state.departureType = action.payload;
    },
    setOrigin: (state, action) => {
      state.origin = action.payload;
    },
    setDestination: (state, action) => {
      state.pageable.pageNumber = 0;
      state.destination = action.payload;
    },
    setDate: (state, action) => {
      state.pageable.pageNumber = 0;
      state.date = action.payload;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
});

export const { name, actions, reducer } = packageDepartureHistorySlice;
