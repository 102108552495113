import React, { useEffect, useCallback } from 'react';
import rest from 'util/Api';
import store from 'app/store';
import API from 'constants/api';
import {
  storeLoadedSelector,
  storeItemsSelector,
  storePageableSelector,
} from 'redux/store/selectors';
import { actions } from 'redux/store/slice';
import { useSelector, useDispatch } from 'react-redux';
import useStore from 'redux/store';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import { useParams } from 'react-router-dom';
import DeleteModal from 'components/deleteModal';
import EditRecordButton from 'components/editRecordButton';
import DeleteRecordButton from 'components/deleteRecordButton';

const storeNumberColumn = {
  title: 'Código de tienda',
  dataIndex: 'key',
  width: 150,
};

const nameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
};

const addressColumn = {
  title: 'Dirección',
  dataIndex: 'address',
};

const contactColumn = {
  title: 'Responsable',
  dataIndex: 'contactName',
};

const activeColumn = {
  title: 'Activo',
  dataIndex: 'active',
};

const dataColumns = [
  storeNumberColumn,
  nameColumn,
  contactColumn,
  addressColumn,
  activeColumn,
];

const MarketplaceStoreList = () => {
  useStore();
  const dispatch = useDispatch();
  const loaded = useSelector(storeLoadedSelector);
  const items = useSelector(storeItemsSelector);
  const pageable = useSelector(storePageableSelector);
  const { marketplaceId } = useParams();

  useEffect(() => {
    dispatch(actions.updateMarketplace(marketplaceId));
  }, [marketplaceId, dispatch]);

  const deleteAction = (id, name) => {
    const deleteRequest = async () => {
      let response;
      try {
        response = await rest.del(`${API.stores.create}/${id}`);
        if (rest.isSuccessResponse(response)) {
          store.dispatch(actions.refreshPage(marketplaceId));
        }
      } catch (error) {
        // Nothing to do
      }
      return response;
    };
    DeleteModal({
      title: `¿Estas seguro de que deseas eliminar la tienda ${name}?`,
      onConfirmation: deleteRequest,
    });
  };

  const editColumn = {
    align: 'center',
    render: (_, record) => (
      <EditRecordButton
        record={record}
        link={`/marketplaces/${marketplaceId}/stores`}
      />
    ),
  };

  const deleteColumn = {
    align: 'center',
    render: (_, record) => (
      <DeleteRecordButton
        onClick={() => deleteAction(record.key, `${record.name}`)}
      />
    ),
  };

  const columnsLargeDevice = [...dataColumns, editColumn, deleteColumn];

  const columnsSmallDevice = [nameColumn, editColumn, deleteColumn];

  const columnsMediumDevice = [
    nameColumn,
    storeNumberColumn,
    editColumn,
    deleteColumn,
  ];

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Nombre',
  };

  let filteredStores = [];
  if (loaded && items) {
    filteredStores = items.map((storeItem) => ({
      key: storeItem.id,
      name: storeItem.name,
      contactName: storeItem.contactName,
      storeCode: storeItem.storeCode,
      address: storeItem.address,
      active: storeItem.active ? 'Si' : 'No',
    }));
  }
  return (
    <>
      <PaqueryTable
        loading={!loaded}
        header={{
          searcher,
          refresh: () => dispatch(actions.refreshPage(marketplaceId)),
          primaryButton: (
            <TableHeader.AddButton
              url={`/marketplaces/${marketplaceId}/stores/add`}
            />
          ),
        }}
        onChangePaginate={updatePaginate}
        dataSource={filteredStores}
        paginate={pageable}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
    </>
  );
};

export default MarketplaceStoreList;
