import React from 'react';
import PropTypes from 'prop-types';
import { FormItem, Checkbox } from 'formik-antd';

const CheckboxField = ({ fieldName, label, disabled }) => (
  <div style={{ textAlign: 'left' }}>
    <FormItem name={fieldName} style={{ marginBottom: 0 }}>
      <Checkbox name={fieldName} style={{ width: '100%' }} disabled={disabled}>
        {label}
      </Checkbox>
    </FormItem>
  </div>
);

CheckboxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CheckboxField.defaultProps = {
  disabled: false,
};

export default CheckboxField;
