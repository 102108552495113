import { createAction } from '@reduxjs/toolkit';
import { actions as actPackages } from 'redux/packages/slice';
import { actions as actHistory } from 'redux/packagesHistory/slice';
import { actions as actWithoutZones } from 'redux/packagesWithoutZones/slice';
import { actions as actWithPaquers } from 'redux/packagesWithPaquers/slice';
import { actions as actWithPaquersHistory } from 'redux/packagesWithPaquersHistorical/slice';
import { takeEvery, put } from 'redux-saga/effects';
import { finalStatuses } from 'constants/packages';
import { removePacket } from './actions';

const updateAllPacket = createAction('updateAllPacket');

export { updateAllPacket };

function* updatePacketSaga({ payload }) {
  const packet = payload;
  const isHistory = finalStatuses.some(
    (statuses) => statuses === packet.status,
  );
  const isNormalPacket = !finalStatuses.some(
    (statuses) => statuses === packet.status,
  );
  const isWithoutZonePacket =
    !packet.shippingScheduleDestination.zone ||
    packet.shippingScheduleDestination.zone === {};
  const isWithPaquersPacket = !finalStatuses.some(
    (statuses) => statuses === packet.status,
  );

  yield put({ type: removePacket.type, payload: packet.id });
  if (isHistory) {
    yield put({
      type: actWithPaquersHistory.updatePacket.type,
      payload: packet,
    });
    yield put({ type: actHistory.updatePacket.type, payload: packet });
  }
  if (isNormalPacket) {
    yield put({ type: actPackages.updatePacket.type, payload: packet });
  }
  if (isWithoutZonePacket) {
    yield put({ type: actWithoutZones.updatePacket.type, payload: packet });
  }
  if (isWithPaquersPacket) {
    yield put({ type: actWithPaquers.updatePacket.type, payload: packet });
  }
}

export default function* rootSaga() {
  yield takeEvery(updateAllPacket.type, updatePacketSaga);
}
