/* eslint-disable import/no-named-as-default-member */
import React, { useCallback, useEffect, useState } from 'react';
import rest from 'util/Api';
import dayjs from 'dayjs';
import JsDownload from 'js-file-download';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PaqueryTable from '@paquery-team/lib-table';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import API from 'constants/api';
import { ExportDateFormat } from 'constants/dateFormats';
import { PACKAGE } from 'constants/packages';
import { notification } from 'antd';
import {
  loadedSelector,
  dateSelector,
  typeSelector,
  statusSelector,
  searchSelector,
} from 'redux/packagesByStore/selectors';
import { actions } from 'redux/packagesByStore/slice';
import useStoreNormalPackages from 'redux/packagesByStore';
import { useSendStatusOptions, useDirectStatusOptions } from 'hooks/useOptions';
import { selectGlobals } from 'redux/globals/selectors';
import useTableDataNormal from '../hooks/useTableDataNormal';

const StorePackages = () => {
  useStoreNormalPackages();
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const { packages, pageable, columns, colsSM, colsMD, colsLG } =
    useTableDataNormal();
  const globals = useSelector(selectGlobals);
  const status = useSelector(statusSelector);
  const search = useSelector(searchSelector);
  const date = useSelector(dateSelector);
  const loaded = useSelector(loadedSelector);
  const packageType = useSelector(typeSelector);
  const storeStatuses = useSendStatusOptions();
  const storeWithdrawalStatuses = useDirectStatusOptions();
  const filteredStatus =
    packageType === PACKAGE.TYPES.DIRECT
      ? storeWithdrawalStatuses
      : storeStatuses;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(actions.resetFilters());
    dispatch(actions.setStore(storeId));
  }, [storeId, dispatch]);

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.setSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.setPageable(page));
    },
    [dispatch],
  );

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.setDate(value));
    },
    [dispatch],
  );

  const packageTypeSearchCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.setPackageType(newPackageType));
    },
    [dispatch],
  );
  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.setStatus(value));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      onChange: packageTypeSearchCallback,
      list: globals.packages.type,
      placeholder: 'Tipo de envio',
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: filteredStatus,
    },
  ];
  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const searchParams = new URLSearchParams({
        search,
        storeId,
        status,
        from: date.from,
        to: date.to,
        packageType,
      });
      const response = await rest.get(
        `${API.reports.storePackages}?${searchParams.toString()}`,
        {
          responseType: 'blob',
        },
      );
      const filename = `Paquetes-${dayjs().tz().format(ExportDateFormat)}.xlsx`;
      JsDownload(response.data, filename);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        selectors,
        searcher,
        rangePicker: {
          onDateSelection: updateDate,
          required: {
            value: true,
            message: 'La fecha es requerida',
          },
        },
        onExportCsv: { callback: handleExportCsv, disabled },
        refresh: () => dispatch(actions.refreshPage()),
      }}
      usePackageRowColors
      onChangePaginate={updatePaginate}
      dataSource={packages}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={columns}
      colsForSmallDevice={colsSM}
      colsForMediumDevice={colsMD}
      colsForLargeDevice={colsLG}
    />
  );
};

export default StorePackages;
