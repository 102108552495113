const DateDifference = (startDate: string, endDate: string): string => {
  // Parsear las fechas ISO 8601
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calcular la diferencia en milisegundos
  const diffMs = end.getTime() - start.getTime();

  // Convertir la diferencia a horas y minutos
  const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  // Formatear la diferencia
  const formattedDiff = `${String(diffHrs).padStart(2, '0')}:${String(diffMins).padStart(2, '0')} hs`;

  return formattedDiff;
};

export default DateDifference;
