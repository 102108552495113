import React from 'react';
import { Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const DeleteModal = ({ title, onConfirmation }) => {
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    description: 'Esta accion no se puede deshacer',
    async onOk() {
      const response = await onConfirmation();
      if (response && response.status !== 400)
        return notification.success({
          message: 'Eliminación completada',
          description: 'El elemento fue borrado correctamente',
        });
      return notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al procesar el borrado${
          response.data.message ? `: ${response.data.message}` : '.'
        }`,
      });
    },
  });
};

export default DeleteModal;
