import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, getIn } from 'formik';
import { Slider, InputNumber, Tooltip, theme } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    width: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

const SliderField = ({
  fieldName,
  vertical,
  min,
  max,
  disabled,
  defaultValue,
  onChange,
  withInput,
  label,
}) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const { values, errors, setFieldValue } = useFormikContext();
  useEffect(() => {
    setFieldValue(fieldName, defaultValue);
  }, [defaultValue, setFieldValue, fieldName]);
  return (
    <Tooltip
      destroyTooltipOnHide
      color="red"
      title={errors[fieldName]}
      open={!!errors[fieldName]}
    >
      <div className={classes.container}>
        <Slider
          vertical={vertical}
          name={fieldName}
          min={min}
          max={max}
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={(value) => {
            setFieldValue(fieldName, value);
            if (onChange) {
              onChange(value);
            }
          }}
          value={getIn(values, fieldName)}
        />
        {withInput && (
          <InputNumber
            disabled={disabled}
            name={fieldName}
            min={min}
            max={max}
            style={{ marginTop: 7 }}
            defaultValue={defaultValue}
            onChange={(value) => {
              setFieldValue(fieldName, value);
              if (onChange) {
                onChange(value);
              }
            }}
            value={getIn(values, fieldName)}
          />
        )}
        <span>{label}</span>
      </div>
    </Tooltip>
  );
};

SliderField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  withInput: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

SliderField.defaultProps = {
  min: 0,
  max: 999,
  disabled: false,
  defaultValue: 0,
  onChange: null,
  withInput: false,
  vertical: false,
};

export default SliderField;
