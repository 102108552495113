import React, { useEffect, useCallback, useState } from 'react';
import store from 'app/store';
import dayjs from 'dayjs';
import { Avatar, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import usePaquers from 'redux/paquers';
import { actions } from 'redux/paquers/slice';
import {
  paquersFilteredItemsSelector,
  paquersLoadedSelector,
  paquersItemsSelector,
} from 'redux/paquers/selectors';
import { authProfile } from 'redux/auth/selectors';

import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { PERMISSIONS } from 'constants/roles';
import API from 'constants/api';
import { ExportDateFormat } from 'constants/dateFormats';
import rest from 'util/Api';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import DeleteModal from 'components/deleteModal';
import CsvDownloader from 'util/CsvDownloader';
import DeleteRecordButton from 'components/deleteRecordButton';
import EditRecordButton from 'components/editRecordButton';
import { vehicleTypesGlobalsSelector } from 'redux/globals/selectors';

const avatarColumn = {
  title: 'Avatar',
  render: (_, record) => <Avatar icon={<UserOutlined />} src={record.avatar} />,
};

const nameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
};

const mobileColumn = {
  title: 'Teléfono',
  dataIndex: 'mobile',
};

const lastnameColumn = {
  title: 'Apellido',
  dataIndex: 'lastname',
};

const emailColumn = {
  title: 'Usuario',
  dataIndex: 'email',
};

const activeColumn = {
  title: 'Estado',
  dataIndex: 'active',
};

const deleteAction = (paquerId, paquerName) => {
  const deleteRequest = async () => {
    let response;
    try {
      response = await rest.del(`${API.paquer.delete}${paquerId}`);
      if (response.status === 204) {
        store.dispatch({ type: actions.refreshPage.type });
      }
    } catch (error) {
      // Nothing to do
    }
    return response;
  };
  DeleteModal({
    title: `¿Estas seguro de que deseas eliminar a ${paquerName}`,
    onConfirmation: deleteRequest,
  });
};

const getActiveStatus = (status) => {
  if (status === 1) return 'Activo';
  if (status === 6) return 'Inactivo';
  return false;
};

const editPaquerColumn = {
  title: 'Editar',
  align: 'center',
  render: (_, record) => <EditRecordButton link="/paquers" record={record} />,
};

const deletePaquerColumn = {
  title: 'Eliminar',
  align: 'center',
  render: (_, record) => (
    <DeleteRecordButton
      onClick={() =>
        deleteAction(record.key, `${record.name} ${record.lastname}`)
      }
    />
  ),
};

const mediumDeviceColumns = [nameColumn, lastnameColumn, emailColumn];

const withPermissionsColumns = [editPaquerColumn, deletePaquerColumn];

const columnsSmallDevice = [nameColumn, lastnameColumn];

const PaquerList = () => {
  usePaquers();
  const dispatch = useDispatch();
  const profile = useSelector(authProfile);
  const loaded = useSelector(paquersLoadedSelector);
  const fullItems = useSelector(paquersItemsSelector);
  const vehicles = useSelector(vehicleTypesGlobalsSelector);
  const { items, pageable } = useSelector(paquersFilteredItemsSelector);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    dispatch(actions.initial());
  }, [dispatch]);
  const getCsv = async () => {
    setDisabled(true);
    try {
      let csv = fullItems;
      csv = csv.map((paquer) => ({
        ...paquer,
        active: getActiveStatus(paquer.status),
        vehicleTypeID: paquer.vehicleTypeID
          ? vehicles.filter((type) => type.id === paquer.vehicleTypeID)[0].name
          : null,
      }));
      const csvHeader = [
        { label: 'Nombre', value: 'name' },
        { label: 'Apellido', value: 'lastName' },
        { label: 'Email', value: 'email' },
        { label: 'Número de documento', value: 'docNumber' },
        { label: 'Teléfono', value: 'mobile' },
        { label: 'Vehículo', value: 'vehicleTypeID' },
        { label: 'Estado', value: 'active' },
      ];
      const filename = `paquers-${dayjs().tz().format(ExportDateFormat)}.csv`;
      CsvDownloader(csv, csvHeader, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquers',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };
  let filteredPaquers;
  if (loaded && items) {
    filteredPaquers = items.map((paquer) => ({
      ...paquer,
      key: paquer.id,
      name: paquer.name,
      lastname: paquer.lastName,
      avatar: paquer.avatar,
      email: paquer.email,
      active: getActiveStatus(paquer.status),
    }));
  }
  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );
  const searcher = {
    key: 'searchCodeOrDestinationName',
    onSearching: searchCallback,
    placeholder: 'Nombre o email',
    allowEmptySearch: true,
  };
  const canAlterPaquer = profile.userRoleActions.some(
    (role) => role === PERMISSIONS.AddEditPaquer,
  );

  const vehicleTypeColumn = {
    title: 'Vehículo',
    dataIndex: 'vehicleTypeID',
    render: (vehicleTypeID) =>
      vehicleTypeID
        ? vehicles.filter((type) => type.id === vehicleTypeID)[0].name
        : null,
  };

  const dataColumns = [
    nameColumn,
    lastnameColumn,
    emailColumn,
    activeColumn,
    mobileColumn,
    vehicleTypeColumn,
  ];

  const fullSizeColumns = [
    avatarColumn,
    nameColumn,
    lastnameColumn,
    emailColumn,
    mobileColumn,
    vehicleTypeColumn,
    activeColumn,
  ];

  return (
    <PaqueryTable
      loading={!loaded}
      header={
        canAlterPaquer
          ? {
              title: 'Paquers',
              searcher,
              refresh: () => dispatch(actions.refreshPage()),
              onExportCsv: { callback: getCsv, disabled },
              primaryButton: <TableHeader.AddButton url="/paquers/add" />,
            }
          : {
              title: 'Paquers',
              searcher,
              refresh: () => dispatch(actions.refreshPage()),
              onExportCsv: { callback: getCsv, disabled },
            }
      }
      onChangePaginate={updatePaginate}
      dataSource={filteredPaquers}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={
        canAlterPaquer
          ? [...mediumDeviceColumns, ...withPermissionsColumns]
          : mediumDeviceColumns
      }
      colsForSmallDevice={
        canAlterPaquer
          ? [...columnsSmallDevice, ...withPermissionsColumns]
          : columnsSmallDevice
      }
      colsForLargeDevice={
        canAlterPaquer
          ? [...fullSizeColumns, ...withPermissionsColumns]
          : fullSizeColumns
      }
    />
  );
};

export default PaquerList;
