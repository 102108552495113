import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'constants/api';
import { getData } from 'util/Api';
import { actions } from './slice';

function* fetchSaga({ payload: userId }) {
  try {
    const response = yield call(getData, `${API.user.get}${userId}`);
    if (response.data === null) {
      throw new Error('Usuario no encontrado');
    }
    yield put({ type: actions.success.type, payload: response });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.fetch, fetchSaga);
}
