import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import rest, { getData } from 'util/Api';
import { useSelector, useDispatch } from 'react-redux';
import { userviewAvatarSelector } from 'redux/userView/selectors';
import {
  countriesGlobalsSelector,
  rolesGlobalsSelector,
} from 'redux/globals/selectors';
import useUserview from 'redux/userView/index';
import { actions as authActions } from 'redux/auth/slice';
import { actions } from 'redux/userView/slice';
import { notification } from 'antd';
import User from 'routes/app/user';
import { IMAGE_REGEX } from 'constants/defaultValues';
import API from 'constants/api';
import * as Yup from 'yup';
import { authProfile } from 'redux/auth/selectors';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del paquer'),
  lastName: Yup.string().required(
    'Es necesario indicar el apellido del paquer',
  ),
  phone: Yup.number().required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
});

const defaultValues = {
  name: '',
  lastName: '',
  phone: null,
  email: '',
};

const UserProfile = () => {
  useUserview();
  const dispatch = useDispatch();
  const profile = useSelector(authProfile);
  const countries = useSelector(countriesGlobalsSelector);
  const avatar = useSelector(userviewAvatarSelector);
  const roles = useSelector(rolesGlobalsSelector);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  useEffect(() => {
    const getCities = async () => {
      dispatch(actions.loading());
      const citiesResponse = await getData(
        `${API.globals.cities}/${currentCountry}`,
      );
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(undefined);
      }
      dispatch(actions.loaded());
    };
    if (currentCountry) {
      getCities();
    }
  }, [currentCountry, dispatch]);

  useEffect(() => {
    dispatch(actions.reset());
    dispatch(actions.loading());
    const getCitiesAndLoadProfile = async () => {
      const citiesResponse = await getData(
        `${API.globals.cities}/${profile.countryID}`,
      );
      setCities(citiesResponse);
      setInitialValues(profile);
      dispatch(actions.loaded());
    };
    dispatch(actions.setAvatar({ data: profile.avatar }));
    getCitiesAndLoadProfile();
  }, [profile, dispatch]);

  const handleSubmit = async (values) => {
    const payload = {
      Avatar: avatar.name,
      AvatarImg: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : undefined,
      CityID: values.cityID,
      CountryID: values.countryID,
      Email: values.email,
      ID: values.id,
      LastName: values.lastName,
      LogisticOperatorID: values.logisticOperatorID,
      Mobile: values.phone,
      Name: values.name,
      UserRoleID: values.userRoleID,
    };
    try {
      const response = await rest.post(API.user.update, payload);
      if (response.status === 400) {
        throw new Error(response.data.message);
      } else {
        notification.success({
          message: 'Actualización de perfil',
          description: 'Tu perfil ha sido actualizado correctamente',
        });
        dispatch(authActions.reloadProfile());
        setTimeout(() => {
          history.goBack();
        }, 1000);
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al actualizar paquer${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };
  return (
    <User
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      countries={countries}
      cities={cities}
      roles={roles}
      currentCity={currentCity}
      currentCountry={currentCountry}
      setCurrentCountry={setCurrentCountry}
      isProfile
    />
  );
};

export default UserProfile;
