import React, { useEffect, useCallback, useState } from 'react';
import store from 'app/store';
import dayjs from 'dayjs';
import { notification } from 'antd';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import rest from 'util/Api';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import API from 'constants/api';
import { ExportDateFormat } from 'constants/dateFormats';
import DeleteModal from 'components/deleteModal';
import CsvDownloader from 'util/CsvDownloader';
import useUsers from 'redux/users';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/users/slice';
import {
  usersLoadedSelector,
  usersPageableItemsSelector,
  userFilterItemsSelector,
} from 'redux/users/selectors';
import { selectGlobals } from 'redux/globals/selectors';
import DeleteRecordButton from 'components/deleteRecordButton';
import EditRecordButton from 'components/editRecordButton';

const deleteAction = (userId, userName) => {
  const deleteRequest = async () => {
    let response;
    try {
      response = await rest.del(`${API.user.delete}${userId}`);
      if (response.status === 200) {
        store.dispatch({ type: actions.removeUser.type, payload: userId });
      }
    } catch (error) {
      // Nothing to do
    }
    return response;
  };
  DeleteModal({
    title: `¿Estas seguro de que deseas eliminar a ${userName}?`,
    onConfirmation: deleteRequest,
  });
};

const idColumn = {
  title: 'ID',
  dataIndex: 'key',
  align: 'center',
};

const nameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
};

const lastnameColumn = {
  title: 'Apellido',
  dataIndex: 'lastName',
};

const emailColumn = {
  title: 'Usuario',
  dataIndex: 'email',
};

const roleColumn = {
  title: 'Rol',
  dataIndex: 'role',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'status',
};

const editColumn = {
  title: 'Editar',
  align: 'center',
  render: (_, record) => <EditRecordButton link="/users" record={record} />,
};

const deleteColumn = {
  title: 'Eliminar',
  align: 'center',
  render: (_, record) => (
    <DeleteRecordButton
      onClick={() =>
        deleteAction(record.key, `${record.name} ${record.lastName}`)
      }
    />
  ),
};

const dataColumns = [
  idColumn,
  nameColumn,
  lastnameColumn,
  emailColumn,
  roleColumn,
  statusColumn,
];

const fullSizeColumns = [
  idColumn,
  nameColumn,
  lastnameColumn,
  emailColumn,
  statusColumn,
  editColumn,
  deleteColumn,
];

const smallSizeColumns = [idColumn, nameColumn, lastnameColumn];

const UserList = () => {
  useUsers();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const loaded = useSelector(usersLoadedSelector);
  const filteredItems = useSelector(userFilterItemsSelector);
  const { items, pageable } = useSelector(usersPageableItemsSelector);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    dispatch(actions.initial());
  }, [dispatch]);
  const getCsv = async () => {
    setDisabled(true);
    try {
      const csv = filteredItems;
      const csvHeader = [
        { label: 'ID', value: 'id' },
        { label: 'Nombre', value: 'name' },
        { label: 'Apellido', value: 'lastName' },
        { label: 'Email', value: 'email' },
        { label: 'Teléfono', value: 'mobile' },
        { label: 'País', value: 'countryName' },
        { label: 'Ciudad', value: 'cityName' },
        { label: 'Rol', value: 'roleName' },
        { label: 'Estado', value: 'statusName' },
      ];
      const filename = `usuarios-${dayjs().tz().format(ExportDateFormat)}.csv`;
      CsvDownloader(csv, csvHeader, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const statusList = [
    { name: 'Activo', value: 1 },
    { name: 'Dado de baja', value: 6 },
  ];
  let filteredUsers;
  if (loaded && items) {
    filteredUsers = items.map((user) => {
      const roleId = globals.roles.find(
        (userRole) => userRole.value === user.userRoleID,
      );
      return {
        ...user,
        key: user.id,
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        userRoleID: globals && roleId ? roleId.name : null,
        status: user.statusName,
      };
    });
  }
  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );
  const selectRoleCallback = useCallback(
    (role) => {
      dispatch(actions.updateRole(role));
    },
    [dispatch],
  );
  const selectStatusCallback = useCallback(
    (status) => {
      dispatch(actions.updateStatus(status));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Nombre',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      list: globals && globals.roles,
      placeholder: 'Rol',
      onChange: selectRoleCallback,
    },
    {
      list: statusList,
      placeholder: 'Estado',
      onChange: selectStatusCallback,
    },
  ];
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        title: 'Usuarios',
        searcher,
        selectors,
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: getCsv, disabled },
        primaryButton: <TableHeader.AddButton url="/users/add" />,
      }}
      onChangePaginate={updatePaginate}
      dataSource={filteredUsers}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={fullSizeColumns}
      colsForSmallDevice={smallSizeColumns}
      colsForLargeDevice={fullSizeColumns}
    />
  );
};

// TODO Agregar filtro de perfil para del tipo administrador marketplace

export default UserList;
