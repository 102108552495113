import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Avatar, Tooltip, Col, Row, Button, theme } from 'antd';
import useStyles from './styles';

const ModalButton = ({ name, image, icon, shape }) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Tooltip title={name}>
        <Avatar
          icon={icon}
          src={image}
          onClick={() => image && setVisible(true)}
          style={{ cursor: image ? 'pointer' : 'initial' }}
          shape={shape}
        >
          No
        </Avatar>
        <Modal
          title={null}
          closable={false}
          bodyStyle={{ margin: 0, padding: 0 }}
          open={visible}
          onCancel={() => setVisible(false)}
          footer={null}
          centered
        >
          <Row>
            <Col span={24} className={classes.modalHeaderFooter}>
              <span>{name}</span>
            </Col>
            <Col span={24} className={classes.center}>
              <img src={image} alt={name} style={{ width: 250 }} />
            </Col>
            <Col span={24} className={classes.modalButtonContainer}>
              <Button
                type="primary"
                className={classes.modalButton}
                onClick={() => setVisible(false)}
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal>
      </Tooltip>
    </>
  );
};

ModalButton.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  icon: PropTypes.element,
  shape: PropTypes.string,
};

ModalButton.defaultProps = {
  image: null,
  icon: null,
  shape: 'circle',
};

export default ModalButton;
