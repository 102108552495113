import React, { useEffect, useCallback, useState } from 'react';
import rest from 'util/Api';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { notification } from 'antd';
import JsDownload from 'js-file-download';
import PaqueryTable from '@paquery-team/lib-table';
import ViewPackageButton from 'components/viewPackagebutton';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import ModalButton from 'components/modalButton';
import { actions } from 'redux/paquerypointView/slice';
import { selectGlobals } from 'redux/globals/selectors';
import {
  paquerypointViewHistoryLoadedSelector,
  paquerypointViewHistoryDateSelector,
  paquerypointViewHistorySearchSelector,
  paquerypointViewHistoryMarketFilterSelector,
  paquerypointViewHistoryTypeSelector,
  paquerypointViewHistoryStatusSelector,
  paquerypointViewHistoryItemsSelector,
  paquerypointViewHistoryPageableSelector,
  paquerypointViewPaquerypointIDSelector,
} from 'redux/paquerypointView/selectors';
import { paquerypointsItemsSelector } from 'redux/paquerypoint/selectors';
import usePaquerypoints from 'redux/paquerypoint';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import { useFinalStatusOptions, useMarketplaceOptions } from 'hooks/useOptions';
import EditRecordButton from 'components/editRecordButton';
import useMarketplaces from 'redux/marketplaces';

const fetchHistoryCsv = async (
  search,
  paquerypointId,
  date,
  marketplaceId,
  packageType,
  status,
) => {
  try {
    const searchParams = new URLSearchParams({
      search,
      from: date.from,
      to: date.to,
      paqueryPointId: paquerypointId,
      marketplaceId: marketplaceId || '',
      packageType,
      status,
    });
    // eslint-disable-next-line import/no-named-as-default-member
    const response = await rest.get(
      `${API.reports.historyWithPaqueryPoint}?${searchParams.toString()}`,
      {
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (error) {
    return undefined;
  }
};

const statusStyle = (statusText) => {
  let bgColor;
  switch (statusText) {
    case 'Entregado':
      bgColor = 'green';
      break;
    case 'Cancelado':
      bgColor = 'red';
      break;
    case 'Devuelto':
      bgColor = 'gold';
      break;
    default:
      break;
  }
  return {
    background: bgColor,
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };
};

const signatureAvatar = (record) => (
  <ModalButton
    name={record.signatureName}
    shape="square"
    image={record.signatureImage}
  />
);

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const signatureColumn = {
  title: 'Firma',
  render: (_, record) => signatureAvatar(record),
};

const signatureTypeColumn = {
  title: 'Tipo Firma',
  dataIndex: 'signatureType',
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  render: (text) => <span style={statusStyle(text)}>{text}</span>,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  statusColumn,
];

const fullSizeColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureColumn,
  signatureTypeColumn,
  statusColumn,
  editColumn,
  viewPackageColumn,
];

const columnsSmallDevice = [externalCodeColumn, editColumn, viewPackageColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  editColumn,
  viewPackageColumn,
];

const PackageHistory = () => {
  usePaquerypoints({ initialize: true });
  useMarketplaces({ initialize: true });
  const dispatch = useDispatch();
  const paqueryPoints = useSelector(paquerypointsItemsSelector);
  const loaded = useSelector(paquerypointViewHistoryLoadedSelector);
  const globals = useSelector(selectGlobals);
  const date = useSelector(paquerypointViewHistoryDateSelector);
  const search = useSelector(paquerypointViewHistorySearchSelector);
  const items = useSelector(paquerypointViewHistoryItemsSelector);
  const pageable = useSelector(paquerypointViewHistoryPageableSelector);
  const paquerypointId = useSelector(paquerypointViewPaquerypointIDSelector);
  const status = useSelector(paquerypointViewHistoryStatusSelector);
  const packageType = useSelector(paquerypointViewHistoryTypeSelector);
  const marketplace = useSelector(paquerypointViewHistoryMarketFilterSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const [paqueryPointList, setPaqueryPointList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const filteredFinalStatuses = useFinalStatusOptions();

  useEffect(() => {
    if (paqueryPoints.length > 0) {
      setPaqueryPointList(
        paqueryPoints.map((pPoint) => ({
          value: pPoint.id,
          name: `${pPoint.name}`,
        })),
      );
    }
  }, [paqueryPoints]);

  const getCsv = async () => {
    setDisabled(true);
    /* if (!haveDateLoaded(date)) {
       notification.warning({
         message: 'Falta indicar fecha',
         description: 'Es necesario indicar un rango de fechas',
       });
       return;
     } */
    try {
      dispatch(actions.setHistoryLoading());
      const csv = await fetchHistoryCsv(
        search,
        paquerypointId,
        date,
        marketplace,
        packageType,
        status,
      );
      const paqueryPointFilteredName = paquerypointId
        ? `-${
            paqueryPointList.find(
              (pPointOnList) => pPointOnList.value === paquerypointId,
            ).name
          }`
        : 'Paquery Points';
      const filename = ` Paquetes-Historial-${paqueryPointFilteredName}-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(csv, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
      dispatch(actions.setHistoryLoaded());
    }
  };

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearchHistory(value));
    },
    [dispatch],
  );

  const paginateCallback = useCallback(
    (pageableObject) => {
      dispatch(actions.updatePaginationHistory(pageableObject));
    },
    [dispatch],
  );
  const dateFilterCallback = useCallback(
    (newDate) => {
      dispatch(actions.updateDateHistory(newDate));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarketHistory(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageTypeHistory(newPackageType));
    },
    [dispatch],
  );
  const selectStatusCallback = useCallback(
    (newStatus) => {
      dispatch(actions.updateStatusHistory(newStatus));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };

  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      list: marketplacesOptions,
      placeholder: 'Marketplace',
    },
    {
      onChange: selectPackageTypeCallback,
      list: globals.packages.type,
      placeholder: 'Tipo de envio',
    },
    {
      list: filteredFinalStatuses,
      onChange: selectStatusCallback,
      placeholder: 'Estado',
    },
  ];

  let filteredPackages = [];
  if (loaded) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      signatureType: globals.packages.deliveryEndorsementType.find(
        (endorsement) =>
          endorsement.value ===
          packet.shippingScheduleDestination.deliveryPackageType,
      )?.name,
      content: packet.caption,
      deliveryDate: packet.deliveryDate
        ? dayjs(packet.deliveryDate).tz().format(TableDateTimeFormat)
        : null,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      signatureImage: packet.signatureImage,
      signatureName: packet.shippingScheduleDestination.name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searcher,
        selectors,
        rangePicker: {
          onDateSelection: dateFilterCallback,
          required: {
            value: true,
            message: 'La fecha es requerida',
          },
        },
        refresh: () => dispatch(actions.fetchHistory()),
        onExportCsv: { callback: getCsv, disabled },
      }}
      onChangePaginate={paginateCallback}
      dataSource={filteredPackages}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={columnsMediumDevice}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
      usePackageRowColors
    />
  );
};

export default PackageHistory;
