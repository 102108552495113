import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import makePackageRefundDepartureSelector from './selectors';
import { name, reducer, actions } from './slice';
import saga from './saga';

export default function usePackageRefundDeparture() {
  const dispatch = useDispatch();
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useSelector(makePackageRefundDepartureSelector(), shallowEqual);
  useEffect(() => {
    return () => {
      dispatch(actions.resetFilters());
    };
  }, [dispatch]);
  return null;
}
