import { createUseStyles } from 'react-jss';

export default createUseStyles({
  marker: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '30px',
    textShadow:
      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
    margin: '0 auto',
    width: '99%',
    textWrap: 'pretty',
    textAlign: 'center',
  },
});
