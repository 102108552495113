export const LogisticOperatorRoles = [
  3,
  4, // Operador Logistico
];

export const MarketplaceRoles = [
  5,
  6, // Operator
];

export const PaqueryPointRoles = [
  7,
  8, // Operator
];

export const StoreRoles = [
  11,
  12, // Operator
];

export const isProfileAdministrator = (profile) => {
  return profile.userRoleID === LogisticOperatorRoles[0];
};

export const PERMISSIONS = {
  AddEditPaquer: 'Paquers-edit',
  AlterMarketplace: 'Marketplace',
};
