import Api from 'util/Api';
import API from 'constants/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

interface GetStoresParams {
  pageNumber?: number;
  pageSize?: number;
  marketplaceId?: number;
  search?: string;
}
interface Store {
  uuid: string;
  contactName: string;
  phone: string;
  notifierTypeValue: string;
  credentials: {
    storeID: number;
    username: string;
  };
  ownerId: number;
  storeCode: string;
  detail: string;
  ownerType: number;
  active: boolean;
  email: string;
  deleted: boolean;
  notifierType: string;
  customerId: number;
  address: string;
  name: string;
  id: number;
}

interface GetStoresData {
  content: Store[];
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

interface GetStoresResponse {
  data?: GetStoresData;
  message: string;
}

const getAllByMarketplace = async (queries: GetStoresParams) => {
  const response = await Api.apiAxios.get<GetStoresResponse>(
    `${API.stores.getByMarketplace}/${queries.marketplaceId}`,
    {
      params: {
        pageNumber: queries.pageNumber,
        pageSize: queries.pageSize,
        search: queries.search,
      },
    },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const useStores = (params: GetStoresParams) => {
  const query = useMemo(() => {
    return {
      pageNumber: params.pageNumber ? Number(params.pageNumber) : 1,
      pageSize: params.pageSize ? Number(params.pageSize) : 10000,
      search: params.search ? params.search : '',
      marketplaceId: params.marketplaceId,
    };
  }, [params]);

  return useQuery({
    queryKey: ['stores', query],
    queryFn: () => getAllByMarketplace(query),
    enabled: !!query.marketplaceId,
  });
};
