import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { name as key, reducer, actions } from './slice';
import saga from './saga';

export default function useDepartureHistorical() {
  const dispatch = useDispatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  useEffect(() => {
    return () => {
      dispatch(actions.resetFilters());
      dispatch(actions.clearItems());
    };
  }, [dispatch]);
}
