import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Button, Card, theme } from 'antd';
import InputField from 'components/Forms/input';
import CheckboxField from 'components/Forms/checkbox';
import useStyles from 'components/Forms/styles';

const Marketplace = ({
  onSubmit,
  initialValues,
  validationSchema,
  loading,
}) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <Card
          title="Información del Marketplace"
          headStyle={{
            borderBottom: `3px solid ${token.colorPrimary}`,
          }}
        >
          <Row>
            <Col span={24} className={classes.cardContent}>
              <Row gutter={[8, 16]}>
                <Col xs={12}>
                  <InputField label="Nombre" fieldName="name" />
                </Col>
                <Col xs={12}>
                  <InputField label="Detalle" fieldName="detail" />
                </Col>
                <Col xs={8}>
                  <InputField
                    label="Contacto comercial"
                    fieldName="commercialContact"
                  />
                </Col>
                <Col xs={8}>
                  <InputField
                    label="Teléfono de contacto comercial"
                    fieldName="commercialPhone"
                  />
                </Col>
                <Col xs={8}>
                  <InputField
                    label="Email de contacto comercial"
                    fieldName="commercialEmail"
                  />
                </Col>
                <Col xs={8}>
                  <InputField
                    label="Contacto soporte"
                    fieldName="contactSupport"
                  />
                </Col>
                <Col xs={8}>
                  <InputField
                    label="Teléfono contacto soporte"
                    fieldName="phoneSupport"
                  />
                </Col>
                <Col xs={8}>
                  <InputField
                    label="Email contacto soporte"
                    fieldName="emailSupport"
                  />
                </Col>
                <Col xs={12}>
                  <InputField
                    label="Representante comercial PaQuery"
                    fieldName="commercialAgent"
                  />
                </Col>
                <Col xs={12}>
                  <InputField
                    label="Dirección de despacho"
                    fieldName="dispatchAddress"
                  />
                </Col>
                <Col xs={24}>
                  <Row gutter={32}>
                    <Col>
                      <CheckboxField label="Publicar" fieldName="published" />
                    </Col>
                    <Col>
                      <CheckboxField
                        label="Envío de notificaciones a usuarios"
                        fieldName="sendCustomerNotifications"
                      />
                    </Col>
                    <Col>
                      <CheckboxField
                        label="Paquetes del tipo Pickup con stock"
                        fieldName="stock"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24} style={{ justifyContent: 'end', display: 'flex' }}>
            <Button
              type="primary"
              htmlType="submit"
              className={classes.saveButton}
              style={{ marginBottom: 5 }}
              size="large"
              loading={loading}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

Marketplace.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    detail: PropTypes.string,
    publish: PropTypes.bool,
    userNotifications: PropTypes.bool,
    pickupStock: PropTypes.bool,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Marketplace;
