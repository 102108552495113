import {
  call,
  put,
  takeEvery,
  takeLeading,
  all,
  select,
} from 'redux-saga/effects';
import API from 'constants/api';
import { getData } from 'util/Api';
import { actions } from './slice';
import { marketplacesItemsSelector } from './selectors';

function* fetchSaga() {
  try {
    const searchParams = new URLSearchParams({
      take: 10000,
      size: 10000,
      search: '',
    });
    const marketplaces = yield call(
      getData,
      `${API.marketplaces.get}?${searchParams.toString()}`,
    );
    yield put({ type: actions.success.type, payload: marketplaces.content });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* initialSaga() {
  const marketplaces = yield select(marketplacesItemsSelector);
  if (marketplaces.length !== 0) return;
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.refreshPage, fetchSaga),
    takeLeading(actions.fetch, fetchSaga),
  ]);
}
