import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { message, theme } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import useStyles from './styles';

const UploadField = ({ image, onChangeImage, disabled, styles }) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const inputFile = useRef();

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo se admiten imagenes PNG o JPG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La imagen debe pesar menos de 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const getBase64 = (img) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      onChangeImage({
        data: reader.result,
        name: img.name,
      });
    });
    reader.readAsDataURL(img);
  };

  const handleChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (beforeUpload(file)) {
        getBase64(file);
      } else {
        e.target.files = null;
      }
    }
  };
  return (
    <>
      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleChange}
        accept=".jpg,.jpeg,.png"
      />
      <div className={classes.imageUploadContainer} style={styles}>
        <button
          type="button"
          onClick={() => inputFile.current.click()}
          disabled={disabled}
          style={{ overflow: 'hidden' }}
          className={
            disabled ? classes.imageUploadDisabled : classes.imageUploadEnabled
          }
        >
          {image.data ? (
            <img src={image.data} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <CameraOutlined />
          )}
        </button>
      </div>
    </>
  );
};

UploadField.propTypes = {
  image: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.string,
  }),
  onChangeImage: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.shape({}),
};

UploadField.defaultProps = {
  image: {
    name: null,
    data: null,
  },
  onChangeImage: null,
  disabled: false,
  styles: {},
};

export default UploadField;
