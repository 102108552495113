import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectPackages = (state) => state[name] || initialState;

export const storePackageNormalSelector = (state) => state[name];

export const storeIdSelector = (state) => state[name].storeId;

export const searchSelector = (state) => state[name].search;

export const typeSelector = (state) => state[name].type;

export const statusSelector = (state) => state[name].status;

export const loadedSelector = (state) => state[name].loaded;

export const pageableSelector = (state) => state[name].pageable;

export const dateSelector = (state) => state[name].date;

export const itemsSelector = (state) => state[name].items;

const makeSelectStoreModifyNormal = () =>
  createSelector(selectPackages, (substate) => substate);

export default makeSelectStoreModifyNormal;
