import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: {},
  search: '',
  pageable: {
    pageNumber: 0,
    pageSize: 10,
  },
  loaded: false,
};

const paquerypointSlice = createSlice({
  name: 'paquerypoint',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      action.payload.forEach((paquerypoint) => {
        state.items[paquerypoint.id] = paquerypoint;
      });
    },
    failure: (state) => {
      state.loaded = true;
    },
    updatePaqueryPoint: (state, action) => {
      state.items[action.payload.id] = action.payload;
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    removePacket: (state, action) => {
      delete state.items[action.payload];
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, reducer, actions } = paquerypointSlice;
