import React from 'react';
import { theme } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

export default function SizeIndicator({ text }: any) {
  const { token } = theme.useToken();
  return text ? (
    <span
      style={{
        background: token.colorPrimary,
        padding: 3,
        color: token.colorBgBase,
        borderRadius: '10px',
        fontWeight: 'bold',
      }}
    >
      {text[0]}
    </span>
  ) : (
    <MinusOutlined />
  );
}
