import React, { useCallback, useMemo } from 'react';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import DeleteRecordButton from 'components/deleteRecordButton';
import {
  IAssigneeDriver,
  useAssigneeDrivers,
  useDeleteAssigneeDriver,
} from 'services/AssigneeService';
import { ColumnProps } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { Alert, Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { AssigneeDriverModal } from './assignee-driver-modal';

const { confirm } = Modal;

const AssignedDrivers: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const urlParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const {
    data: drivers,
    isLoading: isLoadingAssigneeDrivers,
    refetch: refetchAssigneeDrivers,
  } = useAssigneeDrivers();

  const { mutate: deleteAssigneeDriver } = useDeleteAssigneeDriver();

  const query = useMemo(() => {
    return {
      page: urlParams.get('page') ? Number(urlParams.get('page')) : undefined,
      size: urlParams.get('size') ? Number(urlParams.get('size')) : undefined,
      search: urlParams.get('search'),
      addDriver: urlParams.get('addDriver')
        ? Boolean(urlParams.get('addDriver'))
        : false,
    };
  }, [urlParams]);

  const nameColumn: ColumnProps<IAssigneeDriver> = {
    title: 'Driver',
    render: (_, record) => record.name,
  };

  const storeColumn: ColumnProps<IAssigneeDriver> = {
    title: 'Tienda',
    render: (_, record) => record.assignedStore.name,
  };

  const editZoneColumn: ColumnProps<IAssigneeDriver> = {
    align: 'center',
    title: 'Des-asignar',
    render: (_, record) => (
      <DeleteRecordButton
        onClick={() => {
          confirm({
            title: '¿Estás seguro de des-asignar este driver?',
            content: 'Esta accion no se puede deshacer',
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
              await new Promise((resolve, rej) => {
                deleteAssigneeDriver(record.driverId, {
                  onError: (error) => {
                    notification.error({
                      message: 'Error al des-asignar driver',
                      description: error.message,
                    });
                    rej(error);
                  },
                  onSuccess: (data) => {
                    notification.success({
                      message: 'Driver des-asignado',
                    });
                    resolve(data);
                  },
                });
              });
            },
          });
        }}
      />
    ),
  };

  const dataColumns = [nameColumn, storeColumn];
  const columnsLargeDevice = [...dataColumns, editZoneColumn];
  const columnsMediumDevice = [nameColumn, storeColumn, editZoneColumn];
  const columnsSmallDevice = [nameColumn, editZoneColumn];

  const searchCallback = useCallback(
    (searchText: string) => {
      const params = new URLSearchParams(search);
      if (searchText) {
        params.set('search', searchText);
      } else {
        params.delete('search');
      }
      history.replace({
        search: params.toString(),
      });
    },
    [history, search],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Nombre',
    allowEmptySearch: true,
  };

  const handleOnPaginate = useCallback(
    (pagination: { pageNumber: number; pageSize: number }) => {
      const params = new URLSearchParams(search);
      params.set('page', String(pagination.pageNumber));
      params.set('size', String(pagination.pageSize));
      history.push({
        search: params.toString(),
      });
    },
    [history, search],
  );

  const paginate = useMemo(() => {
    return {
      pageNumber: query.page || 1,
      pageSize: query.size || 10,
      totalElements: drivers?.length || 0,
    };
  }, [query.page, query.size, drivers]);

  const driversFiltered = useMemo(() => {
    if (!drivers) {
      return [];
    }

    return drivers.filter((driver) => {
      return (
        driver.name.includes(query.search || '') ||
        driver.assignedStore.name.includes(query.search || '')
      );
    });
  }, [drivers, query.search]);

  const handleRefresh = useCallback(() => {
    refetchAssigneeDrivers();
  }, [refetchAssigneeDrivers]);

  const handleAddDriver = useCallback(() => {
    const params = new URLSearchParams(search);
    params.set('addDriver', 'true');
    history.push({
      search: params.toString(),
    });
  }, [history, search]);

  const handleCloseAddDriver = useCallback(() => {
    const params = new URLSearchParams(search);
    params.delete('addDriver');
    history.push({
      search: params.toString(),
    });
  }, [history, search]);

  return (
    <>
      <Alert
        message="Esta funcionalidad se encuentra en etapa experimental"
        type="info"
        showIcon
        style={{
          marginBottom: '10px',
        }}
      />
      <PaqueryTable
        loading={isLoadingAssigneeDrivers}
        header={{
          title: 'Drivers asignados',
          searcher,
          refresh: handleRefresh,
          primaryButton: (
            <TableHeader.PrimaryButton
              title="Crear asignado"
              onClick={handleAddDriver}
            />
          ),
        }}
        onChangePaginate={handleOnPaginate}
        dataSource={driversFiltered}
        paginate={paginate || DEFAULT_PAGINATE}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
      <AssigneeDriverModal
        handleClose={handleCloseAddDriver}
        open={query.addDriver}
      />
    </>
  );
};

export default AssignedDrivers;
