/* eslint-disable @typescript-eslint/no-explicit-any */
import { SendOutlined, TruckOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib';
import Card from 'components/card';
import SITELINKS from 'constants/sitelinks';
import { useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import LastMileRatesList from './components/lastMile';
import InterUrbanRatesList from './components/interUrban';

type MenuItem = Required<MenuProps>['items'][number];

const RatesList = () => {
  const location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(SITELINKS.rates.lastMile);

  const items: MenuItem[] = [
    {
      label: 'Última milla',
      type: 'item',
      key: SITELINKS.rates.lastMile,
      icon: <SendOutlined />,
    },
    {
      label: 'Inter-urbano',
      type: 'item',
      key: SITELINKS.rates.interUrban,
      icon: <TruckOutlined />,
    },
  ];

  const handleSelection: MenuProps['onClick'] = (e) => {
    setSelected(e.key);
    history.push(e.key);
  };

  return (
    <>
      <Card
        bodyStyle={{ padding: '0', overflow: 'hidden', marginBottom: '16px' }}
      >
        <Menu
          items={items}
          onClick={handleSelection}
          selectedKeys={[selected]}
          mode="horizontal"
          style={{ width: '100%' }}
        />
      </Card>
      <Switch location={location}>
        <Route
          path={SITELINKS.rates.lastMile}
          render={(props: any) => <LastMileRatesList {...props} />}
        />
        <Route
          path={SITELINKS.rates.interUrban}
          render={(props: any) => <InterUrbanRatesList {...props} />}
        />
      </Switch>
    </>
  );
};

export default RatesList;
