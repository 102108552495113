import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectMassiveViewPackages = (state) => state[name] || initialState;

export const itemsSelector = (state) => state[name].items;

export const searchSelector = (state) => state[name].search;

export const dateSelector = (state) => state[name].date;

export const typeSelector = (state) => state[name].type;

export const statusSelector = (state) => state[name].status;

export const marketFilterSelector = (state) => state[name].marketplaceId;

export const loadedSelector = (state) => state[name].loaded;

export const pageableSelector = (state) => state[name].pageable;

const makeSelectMassiveViewPackages = () =>
  createSelector(selectMassiveViewPackages, (substate) => substate);

export default makeSelectMassiveViewPackages;
