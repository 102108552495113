import { createUseStyles } from 'react-jss';

const center = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default createUseStyles((theme) => {
  return {
    center,
    modalHeaderFooter: {
      ...center,
      height: 45,
      marginBottom: '3vh',
      background: theme.colorPrimary,
      color: theme.colorBgBase,
      fontSize: '1.3rem',
      padding: 4,
      fontWeight: 'bold',
      borderRadius: '5px',
    },
    modalButtonContainer: {
      marginTop: '3vh',
      width: '100%',
    },
    modalButton: {
      width: '100%',
      background: theme.colorPrimary,
      color: theme.colorBgBase,
      borderWidth: 2,
    },
  };
});
