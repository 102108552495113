import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, notification } from 'antd';
import API from 'constants/api';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { downloadByRequest } from 'util/Tools';

const DownloadLabelButton = ({ record }) => {
  const handleClick = () => {
    if (record.successCount > 0) {
      downloadByRequest(
        API.packages.labelByMassive(record.code),
        `Etiqueta-${record.code}.pdf`,
      );
    } else {
      notification.info({
        message: 'Atención',
        description:
          'La alta masiva no se encuentra con paquetes creados correctamente. Por lo que no se imprime recibo',
      });
    }
  };
  return (
    <Tooltip title="Imprimir etiqueta">
      <Button
        type="primary"
        onClick={handleClick}
        style={{
          padding: '0px 8px',
        }}
      >
        <VerticalAlignBottomOutlined />
      </Button>
    </Tooltip>
  );
};

DownloadLabelButton.propTypes = {
  record: PropTypes.shape({
    successCount: PropTypes.number,
    code: PropTypes.string,
  }).isRequired,
};

export default DownloadLabelButton;
