import React, { useEffect, useMemo, useState } from 'react';
import { Button, Space, Tag, Input, Popconfirm, Tooltip } from 'antd';
import Card from 'components/card';
import { UndoOutlined } from '@ant-design/icons';
import { useZones } from 'services/ZoneService';

interface ZipCodeTagProps {
  zipCode: string;
  removeCallback: (args: any) => void;
}

interface ZipCodeSelectorProps {
  initialZipCodes?: string[];
  selectedZipCodes: string[];
  setZipCodes: (args: any) => void;
}

function ZipCodeTag({ zipCode, removeCallback }: ZipCodeTagProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popconfirm
      open={isOpen}
      title="Confirmar eliminación"
      description="¿Seguro que desea eliminar este código postal?"
      onConfirm={() => {
        removeCallback(zipCode);
        setIsOpen(false);
      }}
      onCancel={() => setIsOpen(false)}
      okText="Yes"
      cancelText="No"
    >
      <Tag
        key={zipCode}
        closable
        style={{ marginBottom: '4px' }}
        onClose={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        {zipCode}
      </Tag>
    </Popconfirm>
  );
}

export const ZipCodeSelector = ({
  initialZipCodes,
  selectedZipCodes,
  setZipCodes,
}: ZipCodeSelectorProps) => {
  const [inputZipCode, setInputZipCode] = useState('');
  const { zipCodes: codes } = useZones();

  function loadZipCodes() {
    if (initialZipCodes) {
      setZipCodes(initialZipCodes);
    }
    setInputZipCode('');
  }
  useEffect(loadZipCodes, [initialZipCodes]);

  const isUsed = useMemo(
    () =>
      codes.includes(inputZipCode) || selectedZipCodes.includes(inputZipCode),
    [codes, selectedZipCodes, inputZipCode],
  );

  function addZipCode(zc: string) {
    setZipCodes((prev: string[]) => {
      const _zipCodes = [...prev];
      _zipCodes.push(zc);
      return _zipCodes.sort((a, b) => a.localeCompare(b));
    });
    setInputZipCode('');
  }

  function removeZipCode(zc: string) {
    setZipCodes((prev: string[]) => prev.filter((_zc) => _zc != zc));
  }

  return (
    <Card title="Códigos postales" style={{ marginTop: '16px', height: '60%' }}>
      <Space.Compact style={{ width: '100%', marginBottom: '16px' }}>
        <Input
          value={inputZipCode}
          onPressEnter={() => {
            if (!isUsed) addZipCode(inputZipCode);
          }}
          placeholder="Ingrese un código postal"
          onChange={(e) => setInputZipCode(e.target.value)}
        />
        <Tooltip
          open={isUsed}
          title={isUsed ? 'Este código postal ya está en uso' : ''}
        >
          <Button
            disabled={isUsed || inputZipCode == ''}
            type="primary"
            onClick={() => addZipCode(inputZipCode)}
          >
            Agregar
          </Button>
        </Tooltip>
        {!!initialZipCodes && (
          <Popconfirm
            title="Confirmar deshacer"
            description="¿Seguro que desea deshacer los cambios?"
            onConfirm={loadZipCodes}
            okText="Si"
            cancelText="No"
          >
            <Button type="primary" style={{ background: 'orange' }}>
              <UndoOutlined style={{ fontSize: '18' }} />
            </Button>
          </Popconfirm>
        )}
      </Space.Compact>
      {selectedZipCodes &&
        !!selectedZipCodes.length &&
        selectedZipCodes.length > 0 &&
        selectedZipCodes.map((zc) => (
          <ZipCodeTag key={zc} zipCode={zc} removeCallback={removeZipCode} />
        ))}
    </Card>
  );
};
