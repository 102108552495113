import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { theme } from 'antd';

const ViewPackageButton = ({ packageId }) => {
  const { token } = theme.useToken();
  return (
    <Link to={`/packages/view/${packageId}`}>
      <EyeOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
    </Link>
  );
};

ViewPackageButton.propTypes = {
  packageId: PropTypes.string.isRequired,
};

export default ViewPackageButton;
