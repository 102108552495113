import { createSlice } from '@reduxjs/toolkit';

// Aclaracion: Los paquerypoints que nos traemos en este reducer no son los
// mismos que los del reducer de paquerypoint. Este reducer trae todos los
// paquerypoints sin discriminar operacion logistica, el reducer exclusivo
// de paquerypoints trae unicamente por opl logueado.
export const initialState = {
  items: [],
  search: '',
  pageable: {
    pageNumber: 0,
    pageSize: 10,
  },
  loaded: false,
  paquerypointsLoaded: true,
  paquerypoints: [],
};

const packagePpointDepartureSlice = createSlice({
  name: 'packagePpointDeparture',
  initialState,
  reducers: {
    initial: () => {},
    success: (state, action) => {
      state.loaded = true;
      state.items = action.payload;
    },
    failure: (state) => {
      state.loaded = false;
    },
    dispatchPackages: (state) => {
      state.loaded = false;
    },
    dispatchPackagesSuccess: (state) => {
      state.loaded = true;
    },
    removePacketSuccess: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    updatePackets: (state, action) => {
      state.items = action.payload;
    },
    addPackage: (state, action) => {
      state.items.unshift(action.payload);
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    reset: (state) => {
      state.items = initialState.items;
    },
    fetchPaquerypoints: (state) => {
      state.paquerypointsLoaded = false;
    },
    fetchPaquerypointsSuccess: (state, action) => {
      state.paquerypoints = action.payload;
      state.paquerypointsLoaded = true;
    },
    fetchPaquerypointsFailure: (state) => {
      state.paquerypointsLoaded = false;
    },
  },
});

export const { name, reducer, actions } = packagePpointDepartureSlice;
