import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useSelector, shallowEqual } from 'react-redux';
import { name, reducer } from './slice';
import makeUserview from './selectors';
import saga from './saga';

export default function useUserview() {
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useSelector(makeUserview(), shallowEqual);
  return null;
}
