import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePaquerypointView from 'redux/paquerypointView';
import { actions } from 'redux/paquerypointView/slice';
import { actions as paquerypointListActions } from 'redux/paquerypoint/slice';
import { paquerypointViewLoadingSelector } from 'redux/paquerypointView/selectors';
import {
  countriesGlobalsSelector,
  currentServicesGlobalsSelector,
  daysServiceGlobalsSelector,
} from 'redux/globals/selectors';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';

import rest, { getData } from 'util/Api';
import API from 'constants/api';
import * as Yup from 'yup';
import PaqueryPoint from '../paquerypoint';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Indique un nombre de paquery point'),
  address: Yup.string().required('Indique la dirección del paquery point'),
  phone: Yup.number()
    .required('El teléfono no puede ir vacío')
    .typeError('Solo deben ir numeros y el campo es requerido'),
  detail: Yup.string().required(
    'Es necesario indicar una descripción del paquery point',
  ),
  contactName: Yup.string().required(
    'Es necesario indicar un nombre de contacto',
  ),
  volume: Yup.number()
    .typeError('Deben ser números y son requeridos')
    .required('Es necesario indicar el volumen')
    .min(1, 'No puede ser 0'),
  currentServices: Yup.array().min(1, 'Es necesario indicar un servicio'),
  zones: Yup.array().min(1, 'Ingrese al menos una zona de trabajo'),
  daysOfWeek: Yup.array().min(1, 'Ingrese al menos un día de trabajo'),
});

const AddPaqueryPoint = () => {
  usePaquerypointView();
  const dispatch = useDispatch();
  const countries = useSelector(countriesGlobalsSelector);
  const daysOfWeek = useSelector(daysServiceGlobalsSelector);
  const currentServices = useSelector(currentServicesGlobalsSelector);
  const loading = useSelector(paquerypointViewLoadingSelector);
  const [currentCountry, setCurrentCountry] = useState(1);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const getCities = async () => {
      dispatch(actions.loading());
      const citiesResponse = await getData(
        `${API.globals.cities}/${currentCountry}`,
      );
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(null);
      }
      dispatch(actions.loaded());
    };
    getCities();
  }, [currentCountry, dispatch]);

  const handleSubmit = async (values) => {
    const selectedCity = cities.find((city) => city.id === values.cityID);
    const selectedCountry = countries.find(
      (country) => country.id === values.countryID,
    );
    const payload = {
      active: values.active,
      contactName: values.contactName,
      country: values.countryID,
      currentServices: values.currentServices.map((service) => service.value),
      detail: values.detail,
      locationAddress: {
        address: values.address,
        city: {
          dialingCode: null,
          externalPlaceID: null,
          externalRefernceID: null,
          geoKey: '',
          id: selectedCity.id,
          name: selectedCity.name,
          twoLetterISOCode: selectedCity,
        },
        cityID: values.cityID,
        country: {
          dialingCode: selectedCountry.dialingCode,
          geoKey: '',
          id: selectedCountry.id,
          name: selectedCountry.name,
          twoLetterISOCode: selectedCountry.twoLetterISOCode,
        },
        countryID: values.countryID,
        postalCode: values.postalCode,
      },
      name: values.name,
      openBranchOffice: values.openBranchOffice,
      phone: values.phone,
      published: values.published,
      private: values.private,
      volume: values.volume,
      workingScheduleTimes: [
        {
          daysOfWeek: values.daysOfWeek.map((day) => day.value),
          finishHour: values.finishHour,
          initHour: values.initHour,
        },
      ],
      zones: values.zones,
      paqueryPoints: values.paqueryPoints,
    };
    dispatch(actions.loading());
    try {
      const response = await rest.post(API.paqueryPoint.create, payload);
      if (response.status === 400) {
        throw new Error(response.data.message);
      } else {
        dispatch(paquerypointListActions.refreshPage());
        notification.success({
          message: 'Alta de paquerypoint correcto',
          description: `El paquerypoint ${values.name} ha sido dado de alta correctamente.`,
        });
        history.push('/paquerypoints');
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al dar el alta de paquer${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    } finally {
      dispatch(actions.loaded());
    }
  };
  return (
    <PaqueryPoint
      loaded={!loading}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      daysOfWeek={daysOfWeek}
      currentServices={currentServices}
      countries={countries}
      setCurrentCountry={setCurrentCountry}
      cities={cities}
      currentCity={currentCity}
    />
  );
};

export default AddPaqueryPoint;
