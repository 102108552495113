import React, { useEffect, useState, useCallback } from 'react';
import rest from 'util/Api';
import JsDownload from 'js-file-download';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import usePackageWithoutZones from 'redux/packagesWithoutZones';
import useMarketplaces from 'redux/marketplaces';
import { actions } from 'redux/packagesWithoutZones/slice';
import { actions as paquerActions } from 'redux/paquers/slice';
import {
  packagesWithoutZoneItemsSelector,
  packagesWithoutZoneDateSelector,
  packagesWithoutZoneLoadedSelector,
  packagesWithoutZoneSearchSelector,
  packagesWithoutZoneStatusSelector,
  packagesWithoutZoneTypeSelector,
  packagesWithoutZoneMarketplaceSelector,
  packagesWithoutZonePaginationSelector,
} from 'redux/packagesWithoutZones/selectors';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import { ALL_STATUS_FILTERING } from 'constants/defaultValues';
import { readyForDeliveryStatus } from 'constants/packages';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import { notification } from 'antd';
import PaqueryTable from '@paquery-team/lib-table';
import ViewPackageButton from 'components/viewPackagebutton';
import DownloadLabelButton from 'components/downloadLabelButton';
import {
  useMarketplaceOptions,
  useOnTransitStatusOptions,
} from 'hooks/useOptions';
import externalCodeColumn from 'components/tables/columns';
import PaquerSettingsButton from 'components/paquerSettingsButton';
import usePaquers from 'redux/paquers';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';

const downloadLabelColumn = {
  render: (_, record) => (
    <DownloadLabelButton
      packageId={record.key}
      externalCode={record.externalCode}
    />
  ),
};

const destinationPersonNameColumn = {
  title: 'Nombre destinatario',
  dataIndex: 'destinationPersonName',
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTermDescription',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSizeDescription',
  align: 'center',
  render: (text) => <SizeIndicator text={text} />,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const paquerSettingsColumn = {
  align: 'center',
  render: (_, record) => (
    <PaquerSettingsButton packageId={record} isWithoutZone />
  ),
};

const dataColumns = [
  externalCodeColumn,
  destinationPersonNameColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  sizeColumn,
  arrivedAtPaqueryPointDateColumn,
  statusColumn,
];

const actionColumns = [paquerSettingsColumn, viewPackageColumn, editColumn];

const columnsLargeDevice = [
  downloadLabelColumn,
  ...dataColumns,
  ...actionColumns,
];

const columnsMediumDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  statusColumn,
  ...actionColumns,
];

const columnsSmallDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  ...actionColumns,
];

const PackageList = () => {
  usePackageWithoutZones();
  useMarketplaces({ initialize: true });
  usePaquers();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const pageable = useSelector(packagesWithoutZonePaginationSelector);
  const items = useSelector(packagesWithoutZoneItemsSelector);
  const loaded = useSelector(packagesWithoutZoneLoadedSelector);
  const status = useSelector(packagesWithoutZoneStatusSelector);
  const search = useSelector(packagesWithoutZoneSearchSelector);
  const date = useSelector(packagesWithoutZoneDateSelector);
  const marketFilter = useSelector(packagesWithoutZoneMarketplaceSelector);
  const packageType = useSelector(packagesWithoutZoneTypeSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const [disabled, setDisabled] = useState(false);
  const [selectedPackagesId, setSelectedPackagesId] = useState([]);
  const filteredStatus = [
    ...useOnTransitStatusOptions(),
    { name: 'Todos', value: ALL_STATUS_FILTERING },
  ];

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const searchParams = new URLSearchParams({
        search,
        status: status === ALL_STATUS_FILTERING ? '' : status,
        from: date.from,
        to: date.to,
        packageType: packageType || '',
        marketplaceId: marketFilter || '',
      });
      // eslint-disable-next-line import/no-named-as-default-member
      const response = await rest.get(
        `${API.reports.withoutZone}?${searchParams.toString()}`,
        {
          responseType: 'blob',
        },
      );
      const filename = `Paquetes-Sin-Zona-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(response.data, filename);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const rowSelection = {
    type: 'checkbox',
    columnWidth: '30px',
    hideDefaultSelections: true,
    selectedRowKeys: selectedPackagesId,
    onChange: (selectedRowKeys) => {
      setSelectedPackagesId(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: !record.isDeliverable,
      name: record.externalCode,
    }),
  };

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePagination(page));
    },
    [dispatch],
  );
  const dateSelectionCallback = useCallback(
    (newDate) => {
      dispatch(actions.updateDate(newDate));
    },
    [dispatch],
  );
  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectCallback = useCallback(
    (newStatus) => {
      dispatch(actions.updateStatus(newStatus));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarketplace(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );
  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: filteredStatus,
    },
  ];
  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationPersonName: packet.shippingScheduleDestination.name,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTermDescription: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      packageSizeDescription: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      isDeliverable: readyForDeliveryStatus.some((id) => id === packet.status),
    }));
  }
  return (
    <PaqueryTable
      rowSelection={rowSelection}
      loading={!loaded}
      header={{
        title: 'Sin zona',
        selectors,
        searcher,
        rangePicker: {
          onDateSelection: dateSelectionCallback,
          required: {
            value: true,
            message: 'La fecha es requerida',
          },
        },
        onExportCsv: { callback: handleExportCsv, disabled },
        refresh: () => dispatch(actions.refreshPage()),
      }}
      usePackageRowColors
      onChangePaginate={updatePaginate}
      dataSource={filteredPackages}
      paginate={pageable}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default PackageList;
