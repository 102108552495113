import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useEffect } from 'react';

import { reducer, name, actions } from './slice';
import saga from './saga';
import makeStoreSelector from './selectors';

export default function useStore() {
  const dispatch = useDispatch();
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useSelector(makeStoreSelector(), shallowEqual);
  useEffect(() => {
    return () => {
      dispatch(actions.resetFilters());
      dispatch(actions.clearItems());
    };
  }, [dispatch]);
  return null;
}
