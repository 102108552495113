/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect } from 'react';
import EntryPacketView, { ERROR_STATUS } from '@paquery-team/lib-arrive-packet';
import { useDebounce } from 'util/Hooks';
import { Button, Card, theme } from 'antd';
import useMarketplaces from 'redux/marketplaces';
import PackageSelectModal from 'components/packageSelectModal';
import QRScanner from 'components/QRScanner';
import PackageService, {
  ArrivedPackage,
  FoundPackage,
} from 'services/PackageService';

const WAIT_INTERVAL = 600;

const EntryPacket = () => {
  useMarketplaces();
  const [packetResponse, setPacketResponse] = useState<
    | {
        status: number;
        message: string;
        title?: string;
      }
    | ArrivedPackage
    | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [externalCode, setExternalCode] = useState('');
  const [packageOptions, setPackageOptions] = useState<any[]>([]);
  const [packageModal, setPackageModal] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const inputExternalCode = useRef<HTMLInputElement | null>(null);
  const debouncedExternalCode = useDebounce(externalCode, WAIT_INTERVAL);
  const { token } = theme.useToken();

  const handleScanQR = (data: string | null) => {
    if (data) {
      setExternalCode(data);
      setShowCamera(false);
    }
  };

  const arriveFoundPackage = async (_package: FoundPackage) => {
    setLoading(true);
    const { id } = _package;
    try {
      await PackageService.arrivePacketByID(id);
      // Se muestra una alerta de que el paquete ya fue arribado, usando el codigo -2 que es el codigo de exito.
      setPacketResponse({
        ..._package,
        status: -2,
        title: _package.isBundle ? 'Bulto arribado' : 'Arribado',
      });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      if (error.isAxiosError) {
        setPacketResponse({
          status: ERROR_STATUS,
          message: error.response.data.message,
        });
        return;
      }
      setPacketResponse({ status: ERROR_STATUS, message: error.message });
    } finally {
      // TODO: no se limpia bien el input
      setLoading(false);
      setPackageOptions([]);
      setPackageModal(false);
      if (inputExternalCode.current) {
        inputExternalCode.current.focus();
        inputExternalCode.current.select();
        inputExternalCode.current.value = '';
      }
    }
  };

  const arrivePacket = async (externalCode: string) => {
    try {
      const response =
        await PackageService.arrivePacketByExternalCode(externalCode);
      // Si se arribo el paquete
      if (response.data.arrived) {
        // Se muestra una alerta de que el paquete ya fue arribado, usando el codigo -2 que es el codigo de exito.
        setPacketResponse({
          ...response.data.arrived,
          status: -2,
          title: response.data.arrived.isBundle ? 'Bulto arribado' : 'Arribado',
          message: response.data.message || '',
        });
        return;
      }
      // Si se encontraron mas paquetes con el mismo codigo externo
      if (
        response.data.foundPackages &&
        response.data.foundPackages.length > 1
      ) {
        const suggestedPackageOptions = response.data.foundPackages.map(
          (packet: FoundPackage) => {
            return {
              ...packet,
              marketplace: packet.marketplace?.name,
              destinationAddress:
                packet.shippingScheduleDestination.shippingAddress
                  .addressDetail,
            };
          },
        );
        setPackageOptions(suggestedPackageOptions);
        setPackageModal(true);
        return;
      }

      throw new Error(response.data.message || 'Algo salió mal...');
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log({ error });
      if (error.isAxiosError) {
        setPacketResponse({
          status: ERROR_STATUS,
          message: error.response.data.message,
        });
        return;
      }
      setPacketResponse({ status: ERROR_STATUS, message: error.message });
    } finally {
      // TODO: no se limpia bien el input
      setLoading(false);
      if (inputExternalCode.current) {
        inputExternalCode.current.focus();
        inputExternalCode.current.select();
        inputExternalCode.current.value = '';
      }
    }
  };

  // // es un paquete PickUp que esta disponible para retiro?
  // const isPacketPickupDeliver = (packet: any) => {
  //   return packet.status === 8 && packet.packageType === 3;
  // };

  // // entregar un paquete pickup (Debe mostrarse un modal para confirmar la entrega)
  // const handleDeliverPickupPacket = (packet: any) => {
  //   confirm({
  //     title: `¿Estas seguro que deseas entregar el paquete?`,
  //     content: `Estas por entregar el paquete ${packet.externalCode}`,
  //     onOk: () => arrivePacket(packet.externalCode),
  //     okText: 'Entregar',
  //     onCancel: () => {
  //       setPackageOptions([]);
  //       setLoading(false);
  //     },
  //   });
  // };

  useEffect(() => {
    const handleSubmit = async (extCode: string | null | undefined) => {
      setPacketResponse(null);
      if (!extCode || extCode === '') {
        return;
      }
      setLoading(true);
      let receivedExternalCode = extCode.trim();
      // Check if the typed extCode is a scanned QR
      try {
        const qrCode = JSON.parse(extCode.trim());
        if (qrCode.id) {
          receivedExternalCode = qrCode.id;
        }
      } catch (e) {
        // not QR and it's okay
      }
      arrivePacket(receivedExternalCode);
    };
    handleSubmit(debouncedExternalCode);
  }, [debouncedExternalCode]);

  const handlePackageSelection = (_package: FoundPackage) => {
    arriveFoundPackage(_package);
  };

  return (
    <Card>
      <PackageSelectModal
        visible={packageModal}
        packages={packageOptions}
        handlePackageSelection={handlePackageSelection}
        handleCloseModal={() => {
          setPackageModal(false);
          setPackageOptions([]);
          setLoading(false);
          if (inputExternalCode.current) {
            inputExternalCode.current.focus();
            inputExternalCode.current.select();
            inputExternalCode.current.value = '';
          }
        }}
        disabled={{ statusDescription: true }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            paddingTop: '3vh',
            justifySelf: 'center',
            color: token.colorPrimary,
          }}
          type="link"
          onClick={() => setShowCamera(true)}
        >
          Escanear QR
        </Button>
      </div>
      <EntryPacketView
        inputExternalCode={inputExternalCode}
        onChange={(event) => setExternalCode(event.target.value)}
        loading={loading}
        packetResponse={packetResponse}
        title="Arribar Paquetes"
      />
      <QRScanner
        onScan={handleScanQR}
        showCamera={showCamera}
        setShowCamera={setShowCamera}
      />
    </Card>
  );
};

export default EntryPacket;
