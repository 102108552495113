import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: [],
  loaded: true,
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  search: '',
  marketplaceId: '',
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    all: (state, action) => {
      state.loaded = false;
      state.marketplaceId = action.payload;
      state.pageable.pageSize = 1000;
      state.pageable.pageNumber = 0;
    },
    success: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    failure: (state) => {
      state.loaded = true;
    },
    loading: (state) => {
      state.loaded = false;
    },
    loaded: (state) => {
      state.loaded = true;
    },
    updateMarketplace: (state, action) => {
      state.marketplaceId = action.payload;
      state.pageable.pageNumber = initialState.pageable.pageNumber;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
      state.pageable.pageNumber = initialState.pageable.pageNumber;
    },
    updatePageable: (state, action) => {
      state.pageable.pageSize = action.payload.pageSize;
      state.pageable.pageNumber = action.payload.pageNumber;
    },
    delete: (state) => {
      state.loaded = false;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = storeSlice;
