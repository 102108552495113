import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { selectDepartureHistory } from './selectors';
import { actions } from './slice';

function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      searchExternalCode,
      date,
      origin,
      destination,
      departureType,
    } = yield select(selectDepartureHistory);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      search: searchExternalCode,
      originId: origin,
      destinationId: destination,
      dispatchType: departureType,
      from: date.from,
      to: date.to,
    });
    const departures = yield call(
      getData,
      `${API.departures.getAll}?${searchParams.toString()}`,
    );
    const payload = {
      items: departures.content,
      total: departures.totalElements,
    };
    yield put({ type: actions.success.type, payload });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* updateBasefilters() {
  const { date } = yield select(selectDepartureHistory);
  if (!haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetch.type,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      [
        actions.setPageable,
        actions.setDate,
        actions.setOrigin,
        actions.setDestination,
        actions.setDepartureType,
        actions.setSearchByExternalCode,
        actions.refreshPage,
        actions.resetFilters,
      ],
      updateBasefilters,
    ),
    takeEvery(actions.fetch, fetchSaga),
  ]);
}
