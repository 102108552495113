const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const msApiUrl = process.env.REACT_APP_MS_API_URL;
const cdnHost = process.env.REACT_APP_CDN_HOST;

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

export default {
  auth: {
    login: `${process.env.REACT_APP_API_URL}/apptoken`,
    recover: `${baseUrl}/customeradmin/sendrecoverypassword?Email=`,
    confirm: `${baseUrl}/customeradmin/recoverypassword`,
  },
  profile: {
    load: `${baseUrl}/profile/load`,
  },
  globals: {
    packageType: `${msApiUrl}/packages/parametrics/packageType`,
    packageSize: `${msApiUrl}/packages/parametrics/packageSize`,
    packageStatus: `${msApiUrl}/packages/parametrics/packageStatus`,
    deliveryPackageType: `${msApiUrl}/packages/parametrics/deliveryPackageType`,
    deliveryTerm: `${msApiUrl}/packages/parametrics/deliveryTerm`,
    notifierType: `${msApiUrl}/mkp/parametrics/notifierType`,
    countries: `${baseUrl}/geolocation/loadcountries`,
    cities: `${baseUrl}/geolocation/loadcities`,
    roles: `${baseUrl}/apptype/userrole`,
    logisticOperator: `${msApiUrl}/opl/logisticoperator?page=0&size=1000`,
    userType: `${baseUrl}/apptype/usertype`,
    daysOfWeek: `${msApiUrl}/pqpoint/paquerypoint/daysOfWeek`,
    currentServices: `${msApiUrl}/pqpoint/paquerypoint/currentServices`,
    dispatchTypes: `${msApiUrl}/packages/parametrics/dispatchType`,
  },
  packages: {
    get: `${msApiUrl}/packages/packages`,
    getById: `${msApiUrl}/packages/packages/`,
    withoutZone: `${msApiUrl}/packages/packages/withoutzone`,
    send: `${msApiUrl}/packages/packages`,
    label: (id) => `${msApiUrl}/labels/packages/${id}`,
    labelByMassive: (code) => `${msApiUrl}/labels/packages/massive/${code}`,
    history: `${msApiUrl}/packages/packages/history`,
    getPaquerHistoryCsv: `${msApiUrl}/packages/report/history`,
    updateStatus: `${msApiUrl}/packages/packages/searchArrived/`,
    assignPackages: `${msApiUrl}/packages/paquer/acceptpackages/`,
    getByExternalCode: `${msApiUrl}/packages/packages/findByExternalCode/`,
    getByExternalCodeFromArrivedOrDispatch: `${msApiUrl}/packages/packages/findByExternalCodeFromArrivedOrDispatch`,
    withPaquers: `${msApiUrl}/packages/packages/withPaquers`,
    getNormalByStoreId: (storeId) =>
      `${msApiUrl}/packages/packages/packagesByStoreId/${storeId}`,
    getHistoryByStoreId: (storeId) =>
      `${msApiUrl}/packages/packages/packagesHistoryByStoreId/${storeId}`,
    getByMassiveCode: `${msApiUrl}/packages/massive/packages/list`,
    isPackageBundle: `${msApiUrl}/packages/packages/isPackageBundle`,
    downloadDeliveryNoteById: (packageId) =>
      `${msApiUrl}/labels/packages/remittance/${packageId}`,
    arrivePacket: `${msApiUrl}/packages/packages/arrivals`,
    arrivePacketByID: (id) => `${msApiUrl}/packages/packages/arrive/id/${id}`,
  },
  assignee: {
    drivers: {
      getAll: `${msApiUrl}/assignee/driver`,
      create: `${msApiUrl}/assignee/driver`,
      deleteById: (id) => `${msApiUrl}/assignee/driver/${id}`,
    },
    routes: {
      downloadReportByRouteId: (routeId) =>
        `${msApiUrl}/assignee/route/${routeId}/report`,
      getById: (id) => `${msApiUrl}/assignee/route/${id}`,
      getAll: `${msApiUrl}/assignee/route`,
    },
  },
  departures: {
    create: `${msApiUrl}/packages/dispatch`,
    getAll: `${msApiUrl}/packages/dispatch`,
    getById: (dispatchId) =>
      `${msApiUrl}/packages/dispatch/packages/${dispatchId}`,
    receipt: (dispatchId) =>
      `${msApiUrl}/labels/dispatch/receipt/${dispatchId}`,
  },
  massive: {
    list: `${msApiUrl}/packages/massive`,
    packagesUpload: (userId) =>
      `${msApiUrl}/caronte/integration/${userId}/massive`,
    packagesDownload: `${cdnHost}/assets/bulk-import.xlsx`,
  },
  marketplaces: {
    get: `${msApiUrl}/mkp/marketplace`,
    getById: (mkpId) => `${msApiUrl}/mkp/marketplace/${mkpId}`,
    create: `${msApiUrl}/mkp/marketplace/create`,
    update: `${msApiUrl}/mkp/marketplace/update/`,
    delete: `${msApiUrl}/mkp/marketplace`,
  },
  stores: {
    getById: (id) => `${msApiUrl}/mkp/store/${id}`,
    create: `${msApiUrl}/mkp/store`,
    delete: `${msApiUrl}/mkp/store`,
    getByMarketplace: `${msApiUrl}/mkp/store/marketplace`,
  },
  paquer: {
    getAll: `${msApiUrl}/pqr/paquer`,
    get: `${msApiUrl}/pqr/paquer/`,
    getById: `${msApiUrl}/pqr/paquer/`,
    create: `${msApiUrl}/pqr/paquer/`,
    update: `${msApiUrl}/pqr/paquer/`,
    delete: `${msApiUrl}/pqr/paquer/`,
    getPackages: `${msApiUrl}/packages/packages/current/byDriver/`,
    history: `${msApiUrl}/packages/packages/byDriver/`,
    assign: `${msApiUrl}/packages/packages/searchRolled/`,
    unassign: `${msApiUrl}/packages/paquer/unassign/`,
  },
  paqueryPoint: {
    get: `${msApiUrl}/pqpoint/paquerypoint`,
    getByIds: `${msApiUrl}/pqpoint/paquerypoint/paquerypointbyids`,
    getAllUnrelatedToOpl: `${msApiUrl}/pqpoint/paquerypoint/availables`,
    create: `${msApiUrl}/pqpoint/paquerypoint`,
    update: `${msApiUrl}/pqpoint/paquerypoint`,
    getPackages: `${msApiUrl}/packages/packages/packagesByPaqueryPointId`,
    getHistory: `${msApiUrl}/packages/packages/packagesHistoryByPaqueryPointId`,
    assign: `${msApiUrl}/packages/packages/searchArrived/`,
  },
  reports: {
    history: `${msApiUrl}/reports/report/history`,
    historyByPaquer: `${msApiUrl}/reports/report/paquer/history`,
    packages: `${msApiUrl}/reports/report`,
    withoutZone: `${msApiUrl}/reports/report/withoutzone`,
    packagesOwnedByPaquer: `${msApiUrl}/reports/report/paquer`,
    packagesbyPaquer: `${msApiUrl}/reports/report/packagesWithPaquers`,
    withPaqueryPoint: `${msApiUrl}/reports/report/paqueryPoint`,
    historyWithPaqueryPoint: `${msApiUrl}/reports/report/paqueryPoint/history`,
    storePackages: `${msApiUrl}/reports/report/store`,
    storeHistoryPackages: `${msApiUrl}/reports/report/store/history`,
  },
  user: {
    update: `${baseUrl}/customeradmin/update`,
    changePassword: `${baseUrl}/customeradmin/sendrecoverypassword`,
    getAll: `${baseUrl}/customeradmin/getall/`,
    get: `${baseUrl}/customeradmin/get?id=`,
    delete: `${baseUrl}/customeradmin/delete?id=`,
    create: `${baseUrl}/customeradmin/create`,
  },
  zone: `${msApiUrl}/zones`,
  zones: {
    deleteById: (id) => `${msApiUrl}/zones/${id}`,
    getAllWithGeoJsonByLogisticOperator: (id) =>
      `${msApiUrl}/zones/logistics_operator/${id}/full`,
    getGroupingWithGeoJsonByLogisticOperator: (id) =>
      `${msApiUrl}/zones/logistics_operator/${id}/grouping`,
    getAllByLogisticOperator: (id) =>
      `${msApiUrl}/zones/logistics_operator/${id}`,
    resolve: `${msApiUrl}/mediator/resolveZone`,
    grouping: `${msApiUrl}/zones/groups`,
  },
  rates: {
    trunk: {
      root: `${msApiUrl}/rates/trunk`,
      byId: (rateId) => `${msApiUrl}/rates/trunk/${rateId}`,
      allByOpl: (oplId) => `${msApiUrl}/rates/trunk/logistic_operator/${oplId}`,
    },
    lastMile: {
      root: `${msApiUrl}/rates/last_mile`,
      byId: (rateId) => `${msApiUrl}/rates/last_mile/${rateId}`,
      allByOpl: (oplId) =>
        `${msApiUrl}/rates/last_mile/logistic_operator/${oplId}`,
    },
  },
  alert: `${msApiUrl}/fe/messages`,
  geocode: {
    get: 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json?',
  },
  operators: {
    getAll: `${msApiUrl}/opl/integration/opl/availables`,
  },
  whatsapp: {
    sendMessage: 'https://web.whatsapp.com/send?phone=',
  },
};
