import React from 'react';
import dayjs from 'dayjs';
import { PACKET_VALUE_THRESHOLD } from 'constants/defaultValues';

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text, record) => {
    // ¿Por que no usamos reactJSS aca?
    // Las columns estan fuera del componente, no se tiene acceso a classes.
    // Inicializar el hook de estilos por cada fila de la tabla es poco performante
    // Usamos estilos fijos en el src/index.css en esta ocasion
    // Alternativas:
    // - meter las columns dentro del componente
    // - hacer hook de columnas que inicialice estilos de reactjss
    let expiredPackage;
    let valuablePackage;
    const { scheduledDate } = dayjs(record.shippingScheduleDestination).tz();
    const { estimatedCost } = record;
    if (scheduledDate && dayjs().tz().isAfter(scheduledDate)) {
      expiredPackage = (
        <div
          className="expiredPackage tooltip-message"
          data-message="Vencido"
        />
      );
    }
    if (PACKET_VALUE_THRESHOLD && PACKET_VALUE_THRESHOLD > 0) {
      if (estimatedCost && estimatedCost > PACKET_VALUE_THRESHOLD) {
        valuablePackage = (
          <div
            className="valuablePackage tooltip-message"
            data-message={`Supera los $${PACKET_VALUE_THRESHOLD}`}
          >
            $
          </div>
        );
      }
    }
    return (
      <span style={{ position: 'relative', wordBreak: 'break-word' }}>
        <div className="packageNotificationContainer">
          {expiredPackage}
          {valuablePackage}
        </div>
        {text}
      </span>
    );
  },
};

export default externalCodeColumn;
