import Api from 'util/Api';
import API from 'constants/api';

export interface ArrivedPackage {
  externalCode: string;
  status: number;
  isBundle?: boolean;
  shippingScheduleDestination: {
    zone: {
      name: string | null | undefined;
    };
  };
}

export interface ArrivePacketResponse {
  data: {
    arrived?: ArrivedPackage;
    foundPackages?: FoundPackage[];
    message?: string;
  };
  message?: string;
}

export interface FoundPackage {
  id: number;
  externalCode: string;
  marketplace?: {
    name?: string | null | undefined;
  };
  packageType: number;
  packageSize: number;
  status: number;
  deliveryTerm: number;
  isBundle: boolean;
  shippingScheduleDestination: {
    shippingAddress: {
      addressDetail: string;
    };
    zone: {
      name: string | null | undefined;
    };
  };
}

/**
 * @description
 * Arriba un paquete por el codigo externo
 */
export const arrivePacketByExternalCode = async (
  externalCode: string | null | undefined,
) => {
  try {
    const searchParams = new URLSearchParams({
      searchArrived: externalCode as string,
    });
    const response = await Api.apiAxios.put(
      `${API.packages.arrivePacket}?${searchParams.toString()}`,
    );

    if (!Api.isSuccessResponse(response)) {
      throw new Error(response.data.message);
    }

    const arriveResponse: ArrivePacketResponse = response.data;

    return arriveResponse;
  } catch (error: any) {
    if (error.isAxiosError) {
      throw error;
    }
    throw new Error(error.message);
  }
};

/**
 * @description
 * Arriba un paquete por el id exacto (id de base)
 */
export const arrivePacketByID = async (id: number) => {
  try {
    const response = await Api.apiAxios.put(
      `${API.packages.arrivePacketByID(id)}`,
    );
    if (!Api.isSuccessResponse(response)) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error: any) {
    if (error.isAxiosError) {
      throw error;
    }
    throw new Error(error.message);
  }
};

export const getPackage = async (id: string): Promise<any> => {
  const response = await Api.apiAxios.get(`${API.packages.getById}${id}`);
  const routes = response.data.data;

  return routes;
};

const PackageService = {
  arrivePacketByExternalCode,
  arrivePacketByID,
  getPackage,
};

export default PackageService;
