export const DEPARTURE_TYPE_OPTIONS = Object.freeze({
  INTERIOR: 10,
  PAQUERYPOINT: 20,
  MARKETPLACE: 30,
});

export const INTERIOR_DESTINATIONS_OPTIONS = [
  {
    id: 27,
    name: 'Andesmar Cargas',
  },
];

export const OTHER_NOTIFIER_TYPE = Object.freeze({ label: 'Otra', value: 0 });
