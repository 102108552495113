import { createSelector } from 'reselect';
import { name, initialState } from './slice';

const paquerviewSelector = (state) => state[name] || initialState;

export const paquerviewDriverIDSelector = (state) => state[name].driverId;

export const paquerviewAvatarSelector = (state) => state[name].avatar;

export const paquerviewLoadingSelector = (state) => state[name].isLoading;

export const paquerviewPackagesSelector = (state) => state[name].packages;

export const paquerviewPackagesItemsSelector = (state) =>
  state[name].packages.items;

export const paquerviewPackagesPageableSelector = (state) =>
  state[name].packages.pageable;

export const paquerviewPackagesLoadedSelector = (state) =>
  state[name].packages.loaded;

export const paquerviewPackagesSearchSelector = (state) =>
  state[name].packages.search;

export const paquerviewPackagesTypeSelector = (state) =>
  state[name].packages.type;

export const paquerviewPackagesStatusSelector = (state) =>
  state[name].packages.status;

export const paquerviewPackagesMarketSelector = (state) =>
  state[name].packages.marketplaceId;

export const paqueryviewPackagesDateSelector = (state) =>
  state[name].packages.date;

export const paquerviewPackagesHistoryPaginateSelector = (state) =>
  state[name].history.pageable;

export const paquerviewPackagesHistoryLoadedSelector = (state) =>
  state[name].history.loaded;

export const paqueryviewPackagesHistorySelector = (state) =>
  state[name].history;

export const paqueryviewPackagesHistoryItemsSelector = (state) =>
  state[name].history.items;

export const paqueryviewPackagesHistoryDateSelector = (state) =>
  state[name].history.date;

export const paqueryviewPackagesHistorySearchSelector = (state) =>
  state[name].history.search;

export const paqueryviewPackagesHistoryTypeSelector = (state) =>
  state[name].history.type;

export const paqueryviewPackagesHistoryStatusSelector = (state) =>
  state[name].history.status;

export const paqueryviewPackagesHistoryMarketSelector = (state) =>
  state[name].history.marketplaceId;

const makePaquerviewSelector = () =>
  createSelector(paquerviewSelector, (substate) => substate);

export default makePaquerviewSelector;
