import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import rest, { getData } from 'util/Api';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { actions } from './slice';
import { paquerypointViewSelector } from './selectors';

function* fetchPaquerypoint({ payload: paquerypointId }) {
  try {
    const response = yield call(
      rest.get,
      `${API.paqueryPoint.get}/${paquerypointId}/?fullProjection=true`,
    );
    if (rest.isSuccessResponse(response)) {
      yield put({
        type: actions.fetchPaquerypointSuccess.type,
        payload: response.data.data,
      });
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    yield put({ type: actions.fetchPaquerypointFailure.type });
  }
}

function* fetchPackagesSaga() {
  try {
    const {
      paquerypoint: { id: paquerypointId },
      packages: {
        pageable: { pageNumber, pageSize },
        search,
        status,
        type,
        date,
        marketplaceId,
      },
    } = yield select(paquerypointViewSelector);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: true,
      packageType: type,
      marketplaceId: marketplaceId || '',
      status,
      search,
      from: date.from,
      to: date.to,
    });
    const packages = yield call(
      getData,
      `${
        API.paqueryPoint.getPackages
      }/${paquerypointId}?${searchParams.toString()}`,
    );
    yield put({
      type: actions.fetchPackagesSuccess.type,
      payload: {
        items: packages.content,
        total: packages.totalElements,
      },
    });
  } catch (error) {
    yield put({ type: actions.fetchPackagesFailure.type });
  }
}

function* fetchPackagesHistorySaga() {
  try {
    const {
      paquerypoint: { id: paquerypointId },
      history: {
        pageable: { pageNumber, pageSize },
        search,
        status,
        type,
        date,
        marketplaceId,
      },
    } = yield select(paquerypointViewSelector);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: true,
      packageType: type,
      marketplaceId: marketplaceId || '',
      status,
      search,
      from: date.from,
      to: date.to,
    });
    const packages = yield call(
      getData,
      `${
        API.paqueryPoint.getHistory
      }/${paquerypointId}?${searchParams.toString()}`,
    );
    yield put({
      type: actions.fetchHistorySuccess.type,
      payload: {
        items: packages.content,
        total: packages.totalElements,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('function*fetchPackagesHistorySaga -> error', error);
    yield put({ type: actions.fetchHistoryFailure.type });
  }
}

function* updateBasefilters() {
  const {
    packages: { status, date },
  } = yield select(paquerypointViewSelector);
  if (status === null || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetchPackages.type,
  });
}

function* updateBasefiltersHistory() {
  const {
    history: { status, date },
  } = yield select(paquerypointViewSelector);
  if (status === null || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetchHistory.type,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updateDate,
        actions.updatePaginationPackages,
        actions.updateSearchPackages,
        actions.updateSortPackages,
        actions.updateStatusPackages,
        actions.refreshPagePackages,
        actions.updateMarket,
        actions.updatePackageType,
      ],
      updateBasefilters,
    ),
    takeLatest(actions.fetchPackages, fetchPackagesSaga),
    takeLatest(
      [
        actions.updatePaginationHistory,
        actions.updateSearchHistory,
        actions.updateSortHistory,
        actions.updateStatusHistory,
        actions.updateDateHistory,
        actions.refreshPageHistory,
        actions.updateMarketHistory,
        actions.updatePackageTypeHistory,
      ],
      updateBasefiltersHistory,
    ),
    takeLatest(actions.fetchHistory, fetchPackagesHistorySaga),
    takeLatest(actions.fetchPaquerypoint, fetchPaquerypoint),
  ]);
}
