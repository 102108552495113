import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import rest from 'util/Api';
import API from 'constants/api';
import { actions } from './slice';
import { packagesBySearchCodeSelector } from './selectors';

function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      search,
    } = yield select(packagesBySearchCodeSelector);
    const searchParams = new URLSearchParams({
      externalCode: search,
      page: pageNumber,
      size: pageSize,
    });
    const url = `${API.packages.getByExternalCode}?${searchParams.toString()}`;
    const packages = yield call(rest.get, url);
    const items = packages.data.content;
    const total = packages.data.totalElements;
    yield put({
      type: actions.fetchPackagesSuccess.type,
      payload: {
        items,
        total,
      },
    });
  } catch (error) {
    // eslint-disable-next-line
    console.log('Fetch saga search by code error: ', error);
    yield put({ type: actions.fetchPackagesFailure.type });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.fetchPackages,
        actions.updatePaginationPackages,
        actions.updateSearchPackages,
        actions.refreshPage,
      ],
      fetchSaga,
    ),
  ]);
}
