/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useCallback, useState } from 'react';
import JsDownload from 'js-file-download';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import {
  paquerypointViewSearchSelector,
  paquerypointViewStatusSelector,
  paquerypointViewPackagesLoadedSelector,
  paquerypointViewPaquerypointSelector,
  paquerypointViewPackagesTypeSelector,
  paquerypointViewPackagesMarketFilterSelector,
  paquerypointViewPackagesDateSelector,
  paquerypointViewPackagesItemsSelector,
  paquerypointViewPackagesPageableSelector,
} from 'redux/paquerypointView/selectors';
import usePaquerypoints from 'redux/paquerypoint';
import { paquerypointsItemsSelector } from 'redux/paquerypoint/selectors';
import { actions as pqpointActions } from 'redux/paquerypoint/slice';
import { actions } from 'redux/paquerypointView/slice';
import { notification } from 'antd';
import PaqueryTable from '@paquery-team/lib-table';
import rest from 'util/Api';
import { readyForDeliveryStatus } from 'constants/packages';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import {
  useMarketplaceOptions,
  useOnTransitStatusOptions,
} from 'hooks/useOptions';
import externalCodeColumn from 'components/tables/columns';
import ViewPackageButton from 'components/viewPackagebutton';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';

const fetchCsv = async (
  search,
  date,
  status,
  paqueryPointId,
  packageType,
  marketplaceId,
) => {
  try {
    const searchParams = new URLSearchParams({
      search,
      from: date.from || '',
      to: date.to || '',
      status,
      paqueryPointId,
      packageType,
      marketplaceId: marketplaceId || '',
    });
    const response = await rest.get(
      `${API.reports.withPaqueryPoint}?${searchParams.toString()}`,
      {
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (error) {
    return undefined;
  }
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const rolPosColumn = {
  title: 'Rol/Pos',
  dataIndex: 'rollContainerPosition',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
};

const packageTypeColumn = {
  title: 'Tipo',
  dataIndex: 'packageType',
};

const scheduledDateColumn = {
  title: 'Fecha Programada',
  dataIndex: 'scheduledDate',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSize',
  align: 'center',
  render: (text) => <SizeIndicator text={text} />,
  sorter: true,
  sortDirections: ['descend'],
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  scheduledDateColumn,
  rolPosColumn,
  packageTypeColumn,
  sizeColumn,
  statusColumn,
];

const columnsLargeDevice = [...dataColumns, editColumn, viewPackageColumn];

const columnsSmallDevice = [externalCodeColumn, editColumn, viewPackageColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  statusColumn,
  editColumn,
  viewPackageColumn,
];

const PaqueryPointPackages = () => {
  usePaquerypoints();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const paquerypoint = useSelector(paquerypointViewPaquerypointSelector);
  const loaded = useSelector(paquerypointViewPackagesLoadedSelector);
  const items = useSelector(paquerypointViewPackagesItemsSelector);
  const pageable = useSelector(paquerypointViewPackagesPageableSelector);
  const paquerypointList = useSelector(paquerypointsItemsSelector);
  const search = useSelector(paquerypointViewSearchSelector);
  const status = useSelector(paquerypointViewStatusSelector);
  const packageType = useSelector(paquerypointViewPackagesTypeSelector);
  const date = useSelector(paquerypointViewPackagesDateSelector);
  const marketFilter = useSelector(
    paquerypointViewPackagesMarketFilterSelector,
  );
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const statusList = useOnTransitStatusOptions();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(pqpointActions.initial());
  }, [dispatch]);

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );
  const selectCallback = useCallback(
    (newStatus) => {
      dispatch(actions.updateStatusPackages(newStatus));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarket(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );

  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: statusList,
    },
  ];

  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearchPackages(searchText));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePaginationPackages(page));
    },
    [dispatch],
  );
  const searcher = {
    key: 'searchByExternalCode',
    onSearching: searchCallback,
    placeholder: 'Código externo',
    allowEmptySearch: true,
  };

  const handleExportCsv = async () => {
    setDisabled(true);
    const { id } = paquerypoint;
    try {
      const csv = await fetchCsv(
        search,
        date,
        status,
        id,
        packageType,
        marketFilter,
      );
      const paquerypointName = paquerypointList.find(
        (ppoint) => ppoint.id === id,
      ).name;
      const filename = `Paquetes-${
        paquerypointName ? `${paquerypointName}` : 'Paquery Point'
      }-
        ${dayjs().tz().format(ExportDateFormat)}.xls`;
      JsDownload(csv, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  let filteredPackages;
  if (loaded) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      scheduledDate: packet.shippingScheduleDestination.scheduledDate
        ? dayjs(packet.shippingScheduleDestination.scheduledDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      rollContainerPosition: packet.rollContainerPosition,
      paqueryPointName: packet.shippingScheduleDestination.paqueryPointId
        ? paquerypointList.find(
            (ppoint) =>
              ppoint.id === packet.shippingScheduleDestination.paqueryPointId,
          ).name
        : null,
      packageSize: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      packageType: globals.packages.type.find(
        (packType) => packType.value === packet.packageType,
      ).name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      isDeliverable: readyForDeliveryStatus.some((id) => id === packet.status),
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searcher,
        selectors,
        rangePicker,
        refresh: () => dispatch(actions.fetchPackages()),
        onExportCsv: { callback: handleExportCsv, disabled },
      }}
      // onChange={handleTableChange}
      usePackageRowColors
      onChangePaginate={updatePaginate}
      dataSource={filteredPackages}
      paginate={pageable}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default PaqueryPointPackages;
