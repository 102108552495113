import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  items: [],
  searchExternalCode: '',
  origin: '',
  destination: '',
  date: {
    from: '',
    to: '',
  },
  loaded: false,
  pageable: {
    pageNumber: 0,
    pageSize: 10,
    totalElements: 0,
  },
};

const packageDispatchViewSlice = createSlice({
  name: 'dispatchView',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.totals;
      state.loaded = true;
    },
    failure: (state) => {
      state.loaded = true;
    },
    reset: () => initialState,
  },
});

export const { name, actions, reducer } = packageDispatchViewSlice;
