import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { name as key, reducer, actions } from './slice';
import saga from './saga';
import makeSelectStoreModifyHistory from './selectors';

// No se por qué no se limpian los filtros de fechas en este caso. Debe ser por como funcionan las pestañas de Marketplaces

export default function useStoreHistoryPackages() {
  const dispatch = useDispatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  useSelector(makeSelectStoreModifyHistory(), shallowEqual);
  useEffect(() => {
    return () => {
      dispatch(actions.resetFilters());
      dispatch(actions.clearItems());
    };
  }, [dispatch]);
  return null;
}
