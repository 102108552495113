import React from 'react';
import { Flex, Form, theme, Tooltip } from 'antd';
import { getIn, useFormikContext } from 'formik';
import { DatePicker } from 'formik-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useStyles from './styles';

interface DatePickerProps {
  label: string;
  fieldName: string;
  placeholder?: string;
  disabled?: boolean;
  dateFormat?: string;
  labelStyle?: any;
  containerStyle?: any;
  disabledDate?: (args: any) => any;
  info?: string;
}

const DatePickerField = ({
  label,
  labelStyle,
  containerStyle,
  fieldName,
  placeholder,
  disabled,
  disabledDate,
  dateFormat,
  info,
}: DatePickerProps) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const { values, initialValues, errors } = useFormikContext();
  const error = getIn(errors, fieldName);
  const value = getIn(values, fieldName);
  const initialValue = getIn(initialValues, fieldName);
  const hasChanged = value && value?.toString() !== initialValue?.toString();
  const getValidateStatus = () => {
    let validateStatus:
      | ''
      | 'error'
      | 'validating'
      | 'warning'
      | 'success'
      | undefined = '';
    if (hasChanged) {
      validateStatus = 'warning';
    }
    if (error) {
      validateStatus = 'error';
    }
    return validateStatus;
  };

  const validateStatus = getValidateStatus();

  return (
    <div style={{ textAlign: 'left', ...containerStyle }}>
      {info ? (
        <Flex justify="space-between">
          <span style={labelStyle}>{label}</span>
          <Tooltip title={info}>
            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        </Flex>
      ) : (
        <span style={labelStyle}>{label}</span>
      )}
      <div className={classes.topSelectEffect}>
        <Form.Item validateStatus={validateStatus} help={error}>
          <DatePicker
            name={fieldName}
            format={dateFormat}
            style={{ width: '100%' }}
            disabledDate={disabledDate}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default DatePickerField;
