import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Spin, theme } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  userviewAvatarSelector,
  userviewLoadedSelector,
} from 'redux/userView/selectors';
import { actions } from 'redux/userView/slice';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import {
  MarketplaceRoles,
  PaqueryPointRoles,
  StoreRoles,
} from 'constants/roles';
import { InputField, UploadField, SelectField } from 'components/Forms';
import useStyles from 'components/Forms/styles';
import ChangePassword from 'components/changePassword';
import Card from 'components/card';

const User = ({
  disabledInputs,
  validationSchema,
  onSubmit,
  currentCity,
  cities,
  roles,
  currentMarketplace,
  marketplaces,
  currentPaqueryPoint,
  paqueryPoints,
  currentStore,
  stores,
  selectMarketplace,
  selectUserRoleId,
  setCurrentCountry,
  initialValues,
  countries,
  isProfile,
  isUserModify,
}) => {
  const dispatch = useDispatch();
  const loaded = useSelector(userviewLoadedSelector);
  const avatar = useSelector(userviewAvatarSelector);
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const formik = useRef();
  const optionGenerator = (optionsData) =>
    optionsData.map((data) => ({ label: data.name, value: data.id }));
  useEffect(() => {
    formik.current.setFieldValue('cityID', currentCity);
  }, [currentCity]);
  useEffect(() => {
    formik.current.setFieldValue('marketplaceID', currentMarketplace);
  }, [currentMarketplace]);
  useEffect(() => {
    formik.current.setFieldValue('paqueryPointID', currentPaqueryPoint);
  }, [currentPaqueryPoint]);
  useEffect(() => {
    formik.current.setFieldValue('storeID', currentStore);
  }, [currentStore, currentMarketplace]);
  const statusList = [
    { name: 'Activo', id: 1 },
    { name: 'Dado de baja', id: 6 },
  ];
  const optionsCountries = optionGenerator(countries);
  const optionsCities = optionGenerator(cities);
  const optionsStatus = optionGenerator(statusList);
  const optionsMarketplace = optionGenerator(marketplaces);
  const optionsPaqueryPoints = optionGenerator(paqueryPoints);
  const optionsStores = optionGenerator(stores);
  const optionsRoles = roles.map((role) => ({
    label: role.name,
    value: role.value,
  }));
  const marketplaceRoleCol = (userRoleID) => {
    if (StoreRoles.includes(userRoleID)) {
      return 12;
    }
    if (MarketplaceRoles.includes(userRoleID)) {
      return 24;
    }
    return 0;
  };
  return (
    <Spin spinning={!loaded}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values }) => (
          <Form>
            <Row type="flex" justify="space-between">
              <Col xs={24} md={14}>
                <Card
                  title="Información básica"
                  style={{ margin: '8px', height: '96.5%' }}
                >
                  <Row className={classes.cardContent}>
                    <Col xs={24} lg={8}>
                      <UploadField
                        image={avatar}
                        onChangeImage={(image) =>
                          dispatch(actions.setAvatar(image))
                        }
                      />
                    </Col>
                    <Col xs={24} lg={16}>
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <InputField label="ID" fieldName="id" disabled />
                        </Col>
                        <Col xs={24} md={12}>
                          <InputField label="Nombre" fieldName="name" />
                        </Col>
                        <Col xs={24} md={12}>
                          <InputField label="Apellido" fieldName="lastName" />
                        </Col>
                        <Col xs={24}>
                          <SelectField
                            label="Rol"
                            fieldName="userRoleID"
                            onChange={(roleId) => selectUserRoleId(roleId)}
                            options={optionsRoles}
                          />
                        </Col>
                        <Col xs={marketplaceRoleCol(values.userRoleID)}>
                          <SelectField
                            label="Marketplace"
                            fieldName="marketplaceID"
                            onChange={(marketplace) =>
                              selectMarketplace(marketplace)
                            }
                            options={optionsMarketplace}
                          />
                        </Col>
                        <Col
                          xs={
                            PaqueryPointRoles.some(
                              (pPointRoles) =>
                                values.userRoleID === pPointRoles,
                            )
                              ? 24
                              : 0
                          }
                        >
                          <SelectField
                            label="Paquery Point"
                            fieldName="paqueryPointID"
                            disabled={!!values.marketplace}
                            options={optionsPaqueryPoints}
                          />
                        </Col>
                        <Col
                          xs={StoreRoles.includes(values.userRoleID) ? 12 : 0}
                        >
                          <SelectField
                            label="Tienda"
                            fieldName="storeID"
                            disabled={disabledInputs.store}
                            options={optionsStores}
                          />
                        </Col>
                        {(isProfile || isUserModify) && (
                          <Col span={24}>
                            <ChangePassword email={values.email} />
                          </Col>
                        )}
                        {isUserModify && (
                          <Col span={24}>
                            <SelectField
                              label="Estado"
                              fieldName="statusID"
                              options={optionsStatus}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={10}>
                <Card
                  title="Información de contacto"
                  style={{ margin: '8px', height: '96.5%' }}
                >
                  <Row>
                    <Col span={24} className={classes.cardContent}>
                      <Row gutter={[8, 8]}>
                        <Col xs={24}>
                          <InputField
                            label="Móvil"
                            fieldName="phone"
                            type="number"
                          />
                        </Col>
                        <Col xs={24}>
                          <InputField
                            disabled={disabledInputs.email}
                            label="Email"
                            fieldName="email"
                          />
                        </Col>
                        <Col xs={24}>
                          <SelectField
                            label="País"
                            fieldName="countryID"
                            onChange={(country) => setCurrentCountry(country)}
                            options={optionsCountries}
                          />
                        </Col>
                        <Col xs={24}>
                          <SelectField
                            label="Ciudad"
                            fieldName="cityID"
                            options={optionsCities}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={classes.saveButton}
                  size="large"
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};

User.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  validationSchema: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  avatar: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.string,
  }).isRequired,
  currentMarketplace: PropTypes.number,
  marketplaces: PropTypes.arrayOf(PropTypes.object),
  selectMarketplace: PropTypes.func,
  selectUserRoleId: PropTypes.func,
  currentPaqueryPoint: PropTypes.number,
  paqueryPoints: PropTypes.arrayOf(PropTypes.object),
  currentStore: PropTypes.number,
  stores: PropTypes.arrayOf(PropTypes.object),
  isProfile: PropTypes.bool,
  isUserModify: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentCity: PropTypes.number,
  cities: PropTypes.arrayOf(PropTypes.object),
  setCurrentCountry: PropTypes.func,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    lastname: PropTypes.string,
    pwd: PropTypes.string,
    phone: PropTypes.number,
    confirmpwd: PropTypes.string,
    cityID: PropTypes.number,
    countryID: PropTypes.number,
    storeID: PropTypes.number,
  }),
  disabledInputs: PropTypes.shape({
    email: PropTypes.bool,
    store: PropTypes.bool,
  }),
};

User.defaultProps = {
  marketplaces: [],
  paqueryPoints: [],
  stores: [],
  isProfile: false,
  isUserModify: false,
  selectMarketplace: null,
  selectUserRoleId: null,
  currentMarketplace: '',
  currentPaqueryPoint: '',
  currentStore: '',
  currentCity: 1,
  cities: [],
  setCurrentCountry: 1,
  initialValues: {
    id: null,
    name: '',
    lastname: '',
    pwd: '',
    confirmpwd: '',
    phone: null,
    confirmpassword: '',
    cityID: null,
    countryID: 1,
    storeID: null,
  },
  disabledInputs: {
    email: false,
    store: true,
  },
};

export default User;
