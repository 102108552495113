import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';
import { removePacket } from 'redux/packageAll/actions';

export const initialState = {
  items: [],
  loaded: true,
  pageable: {
    pageNumber: 0,
    pageSize: 10,
    totalElements: 0,
  },
  type: '',
  date: {
    from: '',
    to: '',
  },
  paquerId: '',
  status: '',
  search: '',
  marketplaceId: '',
};

const packagesHistorySlice = createSlice({
  name: 'packages-paquers-history',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
    },
    failure: (state) => {
      state.loaded = true;
    },
    updatePagination: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
      state.pageable.pageNumber = 0;
    },
    updatePaquer: (state, action) => {
      state.pageable.pageNumber = 0;
      state.paquerId = action.payload;
    },
    updateDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    updatePackageType: (state, action) => {
      state.type = action.payload;
    },
    updatePacket: (state, action) => {
      state.items[action.payload.id] = action.payload;
    },
    removePacket: (state, action) => {
      delete state.items[action.payload];
    },
    updateMarket: (state, action) => {
      state.pageable.pageNumber = 0;
      state.marketplaceId = action.payload;
    },
    isLoading: (state) => {
      state.loaded = false;
    },
    emptyItems: (state) => {
      state.items = [];
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
    [removePacket]: (state, action) => {
      delete state.items[action.payload];
    },
  },
});

export const { name, actions, reducer } = packagesHistorySlice;
