import { getData } from 'util/Api';
import API from 'constants/api';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { message } from 'antd';
import { actions } from './slice';
import { storeSelector } from './selectors';

const URL = API.stores.getByMarketplace;

function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      search,
      marketplaceId,
    } = yield select(storeSelector);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      search,
    });
    const stores = yield call(
      getData,
      `${URL}/${marketplaceId}?${searchParams.toString()}`,
    );
    const items = stores.content;
    const total = stores.totalElements;
    yield put({
      type: actions.success.type,
      payload: { items, total },
    });
  } catch (error) {
    yield put({ type: actions.failure.type });
    message.error(error.message);
  }
}

function* marketplaceWatcher() {
  const { marketplaceId } = yield select(storeSelector);
  if (!marketplaceId) return;
  yield put({
    type: actions.fetch.type,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updateMarketplace,
        actions.updateSearch,
        actions.updatePageable,
        actions.refreshPage,
        actions.resetFilters,
      ],
      marketplaceWatcher,
    ),
    takeLatest([actions.fetch, actions.all], fetchSaga),
  ]);
}
