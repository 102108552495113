import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => {
  return {
    content: {
      padding: 25,
    },
    saveButton: {
      marginTop: 15,
      marginRight: 8,
    },
    cardSection: {
      marginBottom: 15,
      padding: 0,
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)',
      borderRadius: '4px',
    },
    cardContainerXs: {
      marginTop: 0,
    },
    '@media (max-width: 576px)': {
      content: {
        padding: 0,
      },
      cardSection: {
        boxShadow:
          '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: '4px',
      },
    },
    '@media (max-width: 992px)': {
      cardContainerXs: {
        marginTop: 15,
      },
    },
    cardHeader: {
      background: '#f0f0f0',
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '1.1rem',
      color: theme.colorPrimary,
    },
    cardContent: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
    },
    imageUploadContainer: {
      width: '100%',
      height: '100%',
      minHeight: 250,
      maxHeight: 250,
      padding: 15,
    },
    imageUploadBase: {
      borderStyle: 'dotted',
      borderWidth: 5,
      borderColor: '#f0f0f0',
      width: '100%',
      height: '100%',
      background: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 15,
    },
    imageUploadEnabled: {
      extend: 'imageUploadBase',
      transition: '0.4s',
      '&:hover': {
        borderStyle: 'dotted',
        borderWidth: 5,
        borderColor: theme.colorPrimary,
      },
    },
    imageUploadDisabled: {
      extend: 'imageUploadBase',
    },
    imgUploadBase: {
      borderStyle: 'dotted',
      borderWidth: 5,
      borderColor: '#f0f0f0',
      width: '100%',
      background: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 8,
    },
    imgUploadDisabled: {
      extend: 'imgUploadBase',
    },
    topSelectEffect: {
      position: 'relative',
      marginTop: 5,
      '&::after': {
        position: 'absolute',
        content: "''",
        height: 4,
        top: 0,
        left: 0,
        right: 0,
        transform: 'scalex(0)',
        transition: '300ms all ease-in-out',
        zIndex: 3,
      },
      '&:focus-within::after': {
        backgroundColor: theme.colorPrimary,
        transform: 'scalex(1)',
      },
    },
  };
});
