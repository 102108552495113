const home = '/bySearchCode';

export default {
  home,
  profiles: '/profile',
  login: {
    index: '/login',
    recover: '/recoverPassword',
    confirm: '/confirmRecovery',
  },
  users: {
    list: '/users',
    add: '/users/add',
    edit: '/users/:id',
  },
  paquers: {
    add: '/paquers/add',
    list: '/paquers',
    edit: '/paquers/:paquerId',
    packagesBy: '/packagesByPaquer',
    packagesHistorical: '/packagesHistoricalByPaquer',
  },
  paquerypoint: {
    list: '/paquerypoints',
    edit: '/paquerypoints/:paquerypointId',
    add: '/paquerypoints/add',
  },
  store: {
    edit: '/marketplaces/:marketplaceId/stores/:storeId',
    add: '/marketplaces/:marketplaceId/stores/add',
  },
  marketplace: {
    list: '/marketplaces',
    add: '/marketplaces/add',
    edit: '/marketplaces/:marketplaceId',
  },
  rates: {
    list: '/rates',
    lastMile: '/rates/lastMile',
    interUrban: '/rates/interUrban',
  },
  massive: {
    list: '/massive',
    add: '/massive/add',
    routes: '/massive/routes',
  },
  packages: {
    bySearchCode: '/packagesBySearchCode',
    entryPacket: '/entryPacket',
    list: '/packages',
    history: '/packages/history',
    withoutZone: '/packages/withoutZones',
    send: '/packages/send',
    view: '/packages/view/:id',
    edit: '/packages/:externalCode',
    editById: '/packages/id/:id',
    departure: '/packages/departure',
    departureHistory: '/packages/departure/history',
  },
  logisticOperator: {
    add: '/logisticOperator/add',
    edit: '/logisticOperator/:id',
    list: '/logisticOperator',
  },
  zones: {
    list: '/zones',
    add: '/zones/add',
    edit: {
      route: '/zones/edit/:id',
      forId: '/zones/edit',
    },
    grouping: {
      add: '/zones/grouping/add',
      edit: '/zones/grouping/edit',
      route: '/zones/grouping/edit/:id',
    },
  },
  support: {
    index: '/support',
  },
  assignee: {
    routes: '/assignee/routes',
    drivers: '/assignee/drivers',
  },
};
