import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useEffect } from 'react';
import makePackagesHistory from './selectors';
import saga from './saga';
import { name, reducer, actions } from './slice';

export default function usePackagesWithPaquersHistorical() {
  const dispatch = useDispatch();
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useSelector(makePackagesHistory(), shallowEqual);
  useEffect(() => {
    return () => {
      dispatch(actions.resetFilters());
      dispatch(actions.clearItems());
    };
  }, [dispatch]);
  return null;
}
