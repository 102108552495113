import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, notification, theme } from 'antd';
import { FileDoneOutlined, LoadingOutlined } from '@ant-design/icons';
import API from 'constants/api';
import { downloadByRequest } from 'util/Tools';
import { useSelector } from 'react-redux';
import { itemsSelector, loadedSelector } from 'redux/dispatch/selectors';
import useDispatchView from '../../../../redux/dispatch';

const ReceiptDownload = ({ departure }) => {
  const [clicked, setClicked] = useState(false);
  const loaded = useSelector(loadedSelector);
  const items = useSelector(itemsSelector);
  const { token } = theme.useToken();
  useDispatchView({ initialize: clicked, departure });
  useEffect(() => {
    if (loaded && clicked) {
      if (items.length > 0) {
        downloadByRequest(
          `${API.departures.receipt(departure.code)}`,
          `Recibo-despacho-${departure.code}.pdf`,
        );
      } else {
        notification.info({
          message: 'Información',
          description:
            'El despacho no tiene paquetes, por lo que no se genera recibo',
        });
      }
      setClicked(false);
    }
  }, [loaded, items, clicked]);
  return (
    <Button type="link" onClick={() => setClicked(true)}>
      {!clicked ? (
        <FileDoneOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
      ) : (
        <LoadingOutlined
          style={{ color: token.colorPrimary, fontSize: 18 }}
          disabled
        />
      )}
    </Button>
  );
};

ReceiptDownload.propTypes = {
  departure: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReceiptDownload;
