/* eslint-disable import/no-named-as-default-member */
import React, { useCallback, useEffect, useState } from 'react';
import PackageViewComponent from '@paquery-team/lib-package-view';
import { fetchPackageById, fetchPaquer } from 'constants/operations';
import { useParams } from 'react-router-dom';
import {
  usePackageStatus,
  useDeliveryTerms,
  usePackageSizes,
} from '@paquery-team/lib-paquery-language/globals';
import { useSelector } from 'react-redux';
import { countriesGlobalsSelector } from 'redux/globals/selectors';
import JsDownload from 'js-file-download';
import Api from 'util/Api';
import { notification } from 'antd';

const PackageView = () => {
  const [loading, setLoading] = useState(true);
  const [packet, setPacket] = useState<any>(null);
  const [paquer, setPaquer] = useState(null);
  const [error, setError] = useState<string>('');
  const countries = useSelector(countriesGlobalsSelector);
  const packageStatuses = usePackageStatus();
  const deliveryTerms = useDeliveryTerms();
  const packageSizes = usePackageSizes();

  const { id } = useParams<{ id: string }>();

  const handleReceiptDownload = useCallback(async () => {
    if (packet) {
      const response = await Api.get(packet.remitoUrl, {
        responseType: 'blob',
      });
      if (!Api.isSuccessResponse(response)) {
        const blob: Blob = response.data;
        const errorMessage = await blob.text();
        notification.error({
          message: 'No se pudo descargar el remito',
          description: errorMessage || 'Ha ocurrido un error en el servidor.',
        });
        return;
      }

      JsDownload(response.data, `${packet.externalCode}.pdf`);
    }
  }, [packet]);

  const handleFetchPacket = useCallback(async () => {
    setLoading(true);
    const response = await fetchPackageById(id);
    setLoading(false);
    if (response instanceof Error) setError(response.message);
    setPacket(response);
  }, [id]);

  const handleFetchPaquer = useCallback(async () => {
    const response = await fetchPaquer(
      packet.shippingScheduleDestination.driver.id,
    );
    if (!(response instanceof Error)) setPaquer(response);
  }, [packet]);

  // Fetch the package
  useEffect(() => {
    handleFetchPacket();
  }, [handleFetchPacket]);

  // If the package was fetched, fetch the paquer
  useEffect(() => {
    if (packet?.shippingScheduleDestination?.driver?.id) {
      handleFetchPaquer();
    }
  }, [handleFetchPaquer, packet]);

  return (
    <PackageViewComponent
      packet={packet}
      loading={loading}
      errorMessage={error}
      paquer={paquer}
      countries={countries}
      packageStatuses={packageStatuses}
      deliveryTerms={deliveryTerms}
      packageSizes={packageSizes}
      onReceiptDownload={handleReceiptDownload}
    />
  );
};

export default PackageView;
