import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';
import { removePacket } from 'redux/packageAll/actions';

export const initialState = {
  items: [],
  search: '',
  status: '',
  marketplaceId: null,
  type: null,
  date: {
    from: '',
    to: '',
  },
  code: '',
  loaded: true,
  pageable: {
    pageNumber: 0,
    pageSize: 10,
    totalElements: 0,
  },
};

const packageMassiveViewPackagesSlice = createSlice({
  name: 'massiveView',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    failure: (state) => {
      state.loaded = true;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload.trim();
    },
    updateCode: (state, action) => {
      state.pageable.pageNumber = 0;
      state.code = action.payload;
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateMarket: (state, action) => {
      state.pageable.pageNumber = 0;
      state.marketplaceId = action.payload;
    },
    updatePackageType: (state, action) => {
      state.type = action.payload;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
    [removePacket]: (state, action) => {
      delete state.items[action.payload];
    },
  },
});

export const { name, actions, reducer } = packageMassiveViewPackagesSlice;
