/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import JsDownload from 'js-file-download';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/paquerView/slice';
import { actions as actionsPaquers } from 'redux/paquers/slice';
import { selectGlobals } from 'redux/globals/selectors';
import {
  paquerviewPackagesItemsSelector,
  paquerviewPackagesSearchSelector,
  paquerviewPackagesLoadedSelector,
  paquerviewPackagesTypeSelector,
  paquerviewPackagesStatusSelector,
  paquerviewPackagesMarketSelector,
  paqueryviewPackagesDateSelector,
  paquerviewPackagesPageableSelector,
} from 'redux/paquerView/selectors';
import { notification, Tooltip, Modal, Input, theme } from 'antd';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import { readyForDeliveryStatus } from 'constants/packages';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import {
  ContainerOutlined,
  UserDeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import ViewPackageButton from 'components/viewPackagebutton';
import rest from 'util/Api';
import { useDebounce } from 'util/Hooks';
import usePaquers from 'redux/paquers';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import {
  useMarketplaceOptions,
  useWithPaquersStatusOptions,
} from 'hooks/useOptions';
import externalCodeColumn from 'components/tables/columns';
import store from 'app/store';
import useMarketplaces from 'redux/marketplaces';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';

const { confirm } = Modal;

const assignPacket = async (paquerID, externalCode) => {
  try {
    const payload = {
      paquerId: parseInt(paquerID, 10),
      searchRolled: externalCode,
    };
    const searchParams = new URLSearchParams(payload);
    await rest.put(`${API.paquer.assign}?${searchParams.toString()}`, payload);
    return true;
  } catch (error) {
    return false;
  }
};

const unassignPacket = (id) => {
  const unassingPacketFetch = async () => {
    try {
      const payload = {
        id: parseInt(id, 10),
      };
      await rest.put(`${API.paquer.unassign}${id}`, payload);
      notification.success({
        message: 'Paquer liberado correctamente',
      });
      store.dispatch(actions.removePacket(id));
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido al liberar al paquer${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };
  confirm({
    title: `¿Estas seguro que deseas liberar al paquer?`,
    icon: <ExclamationCircleOutlined />,
    onOk: unassingPacketFetch,
  });
};

const fetchCsv = async (
  search,
  paquerId,
  date,
  status,
  packageType,
  marketplace,
) => {
  try {
    const searchParams = new URLSearchParams({
      search,
      paquerId,
      to: date.to,
      from: date.from,
      status,
      packageType: packageType || '',
      marketplaceId: marketplace || '',
    });
    // eslint-disable-next-line import/no-named-as-default-member
    const response = await rest.get(
      `${API.reports.packagesOwnedByPaquer}?${searchParams.toString()}`,
      {
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const rolPosColumn = {
  title: 'Rol/Pos',
  dataIndex: 'rollContainerPosition',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  sorter: true,
  sortDirections: ['descend'],
};

const packageTypeColumn = {
  title: 'Tipo',
  dataIndex: 'packageType',
  sorter: true,
  sortDirections: ['descend'],
};

const scheduledDateColumn = {
  title: 'Fecha Programada',
  dataIndex: 'scheduledDate',
  sorter: true,
  sortDirections: ['descend'],
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSize',
  align: 'center',
  render: (text) => <SizeIndicator text={text} />,
  sorter: true,
  sortDirections: ['descend'],
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const PaquerPackages = ({ paquerId }) => {
  usePaquers();
  useMarketplaces({ initialize: true });
  const dispatch = useDispatch();
  const search = useSelector(paquerviewPackagesSearchSelector);
  const globals = useSelector(selectGlobals);
  const paquers = useSelector(paquersItemsSelector);
  const items = useSelector(paquerviewPackagesItemsSelector);
  const pageable = useSelector(paquerviewPackagesPageableSelector);
  const loaded = useSelector(paquerviewPackagesLoadedSelector);
  const packageType = useSelector(paquerviewPackagesTypeSelector);
  const status = useSelector(paquerviewPackagesStatusSelector);
  const date = useSelector(paqueryviewPackagesDateSelector);
  const marketFilter = useSelector(paquerviewPackagesMarketSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const filteredStatus = useWithPaquersStatusOptions();
  const [paquerList, setPaquerList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assignExternalCode, setAssignExternalCode] = useState('');
  const debouncedAssignExternalCode = useDebounce(assignExternalCode, 2000);
  const { token } = theme.useToken();

  const unassignPacketColumn = {
    align: 'center',
    render: (_, record) => (
      <Tooltip placement="top" title="Liberar paquer">
        <UserDeleteOutlined
          style={{
            color: token.colorPrimary,
          }}
          onClick={() => unassignPacket(record.id)}
        />
      </Tooltip>
    ),
  };

  const dataColumns = [
    externalCodeColumn,
    destinationAddressColumn,
    deliveryTermColumn,
    scheduledDateColumn,
    arrivedAtPaqueryPointDateColumn,
    rolPosColumn,
    packageTypeColumn,
    sizeColumn,
    statusColumn,
    unassignPacketColumn,
  ];

  const columnsLargeDevice = [...dataColumns, viewPackageColumn, editColumn];

  const columnsSmallDevice = [
    externalCodeColumn,
    viewPackageColumn,
    editColumn,
  ];

  const columnsMediumDevice = [
    externalCodeColumn,
    statusColumn,
    viewPackageColumn,
    editColumn,
  ];

  useEffect(() => {
    dispatch(actionsPaquers.initial());
  }, [dispatch]);

  useEffect(() => {
    if (paquers.length > 0) {
      setPaquerList(
        paquers.map((paquer) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [paquers]);

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearchPackages(searchText));
    },
    [dispatch],
  );
  const paginateCallback = useCallback(
    (page) => {
      dispatch(actions.updatePaginationPackages(page));
    },
    [dispatch],
  );

  useEffect(() => {
    async function asycnAssign(value) {
      setLoading(true);
      if (value !== '') {
        const isAssigned = await assignPacket(paquerId, value);
        if (isAssigned) {
          dispatch(actions.refreshPagePackages());
          notification.success({
            message: 'Asignación correcta',
            description: `El paquete ${value} fue asignado de manera correcta.`,
          });
        }
      }
      setLoading(false);
    }
    asycnAssign(debouncedAssignExternalCode);
  }, [debouncedAssignExternalCode, paquerId]);

  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarket(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );
  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );

  const searcher = {
    key: 'searchByExternalCode',
    onSearching: searchCallback,
    placeholder: 'Código externo',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      defaultValue: status,
      list: filteredStatus,
    },
  ];

  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  const handleCsv = async () => {
    setDisabled(true);
    try {
      const response = await fetchCsv(
        search,
        paquerId,
        date,
        status,
        packageType,
        marketFilter,
      );
      const paquerFullName =
        paquerId !== '' &&
        paquerList.find((paquer) => paquer.value === parseInt(paquerId, 10))
          .name;
      const filename = `Paquetes-${
        paquerFullName ? `${paquerFullName}` : 'Paquer'
      }-${dayjs().tz().format(ExportDateFormat)}.xls`;
      JsDownload(response, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };
  let filteredPackages;
  if (loaded) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      scheduledDate: packet.shippingScheduleDestination.scheduledDate
        ? dayjs(packet.shippingScheduleDestination.scheduledDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      rollContainerPosition: packet.rollContainerPosition,
      packageSize: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      packageType: globals.packages.type.find(
        (packType) => packType.value === packet.packageType,
      ).name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      isDeliverable: readyForDeliveryStatus.some((id) => id === packet.status),
    }));
  }
  return (
    <>
      <Modal
        closable
        destroyOnClose
        maskClosable
        open={modalVisible}
        title={
          <div style={{ display: 'flex', gap: '3%' }}>
            <ContainerOutlined style={{ paddingTop: '1%' }} />
            <p style={{ marginBottom: 0 }}>Asignar un paquete al Paquer</p>
          </div>
        }
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 1% 1% 1%',
          }}
        >
          {loading ? (
            <LoadingOutlined style={{ color: 'lightblue', padding: '50px' }} />
          ) : (
            <>
              <p>
                Para asignar un paquete al paquer, ingrese su{' '}
                <b>código externo</b>.
              </p>
              <Input
                value={assignExternalCode}
                autoFocus
                style={{ textAlign: 'center' }}
                onChange={(e) => {
                  setAssignExternalCode(e.target.value);
                }}
              />
            </>
          )}
        </div>
      </Modal>
      <PaqueryTable
        loading={!loaded}
        header={{
          searcher,
          selectors,
          rangePicker,
          secondayButton: (
            <TableHeader.SecondaryButton
              title="Asignar paquete"
              onClick={() => setModalVisible(true)}
            />
          ),
          refresh: () => dispatch(actions.refreshPage()),
          onExportCsv: { callback: handleCsv, disabled },
        }}
        // onChange={handleTableChange}
        usePackageRowColors
        onChangePaginate={paginateCallback}
        dataSource={filteredPackages}
        paginate={pageable}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
    </>
  );
};

PaquerPackages.propTypes = {
  paquerId: PropTypes.string.isRequired,
};

export default PaquerPackages;
