import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources, namespaces } from '@paquery-team/lib-paquery-language';

i18next.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'es',
  ns: namespaces,
  resources,
});

export default i18next;
