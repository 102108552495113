import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { actions } from './slice';
import {
  paqueryviewPackagesHistorySelector,
  paquerviewPackagesSelector,
  paquerviewDriverIDSelector,
} from './selectors';

function* fetchSaga() {
  try {
    const {
      sort,
      date,
      search,
      marketplaceId,
      status,
      type,
      pageable: { pageSize, pageNumber },
    } = yield select(paquerviewPackagesSelector);
    const driverID = yield select(paquerviewDriverIDSelector);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: sort !== '',
      search,
      status,
      packageType: type,
      marketplaceId: marketplaceId || '',
      from: date.from,
      to: date.to,
    });
    const packages = yield call(
      getData,
      `${API.paquer.getPackages}${driverID}?${searchParams.toString()}`,
    );
    yield put({
      type: actions.fetchPackagesSuccess.type,
      payload: {
        items: packages.content,
        total: packages.totalElements,
      },
    });
  } catch (error) {
    yield put({ type: actions.fetchPackagesFailure.type });
  }
}

function* updateBasefilters() {
  const { status, date, marketplaceId } = yield select(
    paquerviewPackagesSelector,
  );
  if (status === null || !haveDateLoaded(date) || marketplaceId === null) {
    return;
  }
  yield put({
    type: actions.fetchPackages.type,
  });
}

function* fetchHistorySaga() {
  try {
    const {
      sort,
      date,
      search,
      marketplaceId,
      status,
      type,
      pageable: { pageSize, pageNumber },
    } = yield select(paqueryviewPackagesHistorySelector);
    const driverID = yield select(paquerviewDriverIDSelector);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: sort !== '',
      search,
      status,
      packageType: type,
      marketplaceId: marketplaceId || '',
      from: date.from,
      to: date.to,
    });
    const response = yield call(
      getData,
      `${API.paquer.history}${driverID}?${searchParams.toString()}`,
    );
    yield put({
      type: actions.fetchHistorySuccess.type,
      payload: {
        items: response.content,
        total: response.totalElements,
      },
    });
  } catch (error) {
    yield put({ type: actions.fetchHistoryFailure.type });
  }
}

function* updateBasefiltersHistory() {
  const { status, date, marketplaceId } = yield select(
    paqueryviewPackagesHistorySelector,
  );
  if (status === null || !haveDateLoaded(date) || marketplaceId === null) {
    return;
  }
  yield put({
    type: actions.fetchHistory.type,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updateSearchPackages,
        actions.updateSortPackages,
        actions.updatePaginationPackages,
        actions.updatePackageType,
        actions.updateStatus,
        actions.updateMarket,
        actions.updateDate,
        actions.refreshPagePackages,
      ],
      updateBasefilters,
    ),
    takeLatest(actions.fetchPackages, fetchSaga),
    takeLatest(
      [
        actions.updateSearchHistory,
        actions.updateSortHistory,
        actions.updatePaginationHistory,
        actions.updateHistoryType,
        actions.updateStatusHistory,
        actions.updateMarketHistory,
        actions.updateDateHistory,
        actions.refreshPageHistory,
      ],
      updateBasefiltersHistory,
    ),
    takeLatest(actions.fetchHistory, fetchHistorySaga),
  ]);
}
