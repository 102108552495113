import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: [],
  loaded: true,
  pageable: {
    pageNumber: 0,
    pageSize: 10,
    totalElements: 0,
  },
  search: '',
  date: {
    from: '',
    to: '',
  },
};

const massivesSlice = createSlice({
  name: 'massives',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    failure: (state) => {
      state.loaded = true;
    },
    updateSearch: (state, action) => {
      state.search = action.payload.trim();
      state.pageable.pageNumber = 0;
    },
    updateDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    updatePaginate: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = massivesSlice;
