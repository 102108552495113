import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useEffect } from 'react';
import { name as key, reducer, actions } from './slice';
import saga from './saga';
import makeSelectDispatchView from './selectors';

export default function useDispatchView({ initialize, departure } = {}) {
  const dispatch = useDispatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  useSelector(makeSelectDispatchView(), shallowEqual);
  useEffect(() => {
    if (initialize) {
      dispatch(actions.initial({ departure }));
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [initialize, departure, dispatch]);
}
