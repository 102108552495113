import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from '../logout';

export const initialState = {
  sessionToken: null,
  isLoginIn: false,
  profile: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    rehydrate: (state, action) => {
      state.sessionToken = action.payload;
    },
    fetch: (state) => {
      state.isLoginIn = true;
    },
    fetchLoggedUser: (state) => {
      state.isLoginIn = true;
    },
    success: (state, action) => {
      state.isLoginIn = false;
      state.sessionToken = action.payload.sessionToken;
      state.profile = action.payload.profile;
    },
    failure: (state) => {
      state.isLoginIn = false;
    },
    expired: (state) => {
      state.sessionToken = null;
      state.isLoginIn = false;
    },
    fetchProfile: () => {},
    fetchProfileSuccess: (state, action) => {
      state.profile = action.payload;
    },
    fetchProfileFailure: (state) => {
      state.isLoginIn = false;
    },
    setGeocode: (state, action) => {
      state.profile.geocode = action.payload;
    },
    setZoneinfo: (state, action) => {
      state.profile.zoneinfo = action.payload;
    },
    reloadProfile: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = authSlice;
