import React from 'react';
import { FormOutlined } from '@ant-design/icons';
import SITELINKS from 'constants/sitelinks';
import { Link } from 'react-router-dom';
import { theme } from 'antd';

export default function DuplicatePackageButton({ packet }: any) {
  const { token } = theme.useToken();
  return (
    <Link
      to={{
        pathname: `${SITELINKS.packages.send}`,
        state: { id: packet.id },
      }}
    >
      <FormOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
    </Link>
  );
}
