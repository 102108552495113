import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const packageInlandDepartureSelector = (state) =>
  state[name] || initialState;

export const packageInlandDepartureItemsSelector = (state) =>
  Object.values(state[name].items);

export const packageInlandDepartureSearchSelector = (state) =>
  state[name].search;

export const packageInlandDepartureTypeSelector = (state) =>
  state[name].departureType;

export const packageInlandDepartureOriginSelector = (state) =>
  state[name].originID;

export const packageInlandDepartureDestinySelector = (state) =>
  state[name].destinyID;

export const packageInlandRemovePacketIDSelector = (state) =>
  state[name].removePacketID;

export const packageInlandDepartureLoadedSelector = (state) =>
  state[name].loaded;

export const packageInlandDeparturePageableSelector = (state) =>
  state[name].pageable;

export const packageInlandDepartureOperatorsSelector = (state) =>
  Object.values(state[name].operators);

export const packageDepartureDestinySelector = (state) => state[name].destinyID;

const makePackageInlandDepartureSelector = () =>
  createSelector(packageInlandDepartureSelector, (substate) => substate);

export const packageInlandFilteredItemsSelector = createSelector(
  packageInlandDepartureItemsSelector,
  packageInlandDeparturePageableSelector,
  packageInlandDepartureSearchSelector,
  packageInlandDepartureDestinySelector,
  packageInlandDepartureOriginSelector,
  (items, { pageSize, pageNumber }, search, destiny, origin) => {
    if (!destiny || !origin) {
      return [];
    }
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const externalCodeIgnoreCase = item.externalCode.toUpperCase();
        return (
          externalCodeIgnoreCase &&
          externalCodeIgnoreCase.includes(searchIgnoreCase)
        );
      });
    }
    if (destiny) {
      resultItems = resultItems.filter((item) => item.destinyID === destiny);
    }
    if (origin) {
      resultItems = resultItems.filter((item) => item.originID === origin);
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makePackageInlandDepartureSelector;
