import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import rest, { getData } from 'util/Api';
import * as Yup from 'yup';
import { DriverDetail } from '@paquery-team/lib-driver-detail';
import { IMAGE_REGEX } from 'constants/defaultValues';
import API from 'constants/api';
import usePaquerview from 'redux/paquerView/index';
import {
  paquerviewAvatarSelector,
  paquerviewLoadingSelector,
} from 'redux/paquerView/selectors';
import { authProfile } from 'redux/auth/selectors';
import {
  countriesGlobalsSelector,
  vehicleTypesSelector,
} from 'redux/globals/selectors';
import { actions } from 'redux/paquerView/slice';
import { actions as paquerListingActions } from 'redux/paquers/slice';
import usePaquers from 'redux/paquers';
import { actions as ppointDepartureActions } from 'redux/packagePaquerypointDeparture/slice';
import usePackagePpointDeparture from 'redux/packagePaquerypointDeparture';
import { packagePaquerypointDepartureAllPaqueryPointsItemsSelector } from 'redux/packagePaquerypointDeparture/selectors';
import { useZones } from 'services/ZoneService';

const initialValues = {
  name: '',
  lastname: '',
  docNumber: null,
  cbu: null,
  cuil: null,
  birthDate: null,
  mobile: null,
  city: {
    id: null,
    countryID: null,
  },
  zones: [],
  paqueryPoints: [],
  vehicleID: null,
  vehiclePatent: null,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del paquer'),
  lastname: Yup.string().required(
    'Es necesario indicar el apellido del paquer',
  ),
  docNumber: Yup.number()
    .typeError('Solo se deben indicar números y es requerido')
    .required('El número de documento es necesario'),
  mobile: Yup.number()
    .typeError('Solo deben ser numeros y son requeridos')
    .required('Es necesario indicar el número de teléfono'),
  city: Yup.object().shape({
    id: Yup.number()
      .typeError('Es necesario indicar la ciudad')
      .required('Es necesario indicar la ciudad'),
    countryID: Yup.number()
      .typeError('Es necesario indicar el país')
      .required('Es necesario indicar el país'),
  }),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
});

const PaquerAdd = () => {
  usePaquerview();
  usePaquers();
  usePackagePpointDeparture();
  const dispatch = useDispatch();
  const loading = useSelector(paquerviewLoadingSelector);
  const history = useHistory();
  const avatar = useSelector(paquerviewAvatarSelector);
  const profile = useSelector(authProfile);
  const countries = useSelector(countriesGlobalsSelector);
  const {
    zones: { data: zones },
  } = useZones();
  const vehicleTypes = useSelector(vehicleTypesSelector);
  const paqueryPoints = useSelector(
    packagePaquerypointDepartureAllPaqueryPointsItemsSelector,
  );
  const [cities, setCities] = useState([]);
  const [currentCity, setCurrentCity] = useState();

  const onChangeCountry = useCallback(
    async (country) => {
      dispatch(actions.loading());
      const citiesResponse = await getData(`${API.globals.cities}/${country}`);
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(null);
      }
      dispatch(actions.loaded());
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(actions.reset());
    dispatch(ppointDepartureActions.initial());
  }, [dispatch]);

  const handleSubmit = async (values) => {
    dispatch(actions.loading());
    const payload = {
      active: values.active,
      avatar: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : undefined,
      birthDate: values.birthDate,
      cityID: values.city.id,
      countryId: values.city.countryID,
      cbu: values.cbu,
      cuil: values.cuil,
      docNumber: values.docNumber,
      email: values.email,
      lastName: values.lastname,
      mobile: values.mobile,
      vehicleTypeID: values.vehicleTypeID,
      name: values.name,
      zones: values.zones.map((zone) => ({
        id: zone.id,
      })),
      paqueryPoints: values.paqueryPoints.map((ppoint) => ({
        id: ppoint.id,
      })),
      logisticOperators: [
        {
          id: profile.logisticOperatorID,
        },
      ],
      vehicleID: values.vehicleID,
      vehicleType: values.vehicleType,
    };
    try {
      const response = await rest.post(API.paquer.create, payload);
      if (response.status === 400) {
        throw new Error(response.data.message);
      } else {
        notification.success({
          message: 'Alta de paquer correcto',
          description: `${values.name} ${values.lastname} ha sido dado de alta como paquer correctamente.`,
        });
        dispatch(paquerListingActions.refreshPage());
        setTimeout(() => {
          history.push('/paquers');
        }, 1000);
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al dar el alta de paquer${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };

  return (
    <DriverDetail
      loaded={!loading}
      initialValues={initialValues}
      validationSchema={validationSchema}
      currentCity={currentCity}
      vehicles={vehicleTypes}
      countries={countries}
      cities={cities}
      zones={zones}
      paqueryPoints={paqueryPoints}
      onChangeCountry={onChangeCountry}
      onSubmit={handleSubmit}
    />
  );
};

export default PaquerAdd;
