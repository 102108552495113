import { createSelector } from 'reselect';
import {
  INTERIOR_DESTINATIONS_OPTIONS,
  DEPARTURE_TYPE_OPTIONS,
} from 'constants/options';
import { marketplacesObjectItemSelector } from 'redux/marketplaces/selectors';
import { paquerypointsObjectItemSelector } from 'redux/paquerypoint/selectors';
import { name, initialState } from './slice';

export const selectDepartureHistory = (state) => state[name] || initialState;

export const itemsSelector = (state) => state[name].items;

export const departureTypeSelector = (state) => state[name].departureType;

export const destinationOptionsSelector = createSelector(
  departureTypeSelector,
  marketplacesObjectItemSelector,
  paquerypointsObjectItemSelector,
  (departureType, marketplacesSource, paquerypointsSource) => {
    let options = [];
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        options = INTERIOR_DESTINATIONS_OPTIONS.map((destination) => ({
          name: destination.name,
          value: destination.id,
        }));
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        const paquerypoints = Object.values(paquerypointsSource);
        if (paquerypoints?.length > 0) {
          options = paquerypoints.map((paquerypoint) => ({
            name: paquerypoint.name,
            value: paquerypoint.id,
          }));
        }
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        const marketplaces = Object.values(marketplacesSource);
        if (marketplaces?.length > 0) {
          options = marketplaces.map((marketplace) => ({
            name: marketplace.name,
            value: marketplace.id,
          }));
        }
        break;
      default:
        break;
    }
    return options;
  },
);

export const searchExternalCodeSelector = (state) =>
  state[name].searchExternalCode;

export const originSelector = (state) => state[name].origin;

export const destinationSelector = (state) => state[name].destination;

export const dateSelector = (state) => state[name].date;

export const loadedSelector = (state) => state[name].loaded;

export const pageableSelector = (state) => state[name].pageable;

const makeSelectDepartureHistory = () =>
  createSelector(selectDepartureHistory, (substate) => substate);

export const dispatchItemsDepartureTypeFilter = createSelector(
  itemsSelector,
  departureTypeSelector,
  (items, departureType) => {
    if (!items) return [];
    const intDepartureType = parseInt(departureType, 10);
    const itemsArray = Object.values(items);
    if (origin === '') {
      return itemsArray;
    }
    return itemsArray.filter(
      (item) => item.destinationType === intDepartureType,
    );
  },
);

export default makeSelectDepartureHistory;
